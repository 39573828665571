import { Component, OnInit } from '@angular/core';
import { HomeComponent } from 'src/app/pages/home/home.component';
import { GlobalService } from 'src/app/services/global.service';
@Component({
  selector: 'app-home-sidebar',
  templateUrl: './home-sidebar.component.html',
  styleUrls: ['./home-sidebar.component.scss']
})
export class HomeSidebarComponent implements OnInit {
  tabs: any = [];
  bodies: any = [];
  constructor(public app: HomeComponent, private globalService: GlobalService,) {
    this.globalService.request.subscribe(res => {
      if(res){
        switch (res['type']) {
          case 'OPENSIDEBARMOBILE':
            this.addMenu();
            break;
          case 'CLOSESIDEBARMOBILE':
            this.removeMenu();
            break;
        }
      }
    });
    this.tabs = [
      {
        icon: 'fas fa-globe-americas',
        text: 'Red',
        index: 0,
      },
      {
        icon: 'fas fa-store',
        text: 'Empresas',
        index: 1,
      },
      {
        icon: 'fas fa-cogs',
        text: 'Configuración',
        index: 2,
      },
    ];
  
    this.bodies = [
      {
        title: 'Red',
        index: 0,
        content: [
          {
            icon: 'fas fa-home',
            order: 0,
            text: 'Inicio',
            type: 'message',
            href: 'home',
            enabled: true,
          },
          {
            icon: 'fa-broadcast-tower fas',
            order: 1,
            text: 'Hubs',
            type: 'message',
            href: 'hubs/panel',
            enabled: true,
          }
        ],
      },
      {
        title: 'Empresas',
        index: 1,
        content: [
          {
            icon: 'fas fa-building',
            order: 0,
            text: 'Panel de empresas',
            type: 'message',
            href: 'business-panel',
            enabled: true,
          },
          {
            icon: 'fas fa-tasks',
            order: 1,
            text: 'Verificar empresas',
            type: 'message',
            href: 'verify-business',
            enabled: true
          },
          {
            icon: 'fas fa-hospital-user',
            order: 2,
            text: 'Invitaciones',
            type: 'message',
            href: 'mass-invite',
            enabled: true
          }
        ],
      },
      {
        title: 'Configuración',
        index: 2,
        content: [
          // {
          //   icon: 'fas fa-id-card-alt',
          //   order: 0,
          //   text: 'Mi cuenta',
          //   type: 'message',
          //   href: 'my-account',
          //   enabled: true,
          // },
          {
            icon: 'fas fa-user',
            order: 1,
            text: 'Panel de usuarios',
            type: 'message',
            href: 'users',
            enabled: true
          },
          {
            icon: 'fas fa-mail-bulk',
            order: 2,
            text: 'Plantillas AWS SES',
            type: 'message',
            href: 'ses-templates',
            enabled: true
          },
          {
            icon: 'fas fa-border-style',
            order: 3,
            text: 'Plantillas',
            type: 'message',
            href: 'dashboard-template',
            enabled: true
          }
        ],
      },
    ];
   }

  ngOnInit(): void {
  }
  
  getWitdh(score, order){
    const acBasic = parseFloat(score);
    let total = 35;
    if(order === 1){
      total = 35;
    }else if(order === 2){
      total = 60.00;
    }else if(order === 3){
      total = 5.00;
    }
    const withBarBasic = (acBasic * 100) / total;
    return withBarBasic;
  }

  addMenu(){
    document.getElementById("sidebar").classList.remove("hidden");
    document.getElementById("sidebar").classList.add("block");
  }

  removeMenu(){
    document.getElementById("sidebar").classList.remove("block");
    document.getElementById("sidebar").classList.add("hidden");
  }
}
