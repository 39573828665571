<router-outlet></router-outlet>

<p-dialog [closeOnEscape]="false" header="" [(visible)]="expiredSession" [baseZIndex]="10001" [style]="{width: '25vw'}" [breakpoints]="{'640px': '100vw'}" [modal]="true" [draggable]="false"
[resizable]="false" [closable]="false">
    <div class="grid">
        <div class="col-12 text-center">
            <i class="fas fa-user-clock" style="font-size: 3rem;"></i>
            <h3>Tiempo de sesión expirado</h3>
            <p>Por seguridad hemos finalizado tu sesión por inactividad</p>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button pButton pRipple type="button" label="ACEPTAR" class="p-button-rounded p-button-warning w-button" (click)="signOff()"></button>
    </ng-template>
</p-dialog>

<p-dialog [closeOnEscape]="false" header="" [(visible)]="updatePlatForm" [baseZIndex]="10001" [style]="{width: '25vw'}" [breakpoints]="{'640px': '100vw'}" [modal]="false" [draggable]="false"
    [resizable]="false" [closable]="false" [position]="'bottom-right'">
    <div class="grid">
        <div class="col-12 pb-0 text-center">
            <i class="fas fa-sync" style="font-size: 3rem;"></i>
            <h3 translate>THERE_IS_A_NEW_VERSION_OF_CBN_AVAILABLE</h3>
            <p class="m-0" translate>THERE_IS_A_NEW_VERSION_OF_CBN_AVAILABLE_TEXT</p>
            <p class="m-0" translate>THERE_IS_A_NEW_VERSION_OF_CBN_AVAILABLE_TEXT_SECOND</p>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button pButton pRipple type="button" label="{{ 'SKIP' | translate }}" class="p-button-secondary p-button-text w-button mr-2" (click)="updatePlatForm = false;"></button>
        <button pButton pRipple type="button" label="{{ 'updateU' | translate }}" class="p-button-rounded w-button p-button-success" (click)="clickUopdate();"></button>
    </ng-template>
</p-dialog>

<p-progressBar *ngIf="load" mode="indeterminate"
[style]="{'height': '4px', 'width': '100%', 'background': 'transparent' , 'z-index': '302'}" styleClass="fixed left-0 top-0">
</p-progressBar>

<p-toast [breakpoints]="{'920px': {width: '100%', right: '0', left: '0'}}"></p-toast>