<ng-container *ngIf="type === 'LOAD'">
    <div class="conversation">
        <div class="picture">
            <p-skeleton shape="circle" size="3rem"></p-skeleton>
        </div>
        <div class="content">
            <p-skeleton styleClass="mt-1" borderRadius="16px"></p-skeleton>
            <p-skeleton styleClass="mt-2" borderRadius="16px"></p-skeleton>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="type === 'CHAT' && data">
    <ng-container *ngIf="!data.idChat">
        <div class="conversation" (click)="createChat()">
            <div class="picture">
                <p-avatar styleClass="avatar-chat" size="large" [image]="data.avatar && data.avatar.length > 0 ? data.avatar : 'assets/images/user.svg'" shape="circle"></p-avatar>
            </div>
            <div class="content">
                <div class="conversationHeader">
                    <div class="name">{{ data.name }}</div>
                    <!-- <div class="time"></div>  -->
                </div>
                <div class="message">
                    <!-- {{selectedCompanies['name']}} -->
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="data.idChat">
        <div class="conversation" (click)="goChat()">
            <div class="picture">
                <p-avatar styleClass="avatar-chat" size="large" [image]="data.avatar && data.avatar.length > 0 ? data.avatar : 'assets/images/user.svg'" shape="circle"></p-avatar>
            </div>
            <div class="content">
                <div class="conversationHeader">
                    <div class="name">{{ data.name }}</div>
                    <!-- <div class="time"></div>  -->
                </div>
                <div class="message">
                    <!-- {{selectedCompanies['name']}} -->
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>