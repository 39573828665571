import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, lastValueFrom, Subscription } from 'rxjs';
import { AwsSharedService } from 'src/app/services/aws-shared.service';
import { GlobalService } from 'src/app/services/global.service';
import * as uuid from 'uuid';

@Component({
  selector: 'app-chat-input-footer',
  templateUrl: './chat-input-footer.component.html',
  styleUrls: ['./chat-input-footer.component.scss']
})
export class ChatInputFooterComponent implements OnInit {

  showFiles = true;
  itemsSpeedDial = [
    {
      tooltip: 'Adjuntar archivos',
      icon: 'fas fa-file',
      tooltipPosition: 'left',
      command: () => {
        this.uploadVideo = false;
        this.displayImagencrop = false;
        this.uploadFiles = true;
      },
    },
    {
      tooltip: 'Adjuntar imagenes',
      icon: 'fas fa-images',
      tooltipPosition: 'left',
      command: () => {
        this.uploadVideo = false;
        this.uploadFiles = false;
        this.displayImagencrop = true;
      },
    },
    {
      tooltip: 'Adjuntar videos',
      icon: 'fas fa-film',
      tooltipPosition: 'left',
      command: () => {
        this.uploadVideo = false;
        this.displayImagencrop = false;
        this.uploadVideo = true;
      },
    }
  ];

  images = [];
  displayImagencrop = false;
  loadImage = false;

  files = [];
  uploadFiles = false;
  videos = [];
  uploadVideo = false;
  @Input()  idCompany = "";
  @Input()  typeSession = "USER";

  @Output() submitMessage = new EventEmitter<Object>();

  formQuery:UntypedFormGroup;
  themeSelect = '';
  emojii18n = null;
  subscriptionGlobal: Subscription;
  @ViewChild('input') input: ElementRef;
  tempFiles = [];

  extensionsAttr = [
    { ext:['xls', 'xlsx', 'csv'], color:'#1BBB53', icon:'fa-file-excel' },
    { ext:['txt'], color:'#03A9F4', icon:'fa-file-alt' },
    { ext:['doc', 'docx', 'docm' ,'aspx'], color:'#3C33FF', icon:'fa-file-word' },
    { ext:['jpg', 'jpeg', 'gif', 'png', 'bmp', 'psd'], color:'#BF00FC', icon:'fa-file-image' },
    { ext:['avi', 'mp4', 'mpeg', 'mwv'], color:'#00E9FC', icon:'fa-file-video' },
    { ext:['ppt', 'pptx', 'pptm'], color:'#FF5722', icon:'fa-file-powerpoint' },
    { ext:['pdf'], color:'#E91E63', icon:'fa-file-pdf' },
    { ext:['html', 'css', 'js', 'json'], color:'#E2D63D', icon:'fa-file-code' },
    { ext:['mp3', 'wav', 'wma'], color:'#D1AE2E', icon:'fa-file-audio' },
    { ext:['zip', 'rar', 'gzip'], color:'#A67711', icon:'fa-file-archive' },
    { ext:['etc'], color:'#9E9E9E', icon:'fa-file' }
  ];

  lang = "";
  constructor(
    public trans: TranslateService,
    private fb: UntypedFormBuilder,
    private globalService: GlobalService,
    private sharedService: AwsSharedService
  ) { 
    this.themeSelect = this.globalService.theme;
    this.subscriptionGlobal = this.globalService.request.subscribe((res) => {
      if (res) {
        switch (res['type']) {
          case 'UPDATELANG':
            this.lang = this.globalService.lang;
            this.translateServiceEmoji();
            break;
          case 'UPDATETHEME':
            this.themeSelect = this.globalService.theme;
            break;
        }
      }
    });

    this.formQuery = this.fb.group({ query: "" });
}

  ngOnInit(): void {
    this.lang = this.globalService.lang;
    this.translateServiceEmoji();
  }

  

  //ENVIAR GIF AL CHAT

  sendGif(data){
    this.images.push(data)
  }

  ngOnDestroy(): void {
    this.subscriptionGlobal.unsubscribe();
  }

  async translateServiceEmoji() {
    let emojiLang = await new Promise((resolve, reject) => {
      this.trans.get('emoji').subscribe((res) => {
        resolve(res);
      });
    });
    this.emojii18n = emojiLang;
  }

  get validateSendQuery(){
    let query = this.formQuery.value['query'] ? this.formQuery.value['query'].trim() : "";
    return query.length > 0 || this.images.length > 0 || this.files.length > 0 || this.videos.length > 0 ?  true : false;
  }
  
  addEmoji(selected) {
    const emoji: string = (selected.emoji as any).native;
    
    const input = this.input.nativeElement;
    let query = this.formQuery.value['query'];
    let position = query.length - input.selectionStart;
    let positionNow = input.selectionStart;
    if(position > 0){
      let start = query.slice(0,-position);
      let end = query.slice(-position);
      let textFinish = start + emoji + end;
      positionNow = `${start + emoji}`.length;
      this.formQuery.patchValue({ query: textFinish });
    }else{
      let textFinish = query + emoji;
      positionNow = textFinish.length;
      this.formQuery.patchValue({ query: textFinish });
    }
    input.setSelectionRange(positionNow, positionNow);
    input.focus();
  }

  clearFormulary(){
    this.formQuery.reset();
    this.formQuery.patchValue({ query: "" });
    this.images = [];
    this.files = [];
    this.videos = [];
  }

  onSubmit(){
    if(this.validateSendQuery){
      this.submitMessage.emit({
        query: this.formQuery.value.query.trim(),
        images: this.images,
        files: this.files,
        videos: this.videos,
        sendEmail: false
      });
      this.clearFormulary();
    }
  }

  //subir imagenes
  async saveFile(data) {
    // this.loadImage = true;
    // let nameFile = `${uuid.v4()}.png`;
    // const blob = this.dataURLtoFile( data.file_base64, nameFile );
    // let preSigned = await firstValueFrom( this.sharedService.uploadFileS3All({ type: this.typeSession, typeValue: this.idCompany, path: 'messages', name: nameFile }) );
    // if (preSigned['status']) {
    //   let result = await lastValueFrom( this.sharedService.uploadFileToS3({ url: preSigned['data']['url'], file: blob }) );
    //   if (result && result['status'] === 200) {
    //     let data = { name: nameFile, url: result['url'].split('?')[0] };
    //     this.images.push(data);
    //   } else {
    //     this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: 'FILE_ERROR_UPLOAD', type: 'TOAST', });
    //   }
    // } else {
    //   this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: 'FILE_ERROR_UPLOAD', type: 'TOAST', });
    // }
    // this.loadImage = false;
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  //subir video
  nextVideo(data){
    let decodeUri = decodeURI(data['urlVideo']);
    let splits = decodeUri.split("/");
    let dataObject = {
      name: splits[splits.length - 1],
      url: data['urlVideo']
    };
    this.videos.push(dataObject);
    console.log(this.videos)
    this.uploadVideo = false;
  } 

  //subir archivos
  nextFiles(data) {
    this.files = this.files.concat(data);
    console.log(this.files)
    this.uploadFiles = false;
  }

  //

  getColor(name){
    let ext = name.split(".")[name.split(".").length - 1].toLowerCase();
    return this.extensionsAttr.find(c => c['ext'].includes(ext)) ? this.extensionsAttr.find(c => c['ext'].includes(ext))['color'] : '#9e9e9e';
  }
  
  getIcon(name){
    let ext = name.split(".")[name.split(".").length - 1].toLowerCase();
    return this.extensionsAttr.find(c => c['ext'].includes(ext)) ? this.extensionsAttr.find(c => c['ext'].includes(ext))['icon'] : 'fa-file';
  }

  deleteImages(i){
    this.images.splice(i, 1);
  }

  deleteVideos(i){
    this.videos.splice(i, 1);
  }

  deleteFiles(i){
    this.files.splice(i, 1);
  }

  sendTextArea(data, text: HTMLTextAreaElement) {
    if (data.shiftKey && data.key === 'Enter') {
      if (this.formQuery.value.query.trim().length === 0) {
        setTimeout(() => {
          this.formQuery.value.query = '';
        }, 100);
      }
    } else if (!data.shiftKey && data.key === 'Enter') {
      if (this.validateSendQuery) {
        this.onSubmit();
      }
    }
  }
}
