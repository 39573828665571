<div class="topbar clearfix desktop" #topBar>
    <a class="morpheus-logo-link" href="http://cbntec.com" target="_blank"></a>
    <a id="topbar-menu-button" href="#" (click)="onTopbarMenuButtonClick($event)">
        <i class="fa fa-bars"></i>
    </a>
    <ul class="topbar-menu fadeInDown" [ngClass]="{'topbar-menu-visible': topbarMenuActive}">
        
        <li class="profile-item" (click)="op.toggle($event)">
            <a class="cursor-pointer">
                <div class="profile-image">
                    <p-avatar [image]="sessionIn.avatar"
                        styleClass="p-mr-2" size="large" shape="circle"></p-avatar>
                </div>
            </a>
        </li>
    </ul>
</div>

<div class="topbar clearfix mobile p-2" #topBar>
    <button pButton pRipple type="button" icon="fas fa-bars" class="p-button-rounded p-button-secondary p-button-text" (click)="openMenu()"></button>
    <p-avatar [image]="sessionIn.avatar" styleClass="p-mr-2" size="large" shape="circle" (click)="sidebarMobile = true;"></p-avatar>
</div>


<p-overlayPanel #op styleClass="overlayTopbar" [appendTo]="topBar">
    <ng-template pTemplate>
        <div class="grid" style="width: 450px;
        ">
            <div class="col-3">
                <div class="grid">
                    <div class="col-12 text-center">
                        <p-avatar [image]="sessionIn.avatar" styleClass="p-mr-2" size="xlarge" shape="circle"></p-avatar>
                    </div>
                </div>
            </div>
            <div class="col-9">
                <div class="grid">
                    <div class="col-12 p-1">
                        <p class="text-2xl m-0 text-900 font-bold white-space-nowrap text-overflow-ellipsis overflow-hidden">{{sessionIn.name}} {{sessionIn.lastName}}</p>
                    </div>
                    <div class="col-12 p-1">
                        <p class="text-lg m-0">{{type}}</p>
                    </div>
                    <div class="col-12 p-1">
                        <a href="mailto:{{sessionIn.email}}" style="color: var(--primary-color);">{{sessionIn.email}}</a>
                    </div>
                    <div class="col-12 p-1">
                        <span class="mr-3"><ng-container *ngIf="sessionIn.cellPhone.length > 0 && sessionIn.cellPhonePrefix.length > 0" ><i class="fas fa-mobile-alt mr-1"></i>{{sessionIn.cellPhonePrefix}} {{sessionIn.cellPhone}}</ng-container></span>
                        <span><ng-container *ngIf="sessionIn.phone.length > 0 && sessionIn.phonePrefix.length > 0" ><i class="fas fa-phone mr-1"></i>{{sessionIn.phonePrefix}} {{sessionIn.phone}}</ng-container></span>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="grid">
                    <div class="col-6 p-0 flex align-items-center justify-content-center">
                        <span class="font-bold mr-3" translate>theme</span>
                        <i class="fas fa-sun m-2" style="font-size: 18px;"></i>
                        <p-inputSwitch [(ngModel)]="themeStatus" (onChange)="themeChange()"></p-inputSwitch> <i
                            style="font-size: 18px;" class="fas fa-moon m-2"></i>
                    </div>
                    <div class="col-6 p-0 flex align-items-center justify-content-center">
                        <span class="font-bold mr-3" translate>language</span>
                        <img class="m-2" width="18" src="../../../../assets/images/icons/spain.png" alt="" srcset="">
                        <p-inputSwitch [(ngModel)]="langStatus" (onChange)="langChange()"></p-inputSwitch>
                        <img class="m-2" width="18" src="../../../../assets/images/icons/united-kingdom.png" alt=""
                            srcset="">
                    </div>
                </div>
            </div>
            <div class="col-12 p-0">
                <p-divider [style]="{margin: '0'}"></p-divider>
            </div>
            <div class="col-12 pb-0">
                <div class="grid">
                    <div class="col-6 text-center pt-2 pb-2 grey cursor-pointer" pRipple (click)="openProfile()">
                        <p class="m-0" translate><i class="fas fa-pen"></i> profile</p>
                    </div>
                    <div class="col-6 text-center pt-2 pb-2 grey cursor-pointer" pRipple (click)="confirmSignOff($event)">
                        <p class="m-0" style="color: red;" translate>signOff</p>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</p-overlayPanel>

<p-confirmDialog #cd [baseZIndex]="10000" rejectButtonStyleClass="p-button-text">
    <ng-template pTemplate="header">
        <h3 translate>confirmation</h3>
    </ng-template>
    <ng-template pTemplate="footer">
        <button class="p-button-rounded p-button-danger w-button" type="button" pButton label="{{'no' | translate}}" (click)="cd.reject()"></button>
        <button class="p-button-rounded p-button-success w-button" type="button" pButton label="{{'yes' | translate}}" (click)="cd.accept()"></button>
    </ng-template>
</p-confirmDialog>

<p-sidebar [(visible)]="sidebarMobile" position="right" [showCloseIcon]="true" [style]="{width:'95vw'}">
    <div class="grid m-0 justify-content-between flex-column" style="height: calc(var(--doc-height) - 56px);">
        <div class="col-12 p-0">
            <div class="grid m-0">
                <div class="col-12 mt-3">
                    <div class="grid text-center">
                        <div class="col-12 text-center">
                            <p-avatar image="{{sessionIn.avatar}}" styleClass="p-mr-2"
                                size="xlarge" shape="circle"></p-avatar>
                        </div>
                        <div class="col-12 p-1">
                            <p class="m-0 text-900 font-bold white-space-nowrap text-overflow-ellipsis overflow-hidden">
                                {{sessionIn.name}} {{sessionIn.lastName}}</p>
                        </div>
                        <div class="col-12 p-1">
                            <p class="m-0">{{type}}</p>
                        </div>
                        <div class="col-12 p-1">
                            <a href="mailto:{{sessionIn.email}}"
                                style="color: var(--primary-color);">{{sessionIn.email}}</a>
                        </div>
                        <div class="col-12 p-1">
                            <span class="mr-3">
                                <ng-container
                                    *ngIf="sessionIn.cellPhone.length > 0 && sessionIn.cellPhonePrefix.length > 0"><i
                                        class="fas fa-mobile-alt mr-1"></i>{{sessionIn.cellPhonePrefix}}
                                    {{sessionIn.cellPhone}}
                                </ng-container>
                            </span>
                            <span>
                                <ng-container *ngIf="sessionIn.phone.length > 0 && sessionIn.phonePrefix.length > 0"><i
                                        class="fas fa-phone mr-1"></i>{{sessionIn.phonePrefix}} {{sessionIn.phone}}
                                </ng-container>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-12 flex align-items-center justify-content-center">
                    <span class="font-bold mr-3" translate>theme</span>
                    <i class="fas fa-sun m-2" style="font-size: 18px;"></i>
                    <p-inputSwitch [(ngModel)]="themeStatus" (onChange)="themeChange()"></p-inputSwitch> <i
                        style="font-size: 18px;" class="fas fa-moon m-2"></i>
                </div>
                <div class="col-12 flex align-items-center justify-content-center">
                    <span class="font-bold mr-3" translate>language</span>
                    <img class="m-2" width="18" src="../../../../assets/images/icons/spain.png" alt="" srcset="">
                    <p-inputSwitch [(ngModel)]="langStatus" (onChange)="langChange()"></p-inputSwitch>
                    <img class="m-2" width="18" src="../../../../assets/images/icons/united-kingdom.png" alt=""
                        srcset="">
                </div>
                <div class="col-12 flex align-items-center justify-content-between">
                    <button (click)="openProfile()" pButton pRipple type="button" label="{{'profile' | translate}}"
                        class="p-button-rounded p-button-raised p-button-secondary p-button-text  w-button"></button>
                    <button (click)="confirmSignOff($event)" pButton pRipple type="button" label="{{'signOff' | translate}}"
                        class="p-button-rounded p-button-raised p-button-danger p-button-text  w-button"></button>
                </div>
            </div>
        </div>
        <div class="col-12 p-0 flex align-items-center justify-content-between">
            <button (click)="sidebarMobile = false" pButton pRipple type="button" label="{{'closeU' | translate}}"
                class="p-button-rounded p-button-danger w-button"></button>
        </div>
    </div>

</p-sidebar>