import { Component, OnInit, Input } from '@angular/core';
import SwiperCore, { Autoplay } from 'swiper';
import { GlobalService } from 'src/app/services/global.service';
SwiperCore.use([Autoplay]);

@Component({
  selector: 'app-view-product',
  templateUrl: './view-product.component.html',
  styleUrls: ['./view-product.component.scss'],
})
export class ViewProductComponent implements OnInit {
  @Input() list = null;
  @Input() type = '';
  items = [
    {
      icon: 'pi pi-pencil',
      command: () => {
        this.globalService.sendRequest({type : 'OPENSIDEBRPRODUCTSERVICES' , data :this.list});
      },
    },
    {
      icon: 'pi pi-trash',
      command: () => {
        this.globalService.sendRequest({type : 'DELETERPRODUCTSERVICES' , data :this.list});
      },
    },
  ];
  constructor( private globalService: GlobalService) {
    setTimeout(() => {
      console.log(this.list)
    }, 1000);
  }

  ngOnInit(): void {}
}
