import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { PrimengModule } from './modules/primeng/primeng.module';
import { HomeSidebarComponent } from 'src/app/components/home/home-sidebar/home-sidebar.component';
import { HomeTopComponent } from 'src/app/components/home/home-top/home-top.component';
import { HomeComponent } from 'src/app/pages/home/home.component';
import { GlobalModule } from './modules/global/global.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

export function HttpLoaderFactory( http: HttpClient ){
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HomeSidebarComponent,
    HomeTopComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    PrimengModule,
    PrimengModule,
    GlobalModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register ('ngsw-worker.js', {enabled: environment.production, registrationStrategy: 'registerImmediately'})
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
