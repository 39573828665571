import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  Renderer2,
  ChangeDetectorRef,
} from '@angular/core';
// import { WebsocketService } from 'src/app/services/websocket.service';

enum MenuOrientation {
  STATIC,
  OVERLAY,
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  sidebarActive = false;

  activeTabIndex = -1;

  layoutMode: MenuOrientation = MenuOrientation.OVERLAY;

  topbarMenuActive: boolean;

  overlayMenuActive: boolean;

  staticMenuDesktopInactive: boolean;

  staticMenuMobileActive: boolean;

  rotateMenuButton: boolean;

  sidebarClick: boolean;

  topbarItemClick: boolean;

  menuButtonClick: boolean;

  activeTopbarItem: any;

  documentClickListener: Function;

  
  constructor(
    public renderer: Renderer2,
    // private wssService: WebsocketService
  ) {}

  ngOnInit(): void {
    // console.log(this.wssService.statusRA)
    /* this.wssService.openWebSocket(); */
  }

  ngAfterViewInit() {
    this.documentClickListener = this.renderer.listen(
      'body',
      'click',
      (event) => {
        if (!this.topbarItemClick) {
          this.activeTopbarItem = null;
          this.topbarMenuActive = false;
        }

        if (
          !this.menuButtonClick &&
          !this.sidebarClick &&
          (this.overlay || !this.isDesktop())
        ) {
          this.sidebarActive = false;
        }

        this.topbarItemClick = false;
        this.sidebarClick = false;
        this.menuButtonClick = false;
      }
    );
  }

  onTabClick(event: Event, index: number) {
    if (this.activeTabIndex === index) {
      this.sidebarActive = !this.sidebarActive;
    } else {
      this.activeTabIndex = index;
      this.sidebarActive = true;
    }

    event.preventDefault();
  }

  closeSidebar(event: Event) {
    this.sidebarActive = false;
    event.preventDefault();
  }

  onSidebarClick(event: Event) {
    this.sidebarClick = true;
  }
  
  onMenuButtonClick(event: Event, index: number) {
    this.menuButtonClick = true;
    this.rotateMenuButton = !this.rotateMenuButton;
    this.topbarMenuActive = false;
    this.sidebarActive = !this.sidebarActive;

    if (this.layoutMode === MenuOrientation.OVERLAY) {
      this.overlayMenuActive = !this.overlayMenuActive;
    } else {
      if (this.isDesktop()) {
        this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
      } else {
        this.staticMenuMobileActive = !this.staticMenuMobileActive;
      }
    }

    if (this.activeTabIndex < 0) {
      this.activeTabIndex = 0;
    }

    event.preventDefault();
  }

  onTopbarItemClick(event: Event, item) {
    this.topbarItemClick = true;

    if (this.activeTopbarItem === item) {
      this.activeTopbarItem = null;
    } else {
      this.activeTopbarItem = item;
    }

    event.preventDefault();
  }

  onTopbarSearchItemClick(event: Event) {
    this.topbarItemClick = true;

    event.preventDefault();
  }

  onTopbarSubItemClick(event) {
    event.preventDefault();
  }

  get overlay(): boolean {
    return this.layoutMode === MenuOrientation.OVERLAY;
  }

  changeToStaticMenu() {
    this.layoutMode = MenuOrientation.STATIC;
  }

  changeToOverlayMenu() {
    this.layoutMode = MenuOrientation.OVERLAY;
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  ngOnDestroy() {
    if (this.documentClickListener) {
      this.documentClickListener();
    }
    // this.wssService.closeWebSocket();
  }
}
