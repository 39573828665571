<p-sidebar [(visible)]="displayInfoSupplier" position="right" [baseZIndex]="10000" styleClass="sidear-info-supplier"
    [showCloseIcon]="false" [closeOnEscape]="false" [dismissible]="false" [blockScroll]="true">
    <div class="grid hidden md:flex">
        <ng-container *ngIf="loadComplete">
            <div class="col-12 text-center w-full h-full flex align-items-center justify-content-center"
                style="height: calc(100vh - 48px)!important;">
                <p-progressSpinner [style]="{width: '150px', height: '250px'}"></p-progressSpinner>
            </div>
        </ng-container>
        <ng-container *ngIf="!loadComplete">
            <div class="col-4 p-0">
                <div class="grid m-0">
                    <div class="col-12 mt-4">
                        <div class="grid flex align-items-center" style="height: 48.96px;">
                            <div class="col-12">
                                <h2 class="m-0">RESUMEN</h2>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 p-0">
                        <p-scrollPanel [style]="{width: '100%', height: 'calc(100vh - 188px)'}">
                            <div class="grid m-0">
                                <div class="col-12 text-center">
                                    <div class="container mx-2 shadow-5 mb-3" style="border-radius: 24px;">
                                        <div class="card-profile">
                                            <div class="card-profile_visual">
                                                <p-tag [value]="tradeName" class="tag-info"></p-tag>
                                                <img *ngIf="logo.length > 0" class="w-full"
                                                    style="object-fit:cover; height: 125px!important; border-radius: 24px;"
                                                    [src]="logo" alt="">
                                                <img *ngIf="logo.length === 0" class="w-full"
                                                    style="object-fit:cover; height: 125px!important; border-radius: 24px;"
                                                    src="assets/images/no_logo.jpeg" alt="">
                                                <div class="container absolute top-0 w-full h-full overflow-hidden"
                                                    style="border-radius: 24px;">
                                                    <div class="box w-full h-full"
                                                        style="backdrop-filter: blur(2px);background: rgb(0 0 0 / 50%);">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="country">
                                                <img class="mr-3"
                                                    src="https://cbn-proveedores.s3.sa-east-1.amazonaws.com/cbn-images/countries/{{this.countryLogo | lowercase}}.png"
                                                    alt="" srcset="">
                                                <div style="height: 26px;">
                                                    <app-animated-check *ngIf="homologation === 'YES'">
                                                    </app-animated-check>
                                                </div>
                                            </div>
                                            <div class="card-profile_user-stats relative">
                                                <div class="grid m-0">
                                                    <div class="col-12 absolute text-center" style="top: -14.5%;">
                                                        <p-avatar *ngIf="logo.length > 0" [image]="logo"
                                                            styleClass="img-user" size="xlarge" shape="circle"
                                                            appendTo="body"></p-avatar>

                                                        <p-avatar *ngIf="logo.length === 0"
                                                            image="assets/images/no_logo.jpeg" styleClass="img-user"
                                                            size="xlarge" shape="circle" appendTo="body"></p-avatar>
                                                    </div>
                                                    <div class="col-12" style="margin-top:48px">
                                                        <div class="stats-holder">
                                                            <div class="user-stats">
                                                                <div class="user-infos">
                                                                    <span class="infos_name">{{businessName}}</span>
                                                                </div>
                                                            </div>
                                                            <div class="grid mt-2">
                                                                <div class="col-12">
                                                                    <div
                                                                        class="user-stats flex align-items-center justify-content-between">
                                                                        <span>
                                                                            <p-tag styleClass="ml-6 mr-2"
                                                                                severity="info" [value]="years"></p-tag>
                                                                        </span>
                                                                        <span>{{codeTributary}}</span>
                                                                        <span>
                                                                            <button pButton pRipple type="button"
                                                                                icon="pi pi-paperclip"
                                                                                label="{{files.length}}"
                                                                                class="p-button-rounded p-button-secondary mr-6 p-button-outlined"></button>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12">
                                                                    <div class="user-stats">
                                                                        <span>{{address}} <br>
                                                                            {{countryName}} - {{state}} - {{city}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12">
                                                                    <div class="user-stats">
                                                                        <strong>Contacto</strong>
                                                                        <span>
                                                                            <p class="m-0">
                                                                                <span class="mr-2 inline-block ">
                                                                                    <i
                                                                                        class="fas fa-mobile-alt mr-2"></i>
                                                                                    <span class="">{{phone}}</span>
                                                                                </span>
                                                                            </p>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12">
                                                                    <div class="user-stats">
                                                                        <strong>Actividad comercial</strong>
                                                                        <span class="text-center">
                                                                            <p-tag
                                                                                *ngFor="let item of activityCommercial"
                                                                                styleClass="m-2" severity="success"
                                                                                [value]="item"></p-tag>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6">
                                                                    <div class="user-stats">
                                                                        <strong>Tipo de empresa</strong>
                                                                        <span>{{typeCompany}}</span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6">
                                                                    <div class="user-stats">
                                                                        <strong>Sector comercial</strong>
                                                                        <span>{{sectorComercial}}</span>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12">
                                                                    <div class="user-stats">
                                                                        <div class="social">
                                                                            <a *ngIf="web.length > 0" target="_blank"
                                                                                [href]="web" class="mr-4 "><span
                                                                                    class="fa fa-globe text-lg"
                                                                                    aria-hidden="true"></span></a>
                                                                            <a *ngIf="linkedin.length > 0"
                                                                                target="_blank" [href]="linkedin"
                                                                                class="mr-4"><span
                                                                                    class="fab fa-linkedin text-lg"
                                                                                    aria-hidden="true"></span></a>
                                                                            <a *ngIf="facebook.length > 0"
                                                                                target="_blank" [href]="facebook"
                                                                                class=""><span
                                                                                    class="fab fa-facebook text-lg"
                                                                                    aria-hidden="true"></span></a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12"
                                                                    *ngIf="brochure.length > 0 || presentationLetter.length > 0">
                                                                    <div class="grid"
                                                                        style="border-top: 1px solid; font-size: 11px;">
                                                                        <div [ngClass]="{'border-set': brochure.length > 0 && presentationLetter.length > 0}"
                                                                            *ngIf="brochure.length > 0"
                                                                            class="col-6 flex align-items-center justify-content-center cursor-pointer"
                                                                            style="     margin-top: 13px;"
                                                                            (click)="opFilesBrochure.toggle($event)">
                                                                            BROCHURE <span
                                                                                class="ml-2 pi pi-download"></span>
                                                                        </div>
                                                                        <div *ngIf="presentationLetter.length > 0"
                                                                            class="col-6 flex align-items-center justify-content-center cursor-pointer"
                                                                            style="    margin-top: 13px;"
                                                                            (click)="opFilesPresentationLetter.toggle($event)">
                                                                            CARTA DE PRESENTACIÓN <span
                                                                                class="ml-2 pi pi-download"></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </p-scrollPanel>
                    </div>
                </div>
            </div>
            <div class="col-6 p-0">
                <div class="grid m-0">
                    <div class="col-12 mt-4">
                        <div class="grid flex align-items-center">
                            <div class="col-12">
                                <h2 class="m-0">DETALLE</h2>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 p-0">
                        <ng-container *ngIf="tabSidebar_info === 'resumen'">
                            <p-scrollPanel [style]="{width: '100%', height: 'calc(100vh - 188px)'}">
                                <div class="grid m-0">
                                    <div class="col-12">
                                        <h3 class="m-0">Resumen de la empresa</h3>
                                    </div>
                                    <div class="col-12">
                                        <p class="m-0" *ngIf="summary.length === 0">Sin datos disponibles</p>
                                        <p class="m-0" *ngIf="summary.length > 0">{{summary}}</p>
                                    </div>
                                    <div class="col-12">
                                        <h3 class="m-0">Información contable</h3>
                                    </div>
                                    <div class="col-12">
                                        <p class="m-0" *ngIf="accountingInformation.length === 0">Sin datos disponibles
                                        </p>
                                        <div class="grid m-0 relative w-full" *ngIf="accountingInformation.length > 0">
                                            <div class="col-12 experience-clients">
                                                <p-table [value]="accountingInformation"
                                                    styleClass="table-supplier-panel" [rows]="3" [paginator]="true">
                                                    <ng-template pTemplate="header">
                                                        <tr>
                                                            <th><span style="width: 100%;"></span> </th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-item let-i="rowIndex">
                                                        <tr>
                                                            <td>
                                                                <span style="width: 100%;">
                                                                    <div class="grid w-full">
                                                                        <div class="col-12 p-0">
                                                                            <div
                                                                                class="card shadow-5 m-0 flex align-items-center">
                                                                                <div class="grid m-0">
                                                                                    <div class="col-12">
                                                                                        <div class="grid">
                                                                                            <div class="col-12 p-0">
                                                                                                <div class="grid m-0">
                                                                                                    <div
                                                                                                        class="col-4 flex align-items-center ">
                                                                                                        <p class="m-0">
                                                                                                            <i style="font-size: 18px;"
                                                                                                                class="fas fa-calendar-check mr-3"></i>{{item.accountingYearRegistry}}
                                                                                                        </p>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="col-4 flex align-items-center">
                                                                                                        <p class="m-0">
                                                                                                            <i style="font-size: 18px;"
                                                                                                                class="fas fa-boxes mr-3"></i>USD
                                                                                                            {{item.accountingStockInventoryUSD
                                                                                                            |
                                                                                                            number:'1.2-2'}}
                                                                                                        </p>
                                                                                                    </div>
                                                                                                    <div class="col-4 ">
                                                                                                        <p
                                                                                                            class="m-0 flex align-items-center">
                                                                                                            <i style="font-size: 18px;"
                                                                                                                class="fas fa-file-invoice-dollar mr-3"></i>
                                                                                                            <button
                                                                                                                (click)="opFilesAb.toggle($event)"
                                                                                                                [disabled]="item.accountingBalanceSheet.length === 0"
                                                                                                                pButton
                                                                                                                pRipple
                                                                                                                type="button"
                                                                                                                label="{{item.accountingBalanceSheet.length}} - ARCHIVO"
                                                                                                                class="p-button-rounded p-button-secondary w-button p-button-outlined "></button>
                                                                                                        </p>
                                                                                                        <p-overlayPanel
                                                                                                            #opFilesAb>
                                                                                                            <ng-template
                                                                                                                pTemplate>
                                                                                                                <p-scrollPanel
                                                                                                                    [style]="{width: '16rem', height: '162px'}">
                                                                                                                    <app-list-files
                                                                                                                        [files]="item.accountingBalanceSheet">
                                                                                                                    </app-list-files>
                                                                                                                </p-scrollPanel>
                                                                                                            </ng-template>
                                                                                                        </p-overlayPanel>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-12 p-0">
                                                                                                <div class="grid m-0">
                                                                                                    <div
                                                                                                        class="col-4 pt-0 pb-0 flex align-items-center ">
                                                                                                        <p class="m-0">
                                                                                                            <i style="font-size: 18px;"
                                                                                                                class="fas fa-university mr-3"></i>USD
                                                                                                            {{item.accountingCapitalStockUSD
                                                                                                            |
                                                                                                            number:'1.2-2'}}
                                                                                                        </p>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="col-4 pt-0 pb-0 flex align-items-center">
                                                                                                        <p class="m-0">
                                                                                                            <i style="font-size: 18px;"
                                                                                                                class="fas fa-cog mr-3"></i>USD
                                                                                                            {{item.accountingAssetsUSD
                                                                                                            |
                                                                                                            number:'1.2-2'}}
                                                                                                        </p>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="col-4 pt-0 pb-0 flex align-items-center">
                                                                                                        <p
                                                                                                            class="m-0 flex align-items-center">
                                                                                                            <i style="font-size: 18px;"
                                                                                                                class="fas fa-file-invoice-dollar mr-3"></i>
                                                                                                            <button
                                                                                                                (click)="opFilesAc.toggle($event)"
                                                                                                                [disabled]="item.accountingProfitLossStatement.length === 0"
                                                                                                                pButton
                                                                                                                pRipple
                                                                                                                type="button"
                                                                                                                label="{{item.accountingProfitLossStatement.length}} - ARCHIVO"
                                                                                                                class="p-button-rounded p-button-secondary w-button p-button-outlined "></button>
                                                                                                        </p>
                                                                                                        <p-overlayPanel
                                                                                                            #opFilesAc>
                                                                                                            <ng-template
                                                                                                                pTemplate>
                                                                                                                <p-scrollPanel
                                                                                                                    [style]="{width: '16rem', height: '162px'}">
                                                                                                                    <app-list-files
                                                                                                                        [files]="item.accountingProfitLossStatement">
                                                                                                                    </app-list-files>
                                                                                                                </p-scrollPanel>
                                                                                                            </ng-template>
                                                                                                        </p-overlayPanel>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </ng-template>
                                                </p-table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <h3 class="m-0">Infraestructura</h3>
                                    </div>
                                    <div class="col-12">
                                        <div class="col-12 relative" #mapContainer style="overflow: hidden;">
                                            <ng-container>
                                                <div class="list-button-float" *ngIf="!listMap">
                                                    <div class="circle-off"></div>
                                                    <button [disabled]="loadInfra"
                                                        style="height:40px; width:40px;  background: black;" pButton
                                                        pRipple type="button" icon="fas fa-list-ul"
                                                        class="p-button-rounded p-button-secondary "
                                                        (click)="openListMap()"></button>
                                                </div>
                                                <div class="container-list-map animate__animated animate__slideInRight"
                                                    *ngIf="listMap">
                                                    <ng-container *ngIf="listInfraestructure.length === 0">
                                                        <div class="grid m-0 w-full h-full justify-content-end">
                                                            <div class="col-12 flex align-items-center flex-column justify-content-center"
                                                                style="width: 50%;">
                                                                <span
                                                                    style="color: white; font-size: 18px; text-align: center;"
                                                                    translate>Esta empresa no cuenta con
                                                                    infraestructuras registradas</span>
                                                                <button pButton pRipple type="button" label="CERRAR"
                                                                    icon="pi pi-plus"
                                                                    class="p-button-rounded p-button-danger mt-5 w-button"
                                                                    (click)="closeListMap()"></button>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngIf="listInfraestructure.length > 0">
                                                        <div class="grid m-0">
                                                            <div class="col-12 text-right tex">
                                                                <div class="circle"></div>
                                                                <button
                                                                    style="margin-top: 5px; margin-right: 5px; height:40px; width:40px;"
                                                                    pButton pRipple type="button" icon="fas fa-times"
                                                                    class="p-button-rounded p-button-danger"
                                                                    (click)="closeListMap()">
                                                                </button>
                                                            </div>
                                                            <div class="col-12">
                                                                <p-scrollPanel
                                                                    [style]="{width: '100%', height: '539px'}">
                                                                    <ng-container
                                                                        *ngFor="let item of listInfraestructure">
                                                                        <app-list-properties [type]="'SUMMARY'"
                                                                            [item]="item"
                                                                            (location)="openLocation(item)">
                                                                        </app-list-properties>
                                                                    </ng-container>
                                                                </p-scrollPanel>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </ng-container>
                                            <google-map height="665px" width="100%" [zoom]="zoom" [center]="center">
                                                <map-marker #somemarker="mapMarker"
                                                    *ngFor="let item of listInfraestructure" [position]="item.position"
                                                    (mapClick)="openInfo(somemarker, item)">
                                                </map-marker>
                                                <map-info-window>
                                                    <ng-container *ngIf="temporalInfraestructure">
                                                        <div style="width: 150px;     flex-direction: column;"
                                                            class="flex align-items-center flex-wrap text-center ">
                                                            <p class="m-0 text-blue-500 text-center">
                                                                <strong
                                                                    class="uppercase">{{temporalInfraestructure.name}}</strong>
                                                            </p>
                                                            <p class="m-0  text-center">
                                                                {{temporalInfraestructure.nameType}} -
                                                                {{temporalInfraestructure.area}}m2</p>
                                                            <p class="m-0 text-center">
                                                                <strong>{{temporalInfraestructure.address}}</strong>
                                                            </p>
                                                        </div>
                                                    </ng-container>
                                                </map-info-window>
                                            </google-map>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <h3 class="m-0">Experiencia y clientes</h3>
                                    </div>
                                    <div class="col-12">
                                        <p class="m-0" *ngIf="listClient.length === 0">Sin datos disponibles</p>
                                        <div class="grid m-0 relative w-full" *ngIf="listClient.length > 0">
                                            <div class="col-12 experience-clients">
                                                <p-table [value]="listClient" styleClass="table-supplier-panel"
                                                    [rows]="3" [paginator]="true">
                                                    <ng-template pTemplate="header">
                                                        <tr>
                                                            <th><span style="width: 100%;"></span> </th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-item let-i="rowIndex">
                                                        <tr>
                                                            <td>
                                                                <span style="width: 100%; text-align: left;">
                                                                    <div
                                                                        class="card shadow-5 m-0 flex align-items-center">
                                                                        <div class="grid m-0 w-full">
                                                                            <div class="col-12 flex align-items-center">
                                                                                <h3 class="font-bold overflow-hidden text-overflow-ellipsis white-space-nowrap w-full m-0"
                                                                                    style="color: var(--primary-color);">
                                                                                    <span
                                                                                        pTooltip="{{item.businessName}}"
                                                                                        tooltipPosition="top">{{item.businessName}}</span>
                                                                                </h3>
                                                                                <div class="flex align-items-center">
                                                                                    <img class="mr-3"
                                                                                        src="https://cbn-proveedores.s3.sa-east-1.amazonaws.com/cbn-images/countries/{{item.country | lowercase}}.png"
                                                                                        alt="" srcset="">
                                                                                    <button style="float: right;"
                                                                                        [disabled]="item.files.length === 0"
                                                                                        pButton pRipple type="button"
                                                                                        icon="fas fa-paperclip"
                                                                                        label="{{item.files.length}}"
                                                                                        class="p-button-rounded p-button-secondary p-button-outlined"
                                                                                        (click)="opFiless.toggle($event)"></button>
                                                                                    <p-overlayPanel #opFiless>
                                                                                        <ng-template pTemplate>
                                                                                            <app-list-files
                                                                                                [files]="item.files">
                                                                                            </app-list-files>
                                                                                        </ng-template>
                                                                                    </p-overlayPanel>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-12 p-0">
                                                                                <div class="grid m-0 w-full">
                                                                                    <div
                                                                                        class="col-4 flex align-items-center ">
                                                                                        <p class="m-0"
                                                                                            style="color: #689F38;">
                                                                                            {{item.codeTributary}}</p>
                                                                                    </div>
                                                                                    <div
                                                                                        class="col-4 flex align-items-center">
                                                                                        <p class="m-0"><i
                                                                                                style="font-size: 18px;"
                                                                                                class="fas fa-dollar-sign mr-3"></i>
                                                                                            {{getRango(item.averageSalesLastYear)}}
                                                                                        </p>
                                                                                    </div>
                                                                                    <div
                                                                                        class="col-4 flex justify-content-end">
                                                                                        <p
                                                                                            class="m-0 flex align-items-center">
                                                                                            <i style="font-size: 18px;"
                                                                                                class="fas fa-user-friends mr-3"></i>
                                                                                            <button pButton pRipple
                                                                                                type="button"
                                                                                                label="CONTACTOS"
                                                                                                (click)="opContact.toggle($event)"
                                                                                                class="p-button-rounded p-button-secondary w-button p-button-outlined "
                                                                                                [disabled]="item.contacts.length === 0"></button>
                                                                                        </p>
                                                                                        <p-overlayPanel #opContact>
                                                                                            <ng-template pTemplate>
                                                                                                <p-table
                                                                                                    [value]="item.contacts"
                                                                                                    styleClass="table-list-contact"
                                                                                                    [rows]="2"
                                                                                                    [paginator]="true">
                                                                                                    <ng-template
                                                                                                        pTemplate="header">
                                                        <tr>
                                                            <th><span style="width: 100%;"></span> </th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-item let-i="rowIndex">
                                                        <tr>
                                                            <td>
                                                                <span class="text-left" style="width: 100%;">
                                                                    <div class="grid align-items-center shadow-5"
                                                                        style="width: 300px; border-radius: 24px;">
                                                                        <div class="col-3">
                                                                            <p-avatar *ngIf="item.gender === 'M'"
                                                                                image="https://cbn-proveedores.s3-sa-east-1.amazonaws.com/cbn-images/gender/circle-cropped+(19).png"
                                                                                size="xlarge" shape="circle"></p-avatar>
                                                                            <p-avatar *ngIf="item.gender === 'F'"
                                                                                image="https://icon-library.com/images/free-woman-icon/free-woman-icon-4.jpg"
                                                                                size="xlarge" shape="circle"></p-avatar>
                                                                        </div>
                                                                        <div class="col-9">
                                                                            <p>{{item.name}}</p>
                                                                            <p>{{item.email}}</p>
                                                                            <p>
                                                                                <span class="mr-2 inline-block ">
                                                                                    <i class="fas fa-phone mr-2"></i>
                                                                                    <span>{{item.phone}}</span>
                                                                                </span>
                                                                                <span>
                                                                                    - #{{item.extensionPhone}}
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </ng-template>
                                                </p-table>
                                                </ng-template>
                                                </p-overlayPanel>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 p-0">
                                        <div class="grid m-0">
                                            <div class="col-4 pt-0 pb-0 flex align-items-center ">
                                                <p class="m-0" style="color: var(--bold-text);">{{item.sectorName}}</p>
                                            </div>
                                            <div class="col-4 pt-0 pb-0 flex align-items-center">
                                                <p class="m-0"><i style="font-size: 18px;"
                                                        class="fas fa-history mr-3"></i>{{item.YearCommercialRel}} </p>
                                            </div>
                                            <div class="col-4 pt-0 pb-0 flex align-items-center justify-content-end">
                                                <p class="m-0 flex align-items-center"><i style="font-size: 22px;"
                                                        class="pi pi-info-circle mr-3"></i> <button pButton pRipple
                                                        type="button" label="DETALLE" (click)="opDetail.toggle($event)"
                                                        class="p-button-rounded p-button-secondary w-button  p-button-outlined "
                                                        [disabled]="item.detail.length === 0"></button></p>
                                                <p-overlayPanel #opDetail>
                                                    <ng-template pTemplate>
                                                        <div class="grid align-items-center text-center"
                                                            style="width: 300px;">
                                                            <div class="col-12"
                                                                style="height: auto; max-height: 200px; overflow-y: auto;">
                                                                <div [innerHTML]="item.detail">

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </p-overlayPanel>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    </div>
                    </span>
                    </td>
                    </tr>
                    </ng-template>
                    </p-table>
                </div>
            </div>
    </div>
    <div class="col-12">
        <h3 class="m-0">Certificaciones</h3>
    </div>
    <div class="col-12">
        <p class="m-0"
            *ngIf="!certificateISO9001 && !certificateGMP && !certificateBCR && !certificateHACCP && !certificateBASC && !certificateISO14001 && !certificateISO18001">
            Sin datos disponibles</p>
        <marquee behavior="" direction=""
            *ngIf="certificateISO9001 && certificateGMP && certificateBCR && certificateHACCP && certificateBASC && certificateISO14001 && certificateISO18001">
            <div class="row service-items">
                <div class="col col-d-12 col-t-12 col-m-12 border-line-h" style="display: flex;">
                    <div *ngIf="certificateISO9001" class="col col-d-6 col-t-6 col-m-6" style="padding: 5px;">
                        <div style="display: flex; align-items: center; margin: 5px 0px;">
                            <img pTooltip="{{ 'Perfil.quialitymanagement.qualityManagement' | translate }}"
                                tooltipPosition="bottom" placeholder="Bottom" src="assets/certificacion/garantia.svg"
                                height="30">
                            <span style="margin-left: 10px;">{{'certificate' | translate }}
                                -
                                ISO 9001</span>
                        </div>
                    </div>
                    <div *ngIf="certificateGMP" class="col col-d-6 col-t-6 col-m-6" style="padding: 5px;">
                        <div style="display: flex; align-items: center; margin: 5px 0px;">
                            <img pTooltip="{{ 'Perfil.quialitymanagement.GMP' | translate }}" tooltipPosition="bottom"
                                placeholder="Bottom" src="assets/certificacion/garantia (3).svg" height="30">
                            <span style="margin-left: 10px;">{{'certificate' | translate }}
                                -
                                GMP</span>
                        </div>
                    </div>
                    <div *ngIf="certificateBCR" class="col col-d-6 col-t-6 col-m-6" style="padding: 5px;">
                        <div style="display: flex; align-items: center; margin: 5px 0px;">
                            <img pTooltip="{{ 'Perfil.quialitymanagement.BRC' | translate }}" tooltipPosition="bottom"
                                placeholder="Bottom" src="assets/certificacion/garantia (5).svg" height="30">
                            <span style="margin-left: 10px;">{{'certificate' | translate }}
                                -
                                BCR</span>
                        </div>
                    </div>
                    <div *ngIf="certificateHACCP" class="col col-d-6 col-t-6 col-m-6" style="padding: 5px;">
                        <div style="display: flex; align-items: center; margin: 5px 0px;">
                            <img pTooltip="{{ 'Perfil.quialitymanagement.HACCP' | translate }}" tooltipPosition="bottom"
                                placeholder="Bottom" src="assets/certificacion/garantia (4).svg" height="30">
                            <span style="margin-left: 10px;">{{'certificate' | translate }}
                                -
                                HACCP</span>
                        </div>
                    </div>
                    <div *ngIf="certificateBASC" class="col col-d-6 col-t-6 col-m-6" style="padding: 5px;">
                        <div style="display: flex; align-items: center; margin: 5px 0px;">
                            <img pTooltip="{{ 'Perfil.quialitymanagement.BASC' | translate }}" tooltipPosition="bottom"
                                placeholder="Bottom" src="assets/certificacion/garantia (7).svg" height="30">
                            <span style="margin-left: 10px;">{{'certificate' | translate }}
                                -
                                BASC</span>
                        </div>
                    </div>
                    <div *ngIf="certificateISO14001" class="col col-d-6 col-t-6 col-m-6" style="padding: 5px;">
                        <div style="display: flex; align-items: center; margin: 5px 0px;">
                            <img pTooltip="{{ 'Perfil.quialitymanagement.environmentalProtection' | translate }}"
                                tooltipPosition="bottom" placeholder="Bottom"
                                src="assets/certificacion/garantia (1).svg" height="30">
                            <span style="margin-left: 10px;">{{'certificate' | translate }}
                                -
                                Calidad ISO 14001</span>
                        </div>
                    </div>
                    <div *ngIf="certificateISO18001" class="col col-d-6 col-t-6 col-m-6" style="padding: 5px;">
                        <div style="display: flex; align-items: center; margin: 5px 0px;">
                            <img pTooltip="{{ 'Perfil.quialitymanagement.securityHealth' | translate }}"
                                tooltipPosition="bottom" placeholder="Bottom"
                                src="assets/certificacion/garantia (2).svg" height="30">
                            <span style="margin-left: 10px;">{{'certificate' | translate }}
                                18001 / ISO 45001</span>
                        </div>
                    </div>
                </div>
            </div>
        </marquee>
    </div>
    <div class="col-12">
        <h3 class="m-0">Productos y servicios</h3>
    </div>
    <div class="col-12">
        <p class="m-0" *ngIf="catalogueList.length === 0">
            Sin datos disponibles</p>
            
                <div class="grid-system">
                    <div class="item" *ngFor="let list of catalogueList">
                        <div class="relative card-catalogue shadow-5">
                            <swiper [navigation]="false" class="mySwiper" [autoplay]="{ delay: 2500, disableOnInteraction: false}">
                              <ng-container *ngIf="list.files.length > 0">
                                <ng-template swiperSlide *ngFor="let item of list.files">
                                  <img *ngIf="item.type === 'PHOTO'" [src]="item.url" alt="" srcset="">
                                  <img *ngIf="item.type === 'VIDEO'" src="https://provcatalogue.s3.us-east-2.amazonaws.com/no_image_camera.jpg"
                                    alt="" srcset="">
                                </ng-template>
                              </ng-container>
                              <ng-container *ngIf="list.files.length === 0">
                                <ng-template swiperSlide>
                                  <img src="https://provcatalogue.s3.us-east-2.amazonaws.com/no_image_camera.jpg" alt="" srcset="">
                                </ng-template>
                              </ng-container>
                            </swiper>
                        
                            <div class="title-catalogue">
                              
                            </div>
                            <div class="tex-catalogue">
                              <h3>{{list.catalogueName}}</h3>
                            </div>
                        </div>
                    </div>
                </div>

    </div>
    <div class="col-12">
        <h3 class="m-0">Riesgo crediticio
        </h3>
    </div>
    <div class="col-12">
        <p class="m-0" *ngIf="financialReference.length === 0">Sin datos disponibles</p>
        <div class="grid m-0 relative w-full" *ngIf="financialReference.length > 0">
            <div class="col-12 experience-clients">
                <p-table [value]="financialReference" styleClass="table-supplier-panel" [rows]="3" [paginator]="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th><span style="width: 100%;"></span> </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-item let-i="rowIndex">
                        <tr>
                            <td>
                                <span class="text-left" style="width: 100%;">
                                    <div class="card shadow-5 p-3 flex align-items-center flex-wrap m-0">
                                        <div class="grid w-full align-items-center m-0">
                                            <div class="w-full flex align-items-center justify-content-between">
                                                <h3 class="w-full ml-2" style="color: var(--primary-color);">
                                                    {{item.financialEntity}}</h3>
                                                <button style="margin-right: 0.35rem;"
                                                    [disabled]="item.financialReferenceReportFile.length === 0" pButton
                                                    pRipple type="button" icon="pi pi-paperclip"
                                                    label="{{item.financialReferenceReportFile.length}}"
                                                    class="p-button-rounded p-button-secondary p-button-outlined"
                                                    (click)="opFilesFinancialReference.toggle($event)"></button>
                                                <p-overlayPanel #opFilesFinancialReference>
                                                    <ng-template pTemplate>
                                                        <p-scrollPanel [style]="{width: '16rem', height: '162px'}">
                                                            <app-list-files [files]="item.financialReferenceReportFile">
                                                            </app-list-files>
                                                        </p-scrollPanel>
                                                    </ng-template>
                                                </p-overlayPanel>
                                            </div>
                                            <div class="col-6"><i style="font-size: 18px;" class="fas fa-star-half-alt"
                                                    aria-hidden="true"></i>&nbsp;&nbsp;
                                                <span>{{item.financialReferenceCreditRating | number:'1.2-2'}}</span>
                                            </div>
                                            <div class="col-6"><i style="font-size: 18px;" class="fas fa-calendar-check"
                                                    aria-hidden="true"></i>&nbsp;&nbsp;
                                                <span>{{(item.financialReferenceReportDate * 1000) |
                                                    date:"dd/MM/yyyy, h:mm a"}}</span>
                                            </div>


                                        </div>
                                    </div>
                                </span>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
    </div>
    </p-scrollPanel>
    </ng-container>
    <ng-container *ngIf="tabSidebar_info === 'contacto'">
        <p-scrollPanel [style]="{width: '100%', height: 'calc(100vh - 188px)'}">
            <div class="grid m-0">
                <div class="col-12">
                    <h3 class="m-0">Representantes disponibles
                    </h3>
                </div>
                <div class="col-12">
                    <div class="grid m-0 relative w-full">
                        <div class="col-12 experience-clients">
                            <p class="m-0" *ngIf="executives.length === 0">Sin datos disponibles</p>
                            <p-table *ngIf="executives.length > 0" [value]="executives"
                                styleClass="table-supplier-panel" [rows]="4" [paginator]="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th><span style="width: 100%;"></span> </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-item let-i="rowIndex">
                                    <tr>
                                        <td>
                                            <span class="text-left" style="width: 100%;">
                                                <div class="grid m-0 ">
                                                    <div class="col-12">
                                                        <div class="card shadow-5 m-0 flex align-items-center">
                                                            <div class="card-img ">
                                                                <p-avatar [image]="item.avatar" shape="circle"
                                                                    size="large"
                                                                    [style]="{'height':'6rem', 'width': '6rem'}">
                                                                </p-avatar>
                                                            </div>
                                                            <div class="card-etc ">
                                                                <h3
                                                                    class="font-bold overflow-hidden text-overflow-ellipsis white-space-nowrap w-full">
                                                                    <span pTooltip="{{item.name}}"
                                                                        tooltipPosition="top">{{item.name}}</span>
                                                                </h3>
                                                                <a style="color: var(--primary-color);"
                                                                    href="mailto:{{item.email}}">{{item.email}}</a>
                                                                <div
                                                                    *ngIf="item.cellPhonePrefix.length > 0 || item.cellPhone.length > 0 || item.phonePrefix.length > 0 || item.phone.length > 0 || item.annexed.length > 0">
                                                                    <p>
                                                                        <span class="mr-2 inline-block"
                                                                            *ngIf="item.cellPhonePrefix.length > 0 || item.cellPhone.length > 0">
                                                                            <i class="fas fa-mobile-alt mr-2"></i>
                                                                            <span
                                                                                *ngIf="item.cellPhonePrefix.length > 0">({{item.cellPhonePrefix}})
                                                                            </span>
                                                                            <span
                                                                                *ngIf="item.cellPhone > 0">{{item.cellPhone}}</span>

                                                                        </span>
                                                                        <span class="mr-2 inline-block"
                                                                            *ngIf="item.phonePrefix.length > 0 || item.phone.length > 0">
                                                                            <i class="fas fa-phone mr-2"></i>
                                                                            <span
                                                                                *ngIf="item.phonePrefix.length > 0">({{item.phonePrefix}})</span>
                                                                            <span
                                                                                *ngIf="item.phone.length > 0">{{item.phone}}</span>
                                                                        </span>
                                                                        <span
                                                                            *ngIf="item.annexed.length > 0">#{{item.annexed}}
                                                                        </span>
                                                                    </p>
                                                                </div>

                                                            </div>
                                                            <div class="card-button text-center">
                                                                <button pButton pRipple type="button" label="CHAT"
                                                                    class="p-button-rounded p-button-secondary w-button p-button-outlined"></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </span>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
            </div>
        </p-scrollPanel>
    </ng-container>
    <ng-container *ngIf="tabSidebar_info === 'estado'">
        <p-scrollPanel [style]="{width: '100%', height: 'calc(100vh - 188px)'}">
            <div class="grid m-2">
                <!-- <div class="col-12 p-0">
                                                            <h3 style="margin: 5px 0px;">INTERACCION COMERCIAL</h3>
                                                        </div>
                                                        <div class="col-12 p-0 header-icons">
                                                            <div class="grid m-0">
                                                                <div class="col-4 flex-center-v">
                                                                    <i pTooltip="Valor total adjudicado"
                                                                        class="fas fa-file-contract cursor-p"></i>
                                                                    <span>USD 49,965.65</span>
                                                                </div>
                                                                <div class="col-4 flex-center-v">
                                                                    <i pTooltip="Nº de procesos adjudicados" class="fas fa-copy cursor-p"></i>
                                                                    <span>11</span>
                                                                </div>
                                                                <div class="col-4 flex-center-v">
                                                                    <i pTooltip="Nº de reclamos realizados"
                                                                        class="fas fa-exclamation-triangle cursor-p"></i> <span>0</span>
                                                                </div>
                                                            </div>
                                                        </div> -->
                <div class="col-12 p-0">
                    <h3 style="margin: 5px 0px;">CLASIFICACIÓN (Matriz de kraljic)</h3>
                </div>
                <div class="col-12 p-0">
                    <div class="grid m-0 justify-content-center">
                        <div class="col-12 box-principal">
                            <div class="grid m-0">
                                <span class="float-span-kraljic-princ">IMPACTO <br> FINANCIERO</span>
                                <span class="float-span-kraljic-botom">COMPLEJIDAD DEL SUMINISTRO</span>
                                <div class="col-12" style="padding-bottom: 0;">
                                    <div class="grid m-0">
                                        <span class="float-span-kraljic-top">ALTO</span>
                                        <div class="col-6">
                                            <div class="grid m-0">
                                                <div class="col-12 box-kraljic">
                                                    APALANCADO
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-6">
                                            <div class="grid m-0">
                                                <div class="col-12 box-kraljic">
                                                    ESTRATEGICO
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12" style="padding-top: 0;">
                                    <div class="grid m-0">
                                        <span class="float-span-kraljic-bottom">BAJA</span>
                                        <div class="col-6">
                                            <div class="grid m-0">
                                                <span class="float-span-kraljic-botom-l">BAJA</span>
                                                <div class="col-12 box-kraljic">
                                                    NO CRITICO
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="grid m-0">
                                                <span class="float-span-kraljic-botom-r">ALTA</span>
                                                <div class="col-12 box-kraljic">
                                                    CUELLOS DE <br> BOTELLA
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 p-0 flex-center-v" style="margin-top: 67px;">
                    <h3 style="margin: 5px 0px;">INFORMACION COMPARTIDA</h3>
                    <div class="title-flex">
                        <span
                            style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                            <div class="mr-2" style="height: 25px;">
                                <app-animated-check></app-animated-check>
                            </div>
                            COMPLETADO
                        </span>
                        <span>
                            <i class="fas fa-minus"></i>
                            PARCIAL
                        </span>
                        <span>
                            <i class="pi pi-times"></i>
                            S/I
                        </span>
                    </div>
                </div>
                <div class="col-12 p-0" style="font-weight: bold;">
                    <div class="grid m-0">
                        <div class="col-12 detail-title">
                            <span class="border-blue">INFORMACION BASICA &nbsp;&nbsp;&nbsp;&nbsp;
                                {{ (10.5 + quantityLineBusiness + quantityExecutive + quantityAccountBank) }}/35</span>
                        </div>
                        <div class="col-6 p-0">
                            <div class="col-12 p-0">
                                <div class="detail-subtitle">
                                    DATOS GENERALES
                                </div>
                                <div class="detail-counter-icon">
                                    <span>10.5/10.50</span>
                                    <span
                                        style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                        <div class="mr-2" style="height: 25px;">
                                            <app-animated-check></app-animated-check>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div class="col-12 p-0">
                                <div class="detail-subtitle">
                                    LINEAS DE NEGOCIO
                                </div>
                                <div class="detail-counter-icon">
                                    <span>{{quantityLineBusiness}}/10.50</span>
                                    <span *ngIf="quantityLineBusiness === 10.5"
                                        style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                        <div class="mr-2" style="height: 25px;">
                                            <app-animated-check></app-animated-check>
                                        </div>
                                    </span>
                                    <span *ngIf="quantityLineBusiness < 10.5"
                                        style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                        <i class="fas fa-minus"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 p-0 border-left">
                            <div class="col-12 p-0">
                                <div class="detail-subtitle">
                                    EJECUTIVOS DE EMPRESA
                                </div>
                                <div class="detail-counter-icon">
                                    <span>{{quantityExecutive}}/8.75</span>
                                    <span *ngIf="quantityExecutive === 8.75"
                                        style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                        <div class="mr-2" style="height: 25px;">
                                            <app-animated-check></app-animated-check>
                                        </div>
                                    </span>
                                    <span *ngIf="quantityExecutive < 8.75"
                                        style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                        <i class="fas fa-minus"></i>
                                    </span>
                                </div>
                            </div>
                            <div class="col-12 p-0">
                                <div class="detail-subtitle">
                                    CUENTAS BANCARIAS
                                </div>
                                <div class="detail-counter-icon">
                                    <span>{{quantityAccountBank}}/5.25</span>
                                    <span *ngIf="quantityAccountBank === 5.25"
                                        style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                        <div class="mr-2" style="height: 25px;">
                                            <app-animated-check></app-animated-check>
                                        </div>
                                    </span>
                                    <span *ngIf="quantityAccountBank < 5.25"
                                        style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                        <i class="fas fa-minus"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 p-0" style="font-weight: bold;">
                    <div class="grid m-0">
                        <div class="col-12 detail-title">
                            <span class="border-yellow">SOLIDEZ COMERCIAL &nbsp;&nbsp;&nbsp;&nbsp;
                                {{ (quantityLineProduct + quantityExperienceClients + quantityInfraestructure +
                                quantityConditionTerms) }}/60</span>
                        </div>
                        <div class="col-6 p-0">
                            <div class="grid m-0">
                                <div class="col-12 p-0">
                                    <div class="detail-subtitle">
                                        LINEAS DE PRODUCTO
                                    </div>
                                    <div class="detail-counter-icon">
                                        <span>{{quantityLineProduct}}/21</span>
                                        <span *ngIf="quantityLineProduct === 21"
                                            style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                            <div class="mr-2" style="height: 25px;">
                                                <app-animated-check></app-animated-check>
                                            </div>
                                        </span>
                                        <span *ngIf="quantityLineProduct < 21"
                                            style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                            <i class="fas fa-minus"></i>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-12 p-0">
                                    <div class="detail-subtitle">
                                        EXPERIENCIA Y CLIENTES
                                    </div>
                                    <div class="detail-counter-icon">
                                        <span>{{quantityExperienceClients}}/21</span>
                                        <span *ngIf="quantityExperienceClients === 21"
                                            style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                            <div class="mr-2" style="height: 25px;">
                                                <app-animated-check></app-animated-check>
                                            </div>
                                        </span>
                                        <span *ngIf="quantityExperienceClients < 21"
                                            style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                            <i class="fas fa-minus"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-6 p-0 border-left">
                            <div class="grid m-0">
                                <div class="col-12 p-0">
                                    <div class="detail-subtitle">
                                        INFRAESTRUCTURA
                                    </div>
                                    <div class="detail-counter-icon">
                                        <span>{{quantityInfraestructure}}/15</span>
                                        <span *ngIf="quantityInfraestructure === 15"
                                            style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                            <div class="mr-2" style="height: 25px;">
                                                <app-animated-check></app-animated-check>
                                            </div>
                                        </span>
                                        <span *ngIf="quantityInfraestructure < 15"
                                            style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                            <i class="fas fa-minus"></i>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-12 p-0">
                                    <div class="detail-subtitle">
                                        COND. COMERCIALES
                                    </div>
                                    <div class="detail-counter-icon">
                                        <span>{{quantityConditionTerms}}/3</span>
                                        <span *ngIf="quantityConditionTerms === 3"
                                            style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                            <div class="mr-2" style="height: 25px;">
                                                <app-animated-check></app-animated-check>
                                            </div>
                                        </span>
                                        <span *ngIf="quantityConditionTerms < 3"
                                            style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                            <i class="fas fa-minus"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-12 p-0" style="font-weight: bold;">
                    <div class="grid m-0">
                        <div class="col-12 detail-title">
                            <span class="border-green">SOLIDEZ DE NEGOCIO &nbsp;&nbsp;&nbsp;&nbsp;
                                {{quantityQualityManagement}}/5</span>
                        </div>
                        <div class="col-6 p-0">
                            <div class="grid m-0">
                                <div class="col-12 p-0">
                                    <div class="detail-subtitle">
                                        GESTION DE CALIDAD
                                    </div>
                                    <div class="detail-counter-icon">
                                        <span>{{quantityQualityManagement}}/5</span>
                                        <span *ngIf="quantityQualityManagement === 5"
                                            style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                            <div class="mr-2" style="height: 25px;">
                                                <app-animated-check></app-animated-check>
                                            </div>
                                        </span>
                                        <span *ngIf="quantityQualityManagement < 5"
                                            style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                            <i class="fas fa-minus"></i>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-12 p-0">
                                    <div class="detail-subtitle">
                                        GESTION EN EL TRABAJO
                                    </div>
                                    <div class="detail-counter-icon">
                                        <span></span>
                                        <span
                                            style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                            <i class="fas fa-minus"></i>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-12 p-0">
                                    <div class="detail-subtitle">
                                        PREFERENCIAS FINANCIERAS
                                    </div>
                                    <div class="detail-counter-icon">
                                        <span></span>
                                        <span
                                            style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                            <div class="mr-2" style="height: 25px;">
                                                <app-animated-check></app-animated-check>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 p-0 border-left">
                            <div class="grid m-0">
                                <div class="col-12 p-0">
                                    <div class="detail-subtitle">
                                        GESTION AMBIENTAL
                                    </div>
                                    <div class="detail-counter-icon">
                                        <span></span>
                                        <span
                                            style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                            <div class="mr-2" style="height: 25px;">
                                                <app-animated-check></app-animated-check>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-12 p-0">
                                    <div class="detail-subtitle">
                                        INFORMACION CONTABLE
                                    </div>
                                    <div class="detail-counter-icon">
                                        <span></span>
                                        <span
                                            style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                            <div class="mr-2" style="height: 25px;">
                                                <app-animated-check></app-animated-check>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 p-0 mt-3">
                    <h3 class="m-0">Estado
                    </h3>
                </div>
                <div class="col-12 p-0">
                    <!-- <app-list-activities-proccess [list]="list_activity_proccess" [companies]="companies"
                                                            [managments]="managements" [areas]="areas" [premises]="premises"></app-list-activities-proccess> -->
                </div>
            </div>
        </p-scrollPanel>
    </ng-container>
    </div>
    </div>
    </div>
    <div class="col-2 p-0">
        <div class="grid m-0">
            <div class="col-12 mt-4">
                <div class="grid flex align-items-center" style="height: 48.96px;">
                    <div class="col-12">
                        <h2 class="m-0"></h2>
                    </div>
                </div>
            </div>
            <div class="col-12 p-0">
                <p-scrollPanel [style]="{width: '100%', height: 'calc(100vh - 188px)'}">
                    <div class="grid m-0">
                        <div class="col-12 flex align-items-center justify-content-center py-0">
                            <div class="text-center">
                                <p class="mt-0 font-bold">INFORMACIÓN</p>
                                <div class="c100 {{informationColor}} p{{informationNum}} small backgroud-graphic"
                                    style="font-size: 100px;">
                                    <span>{{informationScore}}</span>
                                    <div class="slice flex align-items-center justify-content-center">
                                        <div class="bar"></div>
                                        <div class="fill"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 flex align-items-center justify-content-center py-0">

                            <div class="text-center">
                                <p class=" font-bold">REPUTACIÓN</p>
                                <div class="c100 {{reputationColor}} p{{reputationNum}} small backgroud-graphic"
                                    style="font-size: 100px;">
                                    <span>{{reputationScore}}</span>
                                    <div class="slice flex align-items-center justify-content-center">
                                        <div class="bar"></div>
                                        <div class="fill"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 flex align-items-center justify-content-center py-0">

                            <div class="text-center">
                                <p class=" font-bold">CONFIANZA</p>
                                <div class="c100 {{trustColor}} p{{trustNum}} small backgroud-graphic"
                                    style="font-size: 100px;">
                                    <span>{{trustScore}}</span>
                                    <div class="slice flex align-items-center justify-content-center">
                                        <div class="bar"></div>
                                        <div class="fill"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </p-scrollPanel>
            </div>
        </div>
    </div>
    <div class="col-12 pb-0 text-right" style="margin-top: calc(1em + 10px);">
        <div class="grid">
            <div class="col-4 p-0 text-left">
                <button (click)="tabSidebar_info = 'resumen'"
                    [ngClass]="{button_tab_selected: tabSidebar_info === 'resumen'}" pButton pRipple type="button"
                    label="RESUMEN" [loading]="loading"
                    class="w-button p-button-rounded p-button-secondary mr-3 p-button-outlined"></button>

                <button (click)="tabSidebar_info = 'contacto'"
                    [ngClass]="{button_tab_selected: tabSidebar_info === 'contacto'}" pButton pRipple type="button"
                    label="CONTACTO" [loading]="loading"
                    class="w-button p-button-rounded p-button-secondary mr-3 p-button-outlined"></button>

                <button (click)="tabSidebar_info = 'estado'" [loading]="loading"
                    [ngClass]="{button_tab_selected: tabSidebar_info === 'estado'}" pButton pRipple type="button"
                    label="ESTADO" class="w-button p-button-rounded p-button-secondary p-button-outlined"></button>
            </div>
            <div class="col-6 p-0">

            </div>
            <div class="col-2 p-0">
                <button pButton pRipple type="button" label="{{'closeU' | translate}}" [loading]="loading"
                    class=" p-button-rounded w-button p-button-danger" (click)="closeModal()"></button>
            </div>
        </div>
    </div>
    </ng-container>
    </div>
    <div class="grid flex md:hidden">
        <ng-container *ngIf="loadComplete">
            <div class="col-12 text-center w-full h-full flex align-items-center justify-content-center"
                style="height: calc(100vh - 48px)!important;">
                <p-progressSpinner [style]="{width: '150px', height: '250px'}"></p-progressSpinner>
            </div>
        </ng-container>
        <ng-container *ngIf="!loadComplete">
            <div class="col-12 p-0">
                
                    <div class="grid m-0">
                        <div class="col-12 mt-4">
                            <div class="grid flex align-items-center" style="height: 48.96px;">
                                <div class="col-12">
                                    <h2 class="m-0">RESUMEN</h2>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 p-0">
                            <p-scrollPanel [style]="{width: '100%', height: 'calc(var(--doc-height) - 165px)'}">
                                <div class="grid m-0">
                                    <div class="col-12 text-center">
                                        <div class="container m-0 md:mx-2 shadow-5 md:mb-3 relative" style="border-radius: 24px;">
                                            <div class="card-profile pt-3">
                                                <img class="w-full" style="object-fit: contain;height: 125px!important;border-radius: 24px;padding: 10px;" [src]="logo" alt="">
                                                <div class="card-profile_user-stats relative">
                                                    <div class="grid m-0">
                                                        <div class="col-12 mt-2">
                                                            <div class="stats-holder">
                                                                <div class="user-stats">
                                                                    <div class="user-infos">
                                                                        <span class="infos_name">{{businessName}}</span>
                                                                    </div>
                                                                </div>
                                                                <div class="stats-holder flex align-items-center justify-content-center">
                                                                    <img class="mr-3" src="https://cbn-proveedores.s3.sa-east-1.amazonaws.com/cbn-images/countries/{{this.country | lowercase}}.png" alt="" srcset="">
                                                                    <p-tag [value]="tradeName" class="tag-info"></p-tag>
                                                                    <span class="inline-block ml-3" style="height: 24px;" *ngIf="homologation === 'YES'">
                                                                        <app-animated-check></app-animated-check>
                                                                    </span>
                                                                </div>
                                                                <div class="grid mt-2">
                                                                    <div class="col-12">
                                                                        <div class="user-stats flex align-items-center justify-content-between">
                                                                            <span>
                                                                                <p-tag styleClass="ml-2 mr-2" severity="info" [value]="years"></p-tag>
                                                                            </span>
                                                                            <span>{{codeTributary}}</span>
                                                                            <span>
                                                                                <button pButton pRipple type="button" icon="pi pi-paperclip" label="{{files.length}}" 
                                                                                class="p-button-rounded p-button-secondary mr-2 p-button-outlined"
                                                                                (click)="opFiles.toggle($event)"></button>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <div class="user-stats">
                                                                            <strong>Dirección</strong>
                                                                            <span>{{address}} <br>
                                                                                {{countryName}} - {{state}} - {{city}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <div class="user-stats">
                                                                            <strong>Contacto</strong>
                                                                            <span>
                                                                                <p class="m-0">
                                                                                    <span class="mr-2 inline-block ">
                                                                                        <i class="fas fa-mobile-alt mr-2"></i>
                                                                                        <span class="">{{phone}}</span>
                                                                                    </span>
                                                                                </p>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <div class="user-stats">
                                                                            <strong >Actividad comercial</strong>
                                                                            <span class="text-center">
                                                                                <p-tag *ngFor="let item of activityCommercial" styleClass="m-2" severity="success" [value]="item"></p-tag>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <!-- <div class="col-12" *ngIf="this.country === 'PE'">
                                                                        <div class="user-stats">
                                                                            <strong>Condición Tributaria</strong>
                                                                            <span class="text-center">
                                                                                <p-tag *ngFor="let item of taxConditions" styleClass="m-2" severity="info" [value]="item.language_name"></p-tag>
                                                                            </span>
                                                                        </div>
                                                                    </div> -->
                                                                    <div class="col-6">
                                                                        <div class="user-stats">
                                                                            <strong >Tipo de empresa</strong>
                                                                            <span>{{typeCompany}}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6">
                                                                        <div class="user-stats">
                                                                            <strong>Sector comercial</strong>
                                                                            <span>{{sectorComercial}}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <div class="user-stats">
                                                                            <div class="social">
                                                                                <a *ngIf="web.length > 0" target="_blank" [href]="web" class="mr-4 ">
                                                                                    <span class="fa fa-globe text-lg"></span>
                                                                                </a>
                                                                                <a *ngIf="linkedin.length > 0" target="_blank" [href]="linkedin" class="mr-4">
                                                                                    <span class="fab fa-linkedin text-lg"></span>
                                                                                </a>
                                                                                <a *ngIf="facebook.length > 0" target="_blank" [href]="facebook" class="">
                                                                                    <span class="fab fa-facebook text-lg"></span>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12" *ngIf="brochure.length > 0 || presentationLetter.length > 0">
                                                                        <div class="grid" style="border-top: 1px solid; font-size: 11px;">
                                                                            <div [ngClass]="{'border-set': brochure.length > 0 && presentationLetter.length > 0}"
                                                                                *ngIf="brochure.length > 0"
                                                                                class="col-6 flex align-items-center justify-content-center cursor-pointer"
                                                                                style="margin-top: 13px;"
                                                                                (click)="opFilesBrochure.toggle($event)">
                                                                                <span class="uppercase"
                                                                                    translate>brochure</span>
                                                                                <span class="ml-2 pi pi-download"></span>
                                                                            </div>
                                                                            <div *ngIf="presentationLetter.length > 0"
                                                                                class="col-6 flex align-items-center justify-content-center cursor-pointer"
                                                                                style="margin-top: 13px;"
                                                                                (click)="opFilesPresentationLetter.toggle($event)">
                                                                                <span class="uppercase">presentation</span>
                                                                                <span class="ml-2 pi pi-download"></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="grid m-0">
                                            <div class="grid m-0">
                                                <div class="col-4 flex align-items-center justify-content-center py-0">
                                                    <div>
                                                        <p class=" font-bold">INFORMACION</p>
                                                        <div class="c100 {{informationColor}} p{{informationNum}} small backgroud-graphic">
                                                            <span>{{informationScore | number:'1.2-2'}}</span>
                                                            <div class="slice">
                                                                <div class="bar" style="width: 100%;height: 100%;"></div>
                                                                <div class="fill" style="width: 100%;height: 100%;"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-4 flex align-items-center justify-content-center py-0">
                    
                                                    <div>
                                                        <p class=" font-bold">REPUTACION</p>
                                                        <div class="c100 {{reputationColor}} p{{reputationNum}} small backgroud-graphic">
                                                            <span>{{reputationScore | number:'1.2-2'}}</span>
                                                            <div class="slice">
                                                                <div class="bar" style="width: 100%;height: 100%;"></div>
                                                                <div class="fill" style="width: 100%;height: 100%;"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-4 flex align-items-center justify-content-center py-0">
                                                    <div>
                                                        <p class="font-bold">CONFIANZA</p>
                                                        <div class="c100 {{trustColor}} p{{trustNum}} small backgroud-graphic">
                                                            <span>{{trustScore | number:'1.2-2'}}</span>
                                                            <div class="slice">
                                                                <div class="bar" style="width: 100%;height: 100%;"></div>
                                                                <div class="fill" style="width: 100%;height: 100%;"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <h3 class="m-0">Resumen de la empresa</h3>
                                    </div>
                                    <div class="col-12">
                                        <p class="m-0" *ngIf="summary.length === 0">Sin datos disponibles</p>
                                        <p class="m-0" *ngIf="summary.length > 0">{{summary}}</p>
                                    </div>
                                    <div class="col-12">
                                        <h3 class="m-0">Información contable</h3>
                                    </div>
                                    <div class="col-12">
                                        <p class="m-0" *ngIf="accountingInformation.length === 0">Sin datos disponibles
                                        </p>
                                        <div *ngFor="let item of accountingInformation; let i = index;" class="card shadow-5 m-0 flex align-items-center mb-3">
                                            <div class="grid m-0 w-full">
                                                <div class="col-12 md:col-11">
                                                    <div class="grid">
                                                        <div class="col-12 p-0">
                                                            <div class="grid m-0">
                                                                <div class="col-12 md:col-4 flex align-items-center ">
                                                                    <p class="m-0"><i pTooltip="{{ 'accounting_information_period' | translate }}" style="font-size: 18px;" class="cursor-pointer fas fa-calendar-check mr-3"></i>{{item.accountingYearRegistry}} </p>
                                                                </div>
                                                                <div class="col-12 md:col-5 flex align-items-center">
                                                                    <p class="m-0"><i pTooltip="{{ 'inventories_stocks' | translate }}"  style="font-size: 18px;" class="cursor-pointer fas fa-boxes mr-3"></i>USD {{item.accountingStockInventoryUSD | number:'1.2-2'}}
                                                                    </p>
                                                                </div>
                                                                <div class="col-12 md:col-3">
                                                                    <p class="m-0 flex align-items-center"><i pTooltip="{{ 'balance_sheet' | translate }}" style="font-size: 18px;"
                                                                            class="cursor-pointer fas fa-file-invoice-dollar mr-3"></i> <button (click)="opFilesAb.toggle($event)" [disabled]="item.accountingBalanceSheet.length === 0" pButton pRipple type="button"
                                                                            label="{{item.accountingBalanceSheet.length}} - {{ 'BALANCE' | translate}}"
                                                                            class="p-button-rounded p-button-secondary w-button p-button-outlined "></button></p>
                                                                            <p-overlayPanel #opFilesAb>
                                                                                <ng-template pTemplate>
                                                                                   <app-list-files [files]="item.accountingBalanceSheet"  (update)="item.accountingBalanceSheet = $event"></app-list-files>
                                                                                </ng-template>
                                                                            </p-overlayPanel>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 p-0">
                                                            <div class="grid m-0">
                                                                <div class="col-12 md:col-4 flex align-items-center ">
                                                                    <p class="m-0"><i pTooltip="{{ 'share_capital' | translate }}" style="font-size: 18px;" class="cursor-pointer fas fa-university mr-3"></i>USD {{item.accountingCapitalStockUSD | number:'1.2-2'}} </p>
                                                                </div>
                                                                <div class="col-12 md:col-5 flex align-items-center">
                                                                    <p class="m-0"><i style="font-size: 18px;" pTooltip="{{ 'machinery_equipment' | translate }}" class="cursor-pointer fas fa-cog mr-3"></i>USD {{item.accountingAssetsUSD | number:'1.2-2'}} </p>
                                                                </div>
                                                                <div class="col-12 md:col-3 flex align-items-center">
                                                                    <p class="m-0 flex align-items-center"><i pTooltip="{{ 'profit_statement' | translate }}" style="font-size: 18px;"
                                                                            class="cursor-pointer fas fa-file-invoice-dollar mr-3"></i> <button (click)="opFilesAc.toggle($event)" [disabled]="item.accountingProfitLossStatement.length === 0" pButton pRipple type="button"
                                                                            label="{{item.accountingProfitLossStatement.length}} - {{ 'STATE' | translate}}"
                                                                            class="p-button-rounded p-button-secondary w-button p-button-outlined "></button></p>
                                                                            <p-overlayPanel #opFilesAc>
                                                                                <ng-template pTemplate>
                                                                                    <app-list-files [files]="item.accountingProfitLossStatement"  (update)="item.accountingProfitLossStatement = $event"></app-list-files>
                                                                                </ng-template>
                                                                            </p-overlayPanel>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div class="col-12">
                                        <h3 class="m-0">Infraestructura</h3>
                                    </div>
                                    <div class="col-12 relative" #mapContainer style="overflow: hidden;">
                                        <ng-container>
                                            <div class="list-button-float" *ngIf="!listMap">
                                                <div class="circle-off"></div>
                                                <button style="height:40px; width:40px;  background: black;" pButton
                                                    pRipple type="button" icon="fas fa-list-ul"
                                                    class="p-button-rounded p-button-secondary "
                                                    (click)="listMap = true"></button>
                                            </div>
                                            <div class="container-list-map animate__animated animate__slideInRight" *ngIf="listMap">
                                                <ng-container *ngIf="listInfraestructure.length === 0">
                                                    <div class="grid m-0 w-full h-full justify-content-end">
                                                        <div class="col-12 flex align-items-center flex-column justify-content-center"
                                                            style="width: 100%;">
                                                            <span style="color: white; font-size: 18px; text-align: center;" >Esta empresa no cuenta con
                                                                infraestructuras registradas</span>
                                                            <button pButton pRipple type="button" label="CERRAR"
                                                                class="p-button-rounded p-button-danger mt-5 w-button"
                                                                (click)="closeListMap()"></button>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="listInfraestructure.length > 0">
                                                    <div class="grid m-0">
                                                        <div class="col-12 text-right tex">
                                                            <div class="circle"></div>
                                                            <button
                                                                style="margin-top: 5px; margin-right: 5px; height:40px; width:40px;"
                                                                pButton pRipple type="button" icon="fas fa-times"
                                                                class="p-button-rounded p-button-danger"
                                                                (click)="closeListMap()">
                                                            </button>
                                                        </div>
                                                        <div class="col-12">
                                                            <p-scrollPanel
                                                                [style]="{width: '100%', height: '339px'}">
                                                                <ng-container *ngFor="let item of listInfraestructure">
                                                                    <app-list-properties [type]="'SUMMARY'"
                                                                        [item]="item"
                                                                        (location)="openLocation(item)">
                                                                    </app-list-properties>
                                                                </ng-container>
                                                            </p-scrollPanel>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </ng-container>
                                        <google-map height="465px" width="100%" [zoom]="zoom" [center]="center">
                                            <map-marker #somemarker="mapMarker" *ngFor="let item of listInfraestructure" [position]="item.position"
                                                (mapClick)="openInfo(somemarker, item)">
                                            </map-marker>
                                            <map-info-window>
                                                <ng-container *ngIf="temporalInfraestructure">
                                                    <div style="width: 150px;     flex-direction: column;"
                                                        class="flex align-items-center flex-wrap text-center ">
                                                        <p class="m-0 text-blue-500 text-center">
                                                            <strong class="uppercase">{{temporalInfraestructure.name}}</strong>
                                                        </p>
                                                        <p class="m-0  text-center"> {{temporalInfraestructure.nameType}} - {{temporalInfraestructure.area}}m2</p>
                                                        <p class="m-0 text-center">
                                                            <strong>{{temporalInfraestructure.address}}</strong>
                                                        </p>
                                                    </div>
                                                </ng-container>
                                            </map-info-window>
                                        </google-map>
                                    </div>
                                    <div class="col-12">
                                        <h3 class="m-0">Experiencia y clientes</h3>
                                    </div>
                                    <div class="col-12">
                                        <p class="m-0" *ngIf="listClient.length === 0">Sin datos disponibles</p>
                                        <div *ngFor="let item of listClient" class="card shadow-5 m-0 flex align-items-center mb-3" style="padding: 20px 12px;">
                                            <div class="grid m-0 w-full relative">
                                                <img class="mr-3 absolute block md:hidden" src="https://cbn-proveedores.s3.sa-east-1.amazonaws.com/cbn-images/countries/{{item.country | lowercase}}.png" alt="" srcset="" style="right: -10px;
                                                top: -10px;" > 
                                                <div class="col-12 flex align-items-center">
                                                    <h3 class="font-bold md:overflow-hidden md:text-overflow-ellipsis md:white-space-nowrap w-full m-0 text-center md:text-left"
                                                        style="color: var(--primary-color);">
                                                        <span pTooltip="{{item.businessName}}"
                                                            tooltipPosition="top">{{item.businessName}}</span>
                                                    </h3>
                                                    <div class="hidden md:flex align-items-center">
                                                        <img class="mr-3" src="https://cbn-proveedores.s3.sa-east-1.amazonaws.com/cbn-images/countries/{{item.country | lowercase}}.png" alt="" srcset="" >  
                                                    <button style="float: right;"
                                                            [disabled]="item.files.length === 0" pButton pRipple type="button" icon="fas fa-paperclip"
                                                            label="{{item.files.length}}" class="p-button-rounded p-button-secondary p-button-outlined"
                                                            (click)="opFiless.toggle($event)"></button>
                                                        <p-overlayPanel #opFiless>
                                                            <ng-template pTemplate>
                                                                <app-list-files [files]="item.files" type="EDIT" (update)="files = $event">
                                                                </app-list-files>
                                                            </ng-template>
                                                        </p-overlayPanel>
                                                    </div>
                                                </div>
                                                <div class="col-12 p-0">
                                                    <div class="grid m-0 w-full">
                                                        <div class="col-12 md:col-4 md:flex align-items-center text-center">
                                                            <p class="m-0" style="color: #689F38;">{{item.codeTributary}}</p>
                                                        </div>
                                                        <div class="col-12 md:col-4 md:flex align-items-center text-center">
                                                            <p class="m-0"><i style="font-size: 18px;" pTooltip="{{ 'average_sales_last_year' | translate }}" class="cursor-pointer fas fa-dollar-sign mr-1 md:mr-3"></i>
                                                                {{getRango(item.averageSalesLastYear)}} </p>
                                                        </div>
                                                        <div class="col-12 md:col-3 ">
                                                            <p class="m-0 md:flex align-items-center text-center"> <button style="width: 125.95px;" icon="fas fa-user-friends" pButton pRipple type="button"
                                                                    label="{{ 'CONTACT' | translate }}" (click)="opContact.toggle($event)"
                                                                    class="p-button-rounded p-button-secondary w-button p-button-outlined "
                                                                    [disabled]="item.contacts.length === 0"></button></p>
                                                            <p-overlayPanel #opContact>
                                                                <ng-template pTemplate>
                                                                    <p-table [value]="item.contacts" styleClass="table-list-contact" [rows]="2"
                                                                        [paginator]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr>
                                                                                <th><span style="width: 100%;"></span> </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-item let-i="rowIndex">
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-left" style="width: 100%;">
                                                                                        <div class="grid align-items-center shadow-5"
                                                                                            style="width: 300px; border-radius: 24px;">
                                                                                            <div class="col-3">
                                                                                                <p-avatar *ngIf="item.gender === 'M'"
                                                                                                    image="https://cbn-proveedores.s3-sa-east-1.amazonaws.com/cbn-images/gender/circle-cropped+(19).png"
                                                                                                    size="xlarge" shape="circle"></p-avatar>
                                                                                                <p-avatar *ngIf="item.gender === 'F'"
                                                                                                    image="https://icon-library.com/images/free-woman-icon/free-woman-icon-4.jpg"
                                                                                                    size="xlarge" shape="circle"></p-avatar>
                                                                                            </div>
                                                                                            <div class="col-9">
                                                                                                <p>{{item.name}}</p>
                                                                                                <p>{{item.email}}</p>
                                                                                                <p>
                                                                                                    <span class="mr-2 inline-block ">
                                                                                                        <i class="fas fa-phone mr-2"></i>
                                                                                                        <span>{{item.phone}}</span>
                                                                                                    </span>
                                                                                                    <span>
                                                                                                       - #{{item.extensionPhone}}
                                                                                                    </span>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                </ng-template>
                                                            </p-overlayPanel>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 p-0">
                                                    <div class="grid m-0">
                                                        <div class="col-12 md:col-4 md:flex align-items-center text-center ">
                                                            <p class="m-0" style="color: var(--bold-text);">{{item.sectorName}}</p>
                                                        </div>
                                                        <div class="col-12 md:col-4 md:flex align-items-center text-center">
                                                            <p class="m-0"><i style="font-size: 18px;"
                                                                pTooltip="{{ 'year_business_relationship' | translate }}"   class="cursor-pointer fas fa-history mr-1 md:mr-3"></i>{{item.YearCommercialRel}} </p>
                                                        </div>
                                                        <div class="col-12 md:col-3 md:flex align-items-center text-center">
                                                            <p class="m-0 md:flex align-items-center text-center"><button style="width: 125.95px;" icon="pi pi-info-circle" pButton pRipple type="button"
                                                                    label="{{ 'DETAIL' | translate }}" (click)="opDetail.toggle($event)"
                                                                    class="p-button-rounded p-button-secondary w-button  p-button-outlined "
                                                                    [disabled]="item.detail.length === 0"></button></p>
                                                            <p-overlayPanel #opDetail>
                                                                <ng-template pTemplate>
                                                                    <div class="grid align-items-center text-center" style="width: 300px;">
                                                                        <div class="col-12" style="height: auto; max-height: 200px; overflow-y: auto;">
                                                                            <div [innerHTML]="item.detail" >
                                
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </ng-template>
                                                            </p-overlayPanel>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <h3 class="m-0">Certificaciones</h3>
                                    </div>
                                    <div class="col-12">
                                        <p class="m-0"
                                            *ngIf="!certificateISO9001 && !certificateGMP && !certificateBCR && !certificateHACCP && !certificateBASC && !certificateISO14001 && !certificateISO18001">
                                            Sin datos disponibles</p>
                                        <marquee behavior="" direction=""
                                            *ngIf="certificateISO9001 && certificateGMP && certificateBCR && certificateHACCP && certificateBASC && certificateISO14001 && certificateISO18001">
                                            <div class="row service-items">
                                                <div class="col col-d-12 col-t-12 col-m-12 border-line-h" style="display: flex;">
                                                    <div *ngIf="certificateISO9001" class="col col-d-6 col-t-6 col-m-6" style="padding: 5px;">
                                                        <div style="display: flex; align-items: center; margin: 5px 0px;">
                                                            <img pTooltip="{{ 'Perfil.quialitymanagement.qualityManagement' | translate }}"
                                                                tooltipPosition="bottom" placeholder="Bottom" src="assets/certificacion/garantia.svg"
                                                                height="30">
                                                            <span style="margin-left: 10px;">{{'certificate' | translate }}
                                                                -
                                                                ISO 9001</span>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="certificateGMP" class="col col-d-6 col-t-6 col-m-6" style="padding: 5px;">
                                                        <div style="display: flex; align-items: center; margin: 5px 0px;">
                                                            <img pTooltip="{{ 'Perfil.quialitymanagement.GMP' | translate }}" tooltipPosition="bottom"
                                                                placeholder="Bottom" src="assets/certificacion/garantia (3).svg" height="30">
                                                            <span style="margin-left: 10px;">{{'certificate' | translate }}
                                                                -
                                                                GMP</span>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="certificateBCR" class="col col-d-6 col-t-6 col-m-6" style="padding: 5px;">
                                                        <div style="display: flex; align-items: center; margin: 5px 0px;">
                                                            <img pTooltip="{{ 'Perfil.quialitymanagement.BRC' | translate }}" tooltipPosition="bottom"
                                                                placeholder="Bottom" src="assets/certificacion/garantia (5).svg" height="30">
                                                            <span style="margin-left: 10px;">{{'certificate' | translate }}
                                                                -
                                                                BCR</span>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="certificateHACCP" class="col col-d-6 col-t-6 col-m-6" style="padding: 5px;">
                                                        <div style="display: flex; align-items: center; margin: 5px 0px;">
                                                            <img pTooltip="{{ 'Perfil.quialitymanagement.HACCP' | translate }}" tooltipPosition="bottom"
                                                                placeholder="Bottom" src="assets/certificacion/garantia (4).svg" height="30">
                                                            <span style="margin-left: 10px;">{{'certificate' | translate }}
                                                                -
                                                                HACCP</span>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="certificateBASC" class="col col-d-6 col-t-6 col-m-6" style="padding: 5px;">
                                                        <div style="display: flex; align-items: center; margin: 5px 0px;">
                                                            <img pTooltip="{{ 'Perfil.quialitymanagement.BASC' | translate }}" tooltipPosition="bottom"
                                                                placeholder="Bottom" src="assets/certificacion/garantia (7).svg" height="30">
                                                            <span style="margin-left: 10px;">{{'certificate' | translate }}
                                                                -
                                                                BASC</span>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="certificateISO14001" class="col col-d-6 col-t-6 col-m-6" style="padding: 5px;">
                                                        <div style="display: flex; align-items: center; margin: 5px 0px;">
                                                            <img pTooltip="{{ 'Perfil.quialitymanagement.environmentalProtection' | translate }}"
                                                                tooltipPosition="bottom" placeholder="Bottom"
                                                                src="assets/certificacion/garantia (1).svg" height="30">
                                                            <span style="margin-left: 10px;">{{'certificate' | translate }}
                                                                -
                                                                Calidad ISO 14001</span>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="certificateISO18001" class="col col-d-6 col-t-6 col-m-6" style="padding: 5px;">
                                                        <div style="display: flex; align-items: center; margin: 5px 0px;">
                                                            <img pTooltip="{{ 'Perfil.quialitymanagement.securityHealth' | translate }}"
                                                                tooltipPosition="bottom" placeholder="Bottom"
                                                                src="assets/certificacion/garantia (2).svg" height="30">
                                                            <span style="margin-left: 10px;">{{'certificate' | translate }}
                                                                18001 / ISO 45001</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </marquee>
                                    </div>
                                    <div class="col-12">
                                        <h3 class="m-0">Productos y servicios</h3>
                                    </div>
                                    <div class="col-12">
                                        <p class="m-0" *ngIf="catalogueList.length === 0">
                                            Sin datos disponibles</p>
                                            <div class="grid-system">
                                                <div class="item" *ngFor="let list of catalogueList">
                                                    <div class="relative card-catalogue shadow-5">
                                                        <swiper [navigation]="false" class="mySwiper" [autoplay]="{ delay: 2500, disableOnInteraction: false}">
                                                          <ng-container *ngIf="list.files.length > 0">
                                                            <ng-template swiperSlide *ngFor="let item of list.files">
                                                              <img *ngIf="item.type === 'PHOTO'" [src]="item.url" alt="" srcset="">
                                                              <img *ngIf="item.type === 'VIDEO'" src="https://provcatalogue.s3.us-east-2.amazonaws.com/no_image_camera.jpg"
                                                                alt="" srcset="">
                                                            </ng-template>
                                                          </ng-container>
                                                          <ng-container *ngIf="list.files.length === 0">
                                                            <ng-template swiperSlide>
                                                              <img src="https://provcatalogue.s3.us-east-2.amazonaws.com/no_image_camera.jpg" alt="" srcset="">
                                                            </ng-template>
                                                          </ng-container>
                                                        </swiper>
                                                    
                                                        <div class="title-catalogue">
                                                          
                                                        </div>
                                                        <div class="tex-catalogue">
                                                          <h3>{{list.catalogueName}}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                    <div class="col-12">
                                        <h3 class="m-0">Riesgo crediticio
                                        </h3>
                                    </div>
                                    <div class="col-12">
                                        <p class="m-0" *ngIf="financialReference.length === 0">Sin datos disponibles</p>
                                        <ng-container *ngFor="let item of financialReference; let i = index">
                                            <div class="card shadow-5 p-3 flex align-items-center flex-wrap m-0 m-2 mb-0">
                                                <div class="grid w-full align-items-center m-0">
                                                    <div class="w-full md:flex align-items-center justify-content-between">
                                                      <h3 class="w-full ml-2" style="color: var(--primary-color);">{{item.financialEntity}}</h3>  
                                                    </div>
                                                    <div class="col-12 md:col-5"><i style="font-size: 18px;" pTooltip="{{ 'credit_level_obtained' | translate }}" class="cursor-pointer fas fa-star-half-alt" aria-hidden="true"></i>&nbsp;&nbsp; <span>{{item.financialReferenceCreditRating | number:'1.2-2'}}</span></div>
                                                    <div class="col-12 md:col-5"><i  pTooltip="{{ 'report_date' | translate }}" style="font-size: 18px;" class="cursor-pointer fas fa-calendar-check" aria-hidden="true"></i>&nbsp;&nbsp; <span>{{(item.financialReferenceReportDate * 1000) | date:"dd/MM/yyyy"}}</span></div>
                                                    
                                                    <div class="col-12 md:col-2 text-center md:text-right">
                                                        <button style="margin-right: 0.35rem;" [disabled]="item.financialReferenceReportFile.length === 0" pButton pRipple type="button" icon="pi pi-paperclip" label="{{item.financialReferenceReportFile.length}}" 
                                                        class="p-button-rounded p-button-secondary p-button-outlined mobile " (click)="files = item.financialReferenceReportFile;opFiles.toggle($event)"></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="col-12">
                                        <h3 class="m-0">Representantes disponibles
                                        </h3>
                                    </div>
                                    <div class="col-12">
                                        <div class="grid m-0 relative w-full">
                                            <div class="col-12">
                                                <p class="m-0" *ngIf="executives.length === 0">Sin datos disponibles</p>
                                                <p-table [value]="executives" responsiveLayout="scroll" [paginator]="true" [rows]="5" >
                                                    <ng-template pTemplate="header">
                                                        <tr class="text-center">
                                                            <th style="min-width: 60px;" ></th>
                                                            <th style="min-width: 100px;" class="font-bold" >Ejecutivo</th>
                                                            <th style="min-width: 100px;" class="font-bold" >Cargo</th>
                                                            <th style="min-width: 100px;" class="font-bold" >Fecha de actualización</th>
                                                            <th style="min-width: 120px;" ></th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-data>
                                                        <tr class="text-center">
                                                            <td style="width: 60px;">
                                                                <p-avatar [image]="data.avatar" shape="circle" size="large" [style]="{'height':'25px', 'width': '25px'}"></p-avatar>
                                                            </td>
                                                            <td style="min-width: 100px;">{{data.name}} {{data.lastName}}</td>
                                                            <td style="min-width: 100px;">
                                                                <ng-container *ngIf="data.typeExecutive === 'warehouseResponsable'">RESPONSABLE DE ALMACÉN</ng-container>
                                                                <ng-container *ngIf="data.typeExecutive === 'logisticsManager'"> JEFE DE LOGÍSTICA / COMPRAS</ng-container>
                                                                <ng-container *ngIf="data.typeExecutive === 'generalAccountant'">RESPONSABLE CONTABLE</ng-container>
                                                                <ng-container *ngIf="data.typeExecutive === 'chiefSalesOfficer'">GERENTE VENTAS</ng-container>
                                                                <ng-container *ngIf="data.typeExecutive === 'chiefOperationsOfficer'">GERENTE DE OPERACIONES</ng-container>
                                                                <ng-container *ngIf="data.typeExecutive === 'chiefLogisticsOfficer'">GERENTE DE LOGÍSTICA/COMPRAS</ng-container>
                                                                <ng-container *ngIf="data.typeExecutive === 'operationsManager'">JEFE DE OPERACIONES</ng-container>
                                                                <ng-container *ngIf="data.typeExecutive === 'chiefExecutiveProject'">JEFE DE PROYECTOS</ng-container>
                                                                <ng-container *ngIf="data.typeExecutive === 'salesManager'">JEFE DE VENTAS</ng-container>
                                                                <ng-container *ngIf="data.typeExecutive === 'paymentsResponsable'">RESPONSABLE DE PAGOS</ng-container>
                                                                <ng-container *ngIf="data.typeExecutive === 'projectManager'">GERENTE DE PROYECTOS</ng-container>
                                                                <ng-container *ngIf="data.typeExecutive === 'salesRepresentative'">REPRESENTATE DE VENTA</ng-container>
                                                                <ng-container *ngIf="data.typeExecutive === 'chiefExecutiveOfficer'">GERENTE GENERAL</ng-container>
                                                                <ng-container *ngIf="data.typeExecutive === 'chiefadministrationOfficer'">GERENTE ADMINISTRATIVO</ng-container>
                                                                <ng-container *ngIf="data.typeExecutive === 'treasuryManager'">RESPONSABLE DE TESORERÍA</ng-container>
                                                            </td>
                                                            <td style="min-width: 100px;">{{(data.updateDate * 1000) | date:'short'}}</td>
                                                            <td style="width: 120px; font-size: 18px;">
                                                                <a *ngIf="data.linkedin.length > 0" [href]="data.linkedin" style="color: rgb(81, 184, 224);text-decoration: none;" class="mr-2"><i class="fab fa-linkedin"></i></a>
                                                                <span pTooltip="{{data.email}}" style="margin-left: 5px;" class="mr-2"  ngxClipboard cbContent="{{data.email}}" (click)="openCopyEmail()"><i class="fas fa-envelope cursor-pointer"></i></span>
                                                                <span pTooltip="{{data.cellPhonePrefix}} {{data.cellPhone}}" style="margin-left: 5px;"  ngxClipboard cbContent="{{data.cellPhonePrefix}} {{data.cellPhone}}" (click)="openCopyPhone()"><i class="fas fa-mobile cursor-pointer"></i></span>
                                                            </td>
                                                        </tr>
                                                    </ng-template>
                                                </p-table>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-12 p-0">
                                        <h3 style="margin: 5px 0px;">CLASIFICACIÓN (Matriz de kraljic)</h3>
                                    </div>
                                    <div class="col-12 p-0">
                                        <div class="grid m-0 justify-content-center">
                                            <div class="col-12 box-principal">
                                                <div class="grid m-0">
                                                    <span class="float-span-kraljic-princ">IMPACTO <br> FINANCIERO</span>
                                                    <span class="float-span-kraljic-botom">COMPLEJIDAD DEL SUMINISTRO</span>
                                                    <div class="col-12" style="padding-bottom: 0;">
                                                        <div class="grid m-0">
                                                            <span class="float-span-kraljic-top">ALTO</span>
                                                            <div class="col-6">
                                                                <div class="grid m-0">
                                                                    <div class="col-12 box-kraljic">
                                                                        APALANCADO
                                                                    </div>
                                                                </div>
                
                                                            </div>
                                                            <div class="col-6">
                                                                <div class="grid m-0">
                                                                    <div class="col-12 box-kraljic">
                                                                        ESTRATEGICO
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12" style="padding-top: 0;">
                                                        <div class="grid m-0">
                                                            <span class="float-span-kraljic-bottom">BAJA</span>
                                                            <div class="col-6">
                                                                <div class="grid m-0">
                                                                    <span class="float-span-kraljic-botom-l">BAJA</span>
                                                                    <div class="col-12 box-kraljic">
                                                                        NO CRITICO
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-6">
                                                                <div class="grid m-0">
                                                                    <span class="float-span-kraljic-botom-r">ALTA</span>
                                                                    <div class="col-12 box-kraljic">
                                                                        CUELLOS DE <br> BOTELLA
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="col-12 flex-center-v">
                                        <h3 style="margin: 5px 0px;" translate>SHARED_INFORMATION</h3>
                                        <div>
                                            <span class="flex align-items-center mb-2">
                                                <div class="mr-2" style="height: 25px;">
                                                    <app-animated-check></app-animated-check>
                                                </div>
                                                <span translate>FILLED</span>
                                            </span>
                                            <span class="flex align-items-center mb-2">
                                                <i class="fas fa-minus mr-2" style="width: 25px; text-align: center;"></i>
                                                <span translate>PARTIAL</span>
                                            </span>
                                            <span class="flex align-items-center mb-2">
                                                <i class="pi pi-times mr-2" style="width: 25px; text-align: center;"></i>
                                                <span translate>N_O</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-12" style="font-weight: bold;">
                                        <div class="grid m-0">
                                            <div class="col-12 detail-title">
                                                <span class="border-blue flex align-items-center justify-content-between" style="width: 100%; padding: 5px 0px;"> 
                                                    <span translate>BASIC_INFORMATION_U</span>
                                                    <span>{{ (10.5 + quantityLineBusiness + quantityExecutive + quantityAccountBank) | number:'1.2-2'}}/35</span>
                                                </span>
                                            </div>
                                            <div class="col-12 p-0">
                                                <div class="col-12 p-0">
                                                    <div class="detail-subtitle" translate>
                                                        GENERAL_DATA
                                                    </div>
                                                    <div class="detail-counter-icon">
                                                        <span>10.5/10.50</span>
                                                        <span
                                                            style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                                            <div class="mr-2" style="height: 25px;">
                                                                <app-animated-check></app-animated-check>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-12 p-0">
                                                    <div class="detail-subtitle" translate>
                                                        BUSINESS_LINES
                                                    </div>
                                                    <div class="detail-counter-icon">
                                                        <span>{{quantityLineBusiness}}/10.50</span>
                                                        <span *ngIf="quantityLineBusiness === 10.5"
                                                            style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                                            <div class="mr-2" style="height: 25px;">
                                                                <app-animated-check></app-animated-check>
                                                            </div>
                                                        </span>
                                                        <span *ngIf="quantityLineBusiness < 10.5"
                                                            style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                                            <i class="fas fa-minus"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 p-0">
                                                <div class="col-12 p-0">
                                                    <div class="detail-subtitle" translate>
                                                        COMPANY_EXECUTIVES
                                                    </div>
                                                    <div class="detail-counter-icon">
                                                        <span>{{quantityExecutive}}/8.75</span>
                                                        <span *ngIf="quantityExecutive === 8.75"
                                                            style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                                            <div class="mr-2" style="height: 25px;">
                                                                <app-animated-check></app-animated-check>
                                                            </div>
                                                        </span>
                                                        <span *ngIf="quantityExecutive < 8.75"
                                                            style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                                            <i class="fas fa-minus"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="col-12 p-0">
                                                    <div class="detail-subtitle" translate>
                                                        BANK_ACCOUNTS
                                                    </div>
                                                    <div class="detail-counter-icon">
                                                        <span>{{quantityAccountBank}}/5.25</span>
                                                        <span *ngIf="quantityAccountBank === 5.25"
                                                            style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                                            <div class="mr-2" style="height: 25px;">
                                                                <app-animated-check></app-animated-check>
                                                            </div>
                                                        </span>
                                                        <span *ngIf="quantityAccountBank < 5.25"
                                                            style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                                            <i class="fas fa-minus"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12" style="font-weight: bold;">
                                        <div class="grid m-0">
                                            <div class="col-12 detail-title">
                                                <span class="border-yellow flex align-items-center justify-content-between" style="width: 100%; padding: 5px 0px;"> 
                                                    <span translate class="uppercase">SIDEBAR.COMMERCIAL_SOLIDITY</span>
                                                    <span>{{ (quantityLineProduct + quantityExperienceClients + quantityInfraestructure + quantityConditionTerms) | number:'1.2-2'}}/60</span>
                                                </span>
                                            </div>
                                            <div class="col-12 p-0">
                                                <div class="grid m-0">
                                                    <div class="col-12 p-0">
                                                        <div class="detail-subtitle" translate>
                                                            PRODUCT_SERVICE_LINES
                                                        </div>
                                                        <div class="detail-counter-icon">
                                                            <span>{{quantityLineProduct}}/21</span>
                                                            <span *ngIf="quantityLineProduct === 21"
                                                                style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                                                <div class="mr-2" style="height: 25px;">
                                                                    <app-animated-check></app-animated-check>
                                                                </div>
                                                            </span>
                                                            <span *ngIf="quantityLineProduct < 21"
                                                                style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                                                <i class="fas fa-minus"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 p-0">
                                                        <div class="detail-subtitle uppercase">
                                                            {{ 'SIDEBAR.EXPERIENCE_CLIENTS' | translate}}
                                                        </div>
                                                        <div class="detail-counter-icon">
                                                            <span>{{quantityExperienceClients}}/21</span>
                                                            <span *ngIf="quantityExperienceClients === 21"
                                                                style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                                                <div class="mr-2" style="height: 25px;">
                                                                    <app-animated-check></app-animated-check>
                                                                </div>
                                                            </span>
                                                            <span *ngIf="quantityExperienceClients < 21"
                                                                style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                                                <i class="fas fa-minus"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                
                                            </div>
                                            <div class="col-12 p-0">
                                                <div class="grid m-0">
                                                    <div class="col-12 p-0">
                                                        <div class="detail-subtitle" translate>
                                                            INFRASTRUCTURE
                                                        </div>
                                                        <div class="detail-counter-icon">
                                                            <span>{{quantityInfraestructure}}/15</span>
                                                            <span *ngIf="quantityInfraestructure === 15"
                                                                style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                                                <div class="mr-2" style="height: 25px;">
                                                                    <app-animated-check></app-animated-check>
                                                                </div>
                                                            </span>
                                                            <span *ngIf="quantityInfraestructure < 15"
                                                                style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                                                <i class="fas fa-minus"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 p-0">
                                                        <div class="detail-subtitle" translate>
                                                            COMMERCIAL_TERMS
                                                        </div>
                                                        <div class="detail-counter-icon">
                                                            <span>{{quantityConditionTerms}}/3</span>
                                                            <span *ngIf="quantityConditionTerms === 3"
                                                                style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                                                <div class="mr-2" style="height: 25px;">
                                                                    <app-animated-check></app-animated-check>
                                                                </div>
                                                            </span>
                                                            <span *ngIf="quantityConditionTerms < 3"
                                                                style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                                                <i class="fas fa-minus"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                
                                    </div>
                                    <div class="col-12" style="font-weight: bold;">
                                        <div class="grid m-0">
                                            <div class="col-12 detail-title">
                                                <span class="border-green flex align-items-center justify-content-between" style="width: 100%; padding: 5px 0px;"> 
                                                    <span translate class="uppercase">SIDEBAR.BUSINESS_SOLIDITY</span>
                                                    <span>{{quantityQualityManagement | number:'1.2-2'}}/5</span>
                                                </span>
                                            </div>
                                            <div class="col-12 p-0">
                                                <div class="grid m-0">
                                                    <div class="col-12 p-0">
                                                        <div class="detail-subtitle" translate>
                                                            QUALITY_MANAGEMENT
                                                        </div>
                                                        <div class="detail-counter-icon">
                                                            <span>{{quantityQualityManagement}}/5</span>
                                                            <span *ngIf="quantityQualityManagement === 5"
                                                                style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                                                <div class="mr-2" style="height: 25px;">
                                                                    <app-animated-check></app-animated-check>
                                                                </div>
                                                            </span>
                                                            <span *ngIf="quantityQualityManagement < 5"
                                                                style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                                                <i class="fas fa-minus"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 p-0">
                                                        <div class="detail-subtitle uppercase" translate>
                                                            SIDEBAR.SAFETY
                                                        </div>
                                                        <div class="detail-counter-icon">
                                                            <span></span>
                                                            <span
                                                                style="width: 40px;height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 4px;">
                                                                <i class="fas fa-minus"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 p-0">
                                                        <div class="detail-subtitle" translate>
                                                            FINANCIAL_REFERENCES
                                                        </div>
                                                        <div class="detail-counter-icon">
                                                            <span></span>
                                                            <span
                                                                style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                                                <div class="mr-2" style="height: 25px;">
                                                                    <app-animated-check></app-animated-check>
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 p-0">
                                                <div class="grid m-0">
                                                    <div class="col-12 p-0">
                                                        <div class="detail-subtitle" translate>
                                                            ENVIRONMENTAL_AND_SOCIAL_RESPONSIBILITY_MANAGEMENT
                                                        </div>
                                                        <div class="detail-counter-icon">
                                                            <span></span>
                                                            <span
                                                                style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                                                <div class="mr-2" style="height: 25px;">
                                                                    <app-animated-check></app-animated-check>
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 p-0">
                                                        <div class="detail-subtitle" translate>
                                                            ACCOUNTING_INFORMATION
                                                        </div>
                                                        <div class="detail-counter-icon">
                                                            <span></span>
                                                            <span
                                                                style="height: 40px;display: inline-flex;overflow: hidden;align-items: center;justify-content: center;margin-left: 10px;">
                                                                <div class="mr-2" style="height: 25px;">
                                                                    <app-animated-check></app-animated-check>
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </p-scrollPanel>
                        </div>
                    </div>
            </div>
            <div class="col-12 px-0 pb-0 text-right">
                
                        <button pButton pRipple type="button" label="{{'closeU' | translate}}" [loading]="loading"
                            class=" p-button-rounded w-button p-button-danger" (click)="closeModal()"></button>
                 
            </div>
        </ng-container>
    </div>
</p-sidebar>

<p-overlayPanel #opFiles>
    <ng-template pTemplate>
        <p-scrollPanel [style]="{width: '16rem', height: '162px'}">
            <app-list-files [files]="files">
            </app-list-files>
        </p-scrollPanel>
    </ng-template>
</p-overlayPanel>


<p-overlayPanel #opFilesBrochure>
    <ng-template pTemplate>
        <p-scrollPanel [style]="{width: '16rem', height: '162px'}">
            <app-list-files [files]="brochure">
            </app-list-files>
        </p-scrollPanel>
    </ng-template>
</p-overlayPanel>

<p-overlayPanel #opFilesPresentationLetter>
    <ng-template pTemplate>
        <p-scrollPanel [style]="{width: '16rem', height: '162px'}">
            <app-list-files [files]="presentationLetter">
            </app-list-files>
        </p-scrollPanel>
    </ng-template>
</p-overlayPanel>