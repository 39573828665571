import { Component, OnInit , EventEmitter, Input , Output } from '@angular/core';

@Component({
  selector: 'app-list-properties',
  templateUrl: './list-properties.component.html',
  styleUrls: ['./list-properties.component.scss']
})
export class ListPropertiesComponent implements OnInit {
  @Input() item = null;
  @Input() type = '';
  @Output() deleted = new EventEmitter<Object>();
  @Output() edit = new EventEmitter<Object>();
  @Output() location = new EventEmitter<Object>();
  constructor() { }

  ngOnInit(): void {
  }
  deletedItem(){
    this.deleted.emit(this.item)
  }
  editItem(){
    this.edit.emit(this.item)
  }
  setLocation(){
    this.location.emit(this.item)
  }
} 
