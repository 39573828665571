import { Component, Input, OnInit ,ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { GlobalService } from 'src/app/services/global.service';
import { hubService } from 'src/app/services/hub.service';
import { CompanyService } from 'src/app/services/company.service';
import { MapInfoWindow, MapMarker } from '@angular/google-maps'
@Component({
  selector: 'app-info-supplier',
  templateUrl: './info-supplier.component.html',
  styleUrls: ['./info-supplier.component.scss'],
})
export class InfoSupplierComponent implements OnInit {


  @Input() countries = [];
  @Input() categories = [];
  @Input() subgroup = [];
  @Input() group = [];
  @Input() segment = [];
  @Input() sectionCommercials = [];
  @Input() activityCommercials = [];
  @Input() values = [];
  @Input() idCompany = '';
  @Output() close = new EventEmitter<boolean>();
  companyTypes = [];
  displayInfoSupplier = true;
  loading = false;

  //dashboard levels
  reputationScore: number;
  reputationColor: any;
  reputationNum: any;

  trustScore: number;
  trustColor: any;
  trustNum: any;

  informationScore: number;
  informationColor: any;
  informationNum: any;
  //card left
  tradeName = "";
  logo = "";
  country = "";
  homologation = "";
  businessName = "";
  years = "";
  codeTributary = "";
  files = [];
  address = "";
  countryName = "";
  state = "";
  city = "";
  phone = "";
  activityCommercial = [];
  sectorComercial = "";
  typeCompany = "";
  web = "";
  linkedin = "";
  facebook = "";
  //card resumen
  tabSidebar_info = 'resumen';
  summary = "";
  mission = "";
  vission = "";
  valuesCBN = [];
  lineBusiness = [];
  listClient = [];
  certificateISO9001 = false;
  certificateGMP = false;
  certificateBCR = false;
  certificateHACCP = false;
  certificateBASC = false;
  certificateISO14001 = false;
  certificateISO18001 = false;
  accountingInformation = [];
  financialReference = [];
  //card contactos

  executives = [];
  // card estado
  // clasification = "";
  // status = "";

  quantityLineBusiness = 0;
  quantityExecutive = 0;
  quantityAccountBank = 0;

  quantityLineProduct = 0;
  quantityExperienceClients = 0;
  quantityInfraestructure = 0;
  quantityConditionTerms = 0;
  quantityQualityManagement = 0;
  quantityEnviromental = 0;
  quantitySecurity = 0;
  quantityAccountingInformation = 0;
  quantityFinancialReference = 0;

  list_activity_proccess = [];
  loadComplete = true;
  countryLogo = '';
  brochure = [];
  presentationLetter = []
  listInfraestructure = [];
  typesInfraestructure = [];

  centerMap: google.maps.LatLngLiteral;
  zoomMap = 12;
  positionMarker: google.maps.LatLngLiteral;
  options = {
    draggable: true
  };
  @ViewChild('search', { static: true }) public searchElementRef: ElementRef;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
  //MAP INFRAESTRUCTURA
  center: google.maps.LatLngLiteral;
  zoom = 12;
  loadInfra = true;
  listMap = false;
  temporalInfraestructure = null;

  catalogueList = [];
  constructor(
    private globalService: GlobalService,
    private companyService:CompanyService,
    private hub:hubService
  ) {
  }

  ngOnInit(): void {
    this.getCompanyData();
    this.companyTypes = [
      {
        value: 'BOTH',
        label: `${this.globalService.lang === 'es' ? 'AMBOS' : 'BOTH'}`,
      },
      {
        value: 'GOOD',
        label: `${
          this.globalService.lang === 'es' ? 'BIENES Y EQUIPOS' : 'GOODS AND EQUIPMENT' }`,
      },
      {
        value: 'SERVICE',
        label: `${this.globalService.lang === 'es' ? 'SERVICIOS' : 'SERVICES'}`,
      },
    ];
    //centrar mapa
    navigator.geolocation.getCurrentPosition((position) => {
      this.centerMap = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
    });
  }
 
  
  resetListInfraestructure(data){
    this.listInfraestructure = data.map(c => {
      c['position'] = { lat: c['lat'], lng: c['lng'] };
      c['nameType'] = this.typesInfraestructure.find(a => a['value'] === c['type']) ? this.typesInfraestructure.find(a => a['value'] === c['type'])['label'] : '';
      return c;
    });
    console.log(this.listInfraestructure)
  }

  openListMap(){
    this.listMap = true;
  }
  closeListMap(){
    if (this.listMap = true) {
       document.querySelector('.container-list-map').classList.remove('animate__animated' , 'animate__slideInRight');
       document.querySelector('.container-list-map').classList.add('animate__animated', 'animate__slideOutRight');
       setTimeout(() => {
          this.listMap = false;
       }, 1500);
      
    }
  }

  openLocation(item){
    this.centerMap = {
      lat: item.lat,
      lng: item.lng,
    };
    this.center = {
      lat: item.lat,
      lng: item.lng,
    };
    this.closeListMap();
  }

  openInfo(marker: MapMarker, content) {
    this.temporalInfraestructure = content;
    this.infoWindow.open(marker)
  }
  
  

  async getCompanyData(){
    this.loading = true;
    
    let language = `${this.globalService.lang.charAt(0).toUpperCase()}` +  `${this.globalService.lang.slice(1)}`;
    let dependencyInfra = await firstValueFrom(this.hub.getDependencyInfraestructure({}));
    this.typesInfraestructure = dependencyInfra['data']['typeInfrastructure']['Items'].map(c => { return { label: c['name'+language], value: c['code'], image: c['image'] } });
    let result = await firstValueFrom(this.companyService.listResumProvidersCompany({ idCompany: this.idCompany }));

    console.log(result)
    if(result['status'] === false){
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: result['message'], type : 'TOAST'});
    }else if(result['status'] === true){
      console.log(result['data'])
      this.loadInfra = false;
      this.resetListInfraestructure(result['data']['infraestructures']);
      this.catalogueList = result['data']['catalogue']
      this.brochure =  result['data']['profile']['brochure']
      this.presentationLetter = result['data']['profile']['presentationLetter']
      this.countryLogo = result['data']['profile']['country']
      this.tradeName = result['data']['profile']['tradeName'];
      this.logo = result['data']['profile']['logo'];
      this.country = result['data']['profile']['country'];
      this.homologation = result['data']['profile']['homologation'];
      this.businessName = result['data']['profile']['businessName'];
      this.years = `${new Date().getFullYear() - new Date(result['data']['profile']['startCommercialActivity'] * 1000).getFullYear()} años(s)` ;
      this.codeTributary = result['data']['profile']['codeTributary'];
      this.files = result['data']['profile']['files'];
      this.address = result['data']['profile']['direction'];
      this.countryName = this.countries.find(c => c['value'] === result['data']['profile']['country']) ? this.countries.find(c => c['value'] === result['data']['profile']['country'])['label'] : '';
      this.state = result['data']['profile']['state'];
      this.city = result['data']['profile']['city'];
      this.phone = `(${result['data']['profile']['prefixPhone']}) ${result['data']['profile']['phone']}`;
      this.activityCommercial = this.activityCommercials.filter(c => result['data']['profile']['comercialActivity'].split(",").includes(c['value'])).map(c => c['label']);
      this.sectorComercial = this.sectionCommercials.find(c => c['value'] === result['data']['profile']['commercialSection']) ? this.sectionCommercials.find(c => c['value'] === result['data']['profile']['commercialSection'])['label'] : '';
      this.typeCompany = this.companyTypes.find(c => c['value'] === result['data']['profile']['typeCompany']) ? this.companyTypes.find(c => c['value'] === result['data']['profile']['typeCompany'])['label'] : '';
      this.web = result['data']['profile']['web'];
      this.linkedin = result['data']['profile']['linkedin'];
      this.facebook = result['data']['profile']['facebook'];
      //card resumen
      this.summary = result['data']['profile']['summary'];
      this.mission = result['data']['profile']['mission'];
      this.vission = result['data']['profile']['vision'];
      this.valuesCBN = this.values.filter(c => result['data']['profile']['corporateValues'].includes(c['value'])).map(c => c['label']);
      this.lineBusiness = result['data']['categories'].map(c => {
        let category = this.categories.find(s => s['value'] === c['categoryCode']);
        let subGroup = this.subgroup.find(s => s['value'] === c['subGroupCode']);
        let group = this.group.find(s => s['value'] === c['groupCode']);
        let segment = this.segment.find(s => s['value'] === c['segmentCode']);
        return {
          category: category['label'],
          color: segment['color'],
          icon: segment['icon'],
          group: group['label'],
          segment: segment['label'],
          subGroup: subGroup['label']
        }
      });
      this.listClient = result['data']['clients'].map(c => {
        c['sectorName'] = this.sectionCommercials.find(a => a['value'] === c['sector']) ? this.sectionCommercials.find(a => a['value'] === c['sector'])['label'] : c['sector'];
        return c;
      });
      this.certificateISO9001 = result['data']['qualityManagement']['ISO9001Certificate'].length > 0 ? true : false;
      this.certificateGMP = result['data']['qualityManagement']['GMPCertificate'].length > 0 ? true : false;
      this.certificateBCR = result['data']['qualityManagement']['BRCCertificate'].length > 0 ? true : false;
      this.certificateHACCP = result['data']['qualityManagement']['HACCPCertificate'].length > 0 ? true : false;
      this.certificateBASC = result['data']['qualityManagement']['BASCCertificate'].length > 0 ? true : false;
      this.certificateISO14001 = result['data']['enviromentCal']['ISO14001Certificate'].length > 0 ? true : false;
      this.certificateISO18001 = result['data']['safetyCal']['OSHA18001Certificate'].length > 0 ? true : false;
      this.accountingInformation = result['data']['accountingInformation']['accountingInformation'];
      this.financialReference = result['data']['financialReference']['referenceReportFile'];
      //card 3
      this.executives = result['data']['executivesAndSalesRepresentatives'];
      //card estado
      // this.clasification = result['data']['relation']['clasification'];
      // this.status = result['data']['relation']['statusByCompany'];

      this.quantityLineBusiness = result['data']['categoriesCal']['calification'];
      this.quantityExecutive = result['data']['executiveCal']['calification'];
      this.quantityAccountBank = result['data']['accountBankCal']['calification'];

      this.quantityLineProduct = result['data']['lineProductAndServiceCal']['calification'];
      this.quantityExperienceClients = result['data']['experienceCal']['calification'];
      this.quantityInfraestructure = result['data']['infraestructureCal']['calification'];
      this.quantityConditionTerms = result['data']['conditionCommercialCal']['calification'];
      this.quantityQualityManagement = result['data']['qualityManagement']['calification'];
      this.quantityEnviromental = result['data']['enviromentCal']['calification'];
      this.quantitySecurity = result['data']['safetyCal']['calification'];
      this.quantityAccountingInformation = result['data']['accountingInformation']['calification'];
      this.quantityFinancialReference = result['data']['financialReference']['calification'];
      // this.list_activity_proccess = result['data']['relation']['listStatus'];

      let temp = {
        informationLevel: result['data']['profile']['informationLevel'],
        reputationLevel: result['data']['profile']['reputationLevel'],
        trustLevel: result['data']['profile']['trustLevel'],
      };
      this.changeValues(temp);
      this.loadComplete = false;
    }else{
      this.globalService.sendRequest({ severity:'error', summary: 'Error', detail: 'ERROR_THE_REQUEST', type : 'TOAST'});
    }
    this.loading = false;
  }

  closeModal() {
    this.close.emit();
  }

  changeValues(data) {
    this.informationScore = data['informationLevel'];
    this.informationNum = parseInt(data['informationLevel']);
    this.informationColor = this.getColorScale(this.informationNum);

    this.reputationScore = data['reputationLevel'];
    this.reputationNum = parseInt(data['reputationLevel']);
    this.reputationColor = this.getColorScale(this.reputationNum);

    this.trustScore = data['trustLevel'];
    this.trustNum = parseInt(data['trustLevel']);
    this.trustColor = this.getColorScale(this.trustNum);
  }
  getColorScale(number) {
    if (number >= 0 && number <= 45) {
      return 'red';
    } else if (number > 45 && number <= 55) {
      return 'orange';
    } else if (number > 55 && number <= 65) {
      return 'yellow';
    } else if (number > 65 && number <= 85) {
      return 'lemon';
    } else if (number > 85 && number <= 100) {
      return 'green';
    } else {
      return 'red';
    }
  }
  getRango(promedio) {
    if (promedio >= 1 && promedio <= 1000) {
      return `HASTA US$ 1,000`;
    } else if (promedio > 1000 && promedio <= 5000) {
      return 'US$ 1,001 - US$ 5,000';
    } else if (promedio > 5000 && promedio <= 10000) {
      return 'US$ 5,001 - US$ 10,000';
    } else if (promedio > 10000 && promedio <= 20000) {
      return 'US$ 10,001 - US$ 20,000';
    } else if (promedio > 20000 && promedio <= 50000) {
      return 'US$ 20,001 - US$ 50,000';
    } else if (promedio > 50000 && promedio <= 100000) {
      return 'US$ 50,001 - US$ 100,000';
    } else if (promedio > 100000 && promedio <= 250000) {
      return 'US$ 100,001 - US$ 250,000';
    } else if (promedio > 250000 && promedio <= 500000) {
      return 'US$ 250,001 - US$ 500,000';
    } else if (promedio > 500000 && promedio <= 1000000) {
      return 'US$ 500,001 - US$ 1M';
    } else if (promedio > 1000000 && promedio <= 2500000) {
      return 'US$ 1M - US$ 2.5M';
    } else if (promedio > 2500000 && promedio <= 5000000) {
      return 'US$ 2.5M - US$ 5M';
    } else if (promedio > 5000000 && promedio <= 10000000) {
      return 'US$ 5M - US$ 10M';
    } else if (promedio > 10000000 && promedio <= 20000000) {
      return 'US$ 10M - US$ 20M';
    } else if (promedio > 20000000 && promedio <= 50000000) {
      return 'US$ 20M - US$ 50M';
    } else if (promedio > 50000000) {
      return `MÁS DE US$ 50M`;
    }else{
      return '-'
    }
  }

  
  openCopyEmail(){
    this.globalService.sendRequest({ severity:'success', summary: 'Mensaje', detail: 'Email copiado al portapapeles.', type : 'TOAST'});
  }

  openCopyPhone(){
    this.globalService.sendRequest({ severity:'success', summary: 'Mensaje', detail: 'Teléfono copiado al portapapeles.', type : 'TOAST'});
  }
}
