import {Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';
import { GlobalService } from '../../../services/global.service';
import {ConfirmationService} from 'primeng/api';
import { AwsUserService } from 'src/app/services/aws-user.service';
import { Router } from '@angular/router';
import { SessionIn } from 'src/app/interfaces/session-in';

@Component({
  selector: 'app-home-top',
  templateUrl: './home-top.component.html',
  styleUrls: ['./home-top.component.scss'],
  providers: [ConfirmationService]
})
export class HomeTopComponent implements OnInit {
  sessionIn: SessionIn;
  type = "USUARIO";
  theme: string;
  lang: string; 
  themeStatus:boolean = false;
  langStatus:boolean = false;
  topbarItemClick: boolean;
  topbarMenuActive: boolean;
  activeTopbarItem: any;
  sidebarMobile = false;
  @Output() showNot = new EventEmitter<Boolean>();

  constructor(
    private globalService: GlobalService,
    private confirmationService: ConfirmationService,
    private sessionService: SessionService,
    private userService: AwsUserService,
    private router: Router
  ) {
    this.globalService.request.subscribe((res) => {
      if(res){
        switch (res['type']) {
          case 'UPDATECARD':
            this.sessionIn = this.sessionService.sessionIn;
            break;
        }

      }
    });
    this.sessionIn = this.sessionService.sessionIn;
   }

  ngOnInit(): void {
    this.theme = localStorage.getItem("theme");
    this.lang = localStorage.getItem("lang");
    if(localStorage.getItem("theme") === "light"){
      this.themeStatus = false
    }else if(localStorage.getItem("theme") === "dark"){
      this.themeStatus = true
    }

    if(localStorage.getItem("lang") === "es"){
      this.langStatus = false
    }else if(localStorage.getItem("lang") === "en"){
      this.langStatus = true
    }
  }

  onTopbarMenuButtonClick(event: Event) {
    this.topbarItemClick = true;
    this.topbarMenuActive = !this.topbarMenuActive;

    event.preventDefault();
  }

  themeChange(){
    let theme = this.themeStatus ? "dark" : "light";
    localStorage.setItem("theme", theme);
    this.globalService.switchTheme(theme);
    this.globalService.sendRequest({ type: 'UPDATETHEME' });
  }

  langChange(){
    this.lang = this.langStatus ? 'en' : 'es';
    localStorage.setItem("lang", this.lang);
    this.globalService.changeLanguage(this.lang);
    this.globalService.sendRequest({ type: 'UPDATELANG' });
  }

  confirmSignOff(event: Event){
    this.confirmationService.confirm({
      message: this.lang === 'es' ? '¿Está seguro que desea cerrar sesión? ' : 'Are you sure you want to log out?',
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'p-button-danger',
      accept: () => {
          this.signOff();
      }
    });
  }

  async signOff(){
    let logout = await new Promise((resolve, reject) => {
      this.userService.logout().subscribe(next => resolve(next), error => reject(error))
    }) as any;
    this.sessionService.deletedSession();
    localStorage.removeItem('theme');
    localStorage.removeItem('lang');
    window.location = window.location
  }

  openProfile(){
    this.router.navigate(['/home/my-account'])
  }

  openMenu(){
    this.globalService.sendRequest({type : 'OPENSIDEBARMOBILE'});
  }
}
