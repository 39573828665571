<ng-container *ngIf="list">
  <div class="relative card-catalogue shadow-5" style="border-radius: 24px;">
    
    <swiper [navigation]="false" class="mySwiper" [autoplay]="{
    delay: 2500,
    disableOnInteraction: false}">
      <ng-container *ngIf="list.files.length > 0">
        <ng-template swiperSlide *ngFor="let item of list.files">
          <img *ngIf="item.type === 'PHOTO'" [src]="item.url" alt="" srcset="">
          <img *ngIf="item.type === 'VIDEO'" src="https://provcatalogue.s3.us-east-2.amazonaws.com/no_image_camera.jpg"
            alt="" srcset="">
        </ng-template>
      </ng-container>
      <ng-container *ngIf="list.files.length === 0">
        <ng-template swiperSlide>
          <img src="https://provcatalogue.s3.us-east-2.amazonaws.com/no_image_camera.jpg" alt="" srcset="">
        </ng-template>
      </ng-container>
    </swiper>

    <div class="title-catalogue">
      
    </div>
    <!-- class="white-space-nowrap overflow-hidden text-overflow-ellipsis" -->
    <div class="tex-catalogue flex align-items-center justify-content-between">
      <h3 style="width: 75%;">{{list.catalogueName}}</h3>
      <div class="speedDialBtn" *ngIf="type !== 'SUMMARY'">
        <p-speedDial [model]="items" radius="20" direction="down" appendTo="body" showIcon="pi pi-bars" hideIcon="pi pi-times" buttonClassName="p-button-text p-button-white"></p-speedDial>
      </div>
    </div>
  </div>
</ng-container>