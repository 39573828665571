import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-chat-item',
  templateUrl: './chat-item.component.html',
  styleUrls: ['./chat-item.component.scss']
})
export class ChatItemComponent implements OnInit {

  @Input() data:any = null;
  @Input() type = "LOAD";
  @Output() createChatUser = new EventEmitter<Object>();
  @Output() goChatUser = new EventEmitter<Object>();

  constructor() { }

  ngOnInit(): void {
  }
  
  createChat(){
    this.createChatUser.emit(this.data);
  }

  goChat(){
    this.goChatUser.emit(this.data);
  }
}
