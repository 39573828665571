import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AwsSharedService } from 'src/app/services/aws-shared.service';
import { GlobalService } from 'src/app/services/global.service';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'app-upload-photo',
  templateUrl: './upload-photo.component.html',
  styleUrl: './upload-photo.component.scss'
})
export class UploadPhotoComponent implements OnInit{
  showFiles = true;
  files = [];
  statusUploading = false;

  @Output() nextFiles = new EventEmitter<any>();
  @Output() closeDialog = new EventEmitter<any>();
  constructor(
    private globalService: GlobalService,
    private awsShared: AwsSharedService
  ) { }

  ngOnInit(): void {
  }

  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  prepareFilesList(files: Array<File>) {
    if (files.length === 1) {
      const file = files[0];
      if (file.size <= 5242880 && (file.name.endsWith('.jpg') || file.name.endsWith('.png') || file.name.endsWith('.webp'))) {
        const namefile: any = file.name;
        const objPendingUpload = {
          id: uuid(),
          status: 'PENDING',
          progress: 0,
          size: this.formatBytes(file.size, 2),
          name: namefile.replaceAll(/[^a-zA-Z 0-9.]+/g, '_'),
          icon: 'far fa-file-image',
          color: '#3C33FF',
          file: file
        };

        this.files = [objPendingUpload];

        if (!this.statusUploading) {
          this.statusUploading = true;
          this.uploadFile(0);
        }
      } else {
        this.globalService.sendRequest({
          severity: 'error',
          summary: 'Error',
          detail: 'FILE_ERROR_INVALID',
          type: 'TOAST',
        });
      }
    } else {
      this.globalService.sendRequest({
        severity: 'error',
        summary: 'Error',
        detail: 'FILE_ERROR_MULTIPLE',
        type: 'TOAST',
      });
    }
  }

  async uploadFile(index) {
    const file = this.files[index];
    const preSigned = await this.awsShared.uploadFileS3All({
      type: 'docx', // Adjust the type if needed
      typeValue: '',
      path: '',
      name: file.name
    }).toPromise();

    if (preSigned['status']) {
      this.awsShared.uploadFileToS3({ url: preSigned['data']['url'], file: file.file })
        .subscribe((event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              file.status = 'PROGRESS';
              break;
            case HttpEventType.UploadProgress:
              file.progress = Math.round(event.loaded / event.total * 100);
              break;
            case HttpEventType.Response:
              const result = event;
              if (result && result['status'] === 200) {
                file.status = 'FINALIZED';
                file.url = result['url'].split('?')[0];
                this.uploadNextFile(index + 1);
                this.globalService.sendRequest({
                  severity: 'success',
                  summary: 'Éxito',
                  detail: 'DOCUMENT_UPLOAD_SUCCESS',
                  type: 'TOAST'
                });
              } else {
                this.files = [];
                this.statusUploading = false;
                this.globalService.sendRequest({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'FILE_ERROR_UPLOAD',
                  type: 'TOAST'
                });
              }
              break;
          }
        }, error => {
          this.files = [];
          this.statusUploading = false;
          this.globalService.sendRequest({
            severity: 'error',
            summary: 'Error',
            detail: 'FILE_ERROR_UPLOAD',
            type: 'TOAST'
          });
        });
    } else {
      this.files = [];
      this.statusUploading = false;
      this.globalService.sendRequest({
        severity: 'error',
        summary: 'Error',
        detail: 'FILE_ERROR_UPLOAD',
        type: 'TOAST'
      });
    }
  }

  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  uploadNextFile(index) {
    if (index < this.files.length) {
      this.uploadFile(index);
    } else {
      this.statusUploading = false;
    }
  }

  deleteFile() {
    this.files = [];
    this.statusUploading = false;
  }

  generateFiles() {
    if (this.files.length > 0) {
      this.nextFiles.emit(this.files.map(c => {
        return {
          url: c.url,
          name: c.name
        };
      }));
    } else {
      this.nextFiles.emit([]);
    }
  }

  closeUpload(){
    this.closeDialog.emit(false)
  }
}
