import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class AwsSharedService {



  constructor(
    private http: HttpClient, 
    private sessionService: SessionService
  ) {

  }

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'client-id':'apliclientesksks',
      'client-hash':'apliclientesksks',
      'Auth': this.sessionService.token
    })
  };

  /**
   * Generar url prefirmadas de s3
   * @param body Contiene el archivo
   * @returns 
   */
  uploadFileS3All(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/uploadFile`, body, this.httpOptions);
  }

  uploadFileToS3(body){
    let httpFormaData = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
    }); 
    return this.http.put(body['url'], body['file'], { headers: httpFormaData, reportProgress: true, observe: 'events' });
  }

  getCountries(){
    const httpOptionsPreference = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'client-id':'apliclientesksks',
        'client-hash':'apliclientesksks',
        'Auth': this.sessionService.token
      })
    };
    return this.http.post(`${environment.baseUrlAPI}/listCountries`, {},  httpOptionsPreference);
  }
}
