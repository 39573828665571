<p-dialog [draggable]="false" [closeOnEscape]="false" header="{{ 'upload_files_video' | translate }}" [(visible)]="showFiles" [modal]="true" [closable]="false"  
[style]="{'width': '60%'}" [breakpoints]="{'540px': '100vw'}">
    <div class="grid">
        <div *ngIf="statusUploading != 'FINALIZED'" class="col-12 md:col-6 lg:col-6">
            <div class="col-12 dropZoneVideo p-2 relative m-0 flex align-content-center justify-content-center flex-wrap" appDndVideo (fileDropped)="onFileDropped($event)">
                <input class="opacity-0 absolute top-0 left-0 w-full h-full z-2 cursor-pointer" type="file" #fileDropVideo id="fileDropVideo" 
                accept="video/mp4,video/quicktime" (change)="fileBrowseHandler($event.target.files)" />
                <img src="https://cbn-proveedores.s3.sa-east-1.amazonaws.com/cbn-images/others/uploader-video.png" alt="" class="w-full">
                <p class="text-center m-0">{{ 'drop_files_video' | translate }} <br> {{ 'or' | translate }} <label for="fileDropVideo"><b>{{ 'SEARCH' | translate }}</b></label></p>                
            </div>
        </div>
        <div *ngIf="statusUploading === 'FINALIZED'" class="col-12 md:col-6 lg:col-6">
            <video height="250" controls style="width: 100%;background: #000000;border-radius: 15px;">
                <source [src]="urlVideo">
            </video>
        </div>
        <div class="col-12 md:col-6 lg:col-6">
            <p-scrollPanel [style]="{width: '100%', height: '250px'}">
                <ng-container *ngIf="statusUploading != 'NEW'">
                    <div class="grid card-file">
                        <div class="col-2 text-center">
                            <i class="far fa-file-video icon-ext"  style="color: #00E9FC;"></i>
                        </div>
                        <div class="col-9">
                            <div class="text-overflow-ellipsis w-full overflow-hidden white-space-nowrap">{{nameVideo}}</div>
                            <span>{{sizeVideo}}</span>
                            <p-progressBar [value]="progress" styleClass="progress-file" [showValue]="false"></p-progressBar>
                        </div>
                        <div class="col-1">
                            <i *ngIf="statusUploading === 'FINALIZED'" class="far fa-times-circle text-pink-300 text-sm cursor-pointer" (click)="deleteFile()"></i>
                        </div>
                    </div>
                    <div class="grid card-file">
                        <div *ngIf="statusUploadingImg === 'NEW'" class="col-12 text-center">
                            <p-fileUpload styleClass="p-button-rounded" #fubauto mode="basic" accept="image/jpg, image/jpeg, image/png" maxFileSize="26214400" 
                            [customUpload]="true" (uploadHandler)="uploadPrev($event, fubauto)" name="myfile[]" [auto]="true" chooseLabel="{{ 'upload_label_video' | translate }}"></p-fileUpload>
                        </div>
                        <ng-container *ngIf="statusUploadingImg != 'NEW'">
                            <div class="col-2 text-center">
                                <i class="far fa-file-image icon-ext"  style="color: #BF00FC;"></i>
                            </div>
                            <div class="col-9">
                                <div class="text-overflow-ellipsis w-full overflow-hidden white-space-nowrap">{{nameImg}}</div>
                                <span>{{sizeImg}}</span>
                                <p-progressBar [value]="progressImg" styleClass="progress-file" [showValue]="false"></p-progressBar>
                            </div>
                            <div class="col-1">
                                <i *ngIf="statusUploadingImg === 'FINALIZED'" class="far fa-times-circle text-pink-300 text-sm cursor-pointer" (click)="deleteFileImg()"></i>
                            </div>
                            <div *ngIf="urlPrevImg.length > 0" class="col-12 text-center">
                                <img [src]="urlPrevImg" height="95" style="border-radius: 5px;">
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </p-scrollPanel>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button pButton pRipple type="button" label="{{ 'cancelU' | translate }}"  class="p-button-rounded p-button-danger w-button" (click)="closeUploadVideo()"></button>
        <button pButton pRipple type="button" label="{{ 'CONTINUE' | translate }}"  class="p-button-rounded p-button-info w-button" [disabled]="(statusUploading === 'UPLOADING' || statusUploadingImg === 'UPLOADING' || urlVideo.length === 0 )" (click)="generateFiles()"></button>
    </ng-template>
</p-dialog>