<ng-container *ngIf="message">
    <div class="message-chat" [ngClass]="{ 'me': message.me }" >
        <div class="avatarContent">
            <!-- <ng-container *ngIf="message.">

            </ng-container> -->
            <span class="border-avatar" [style.border-color]="colorAvatar">
                <p-avatar [image]="avatar" styleClass="avatarChat" shape="circle"></p-avatar>
            </span>
        </div>
        <div class="messageContent">
            <div class="container-message flex align-items-center">
                <span style="display: inline-block;" class="white-space-nowrap overflow-hidden text-overflow-ellipsis">
                    <span [style.color]="colorName" class="name-participant " > 
                        <ng-container *ngIf="name === 'NOT_USER_NAME'">
                           <b translate>{{name}}</b>  - {{correlativeParticipant}}
                        </ng-container> 
                        <ng-container *ngIf="name !== 'NOT_USER_NAME'">
                            {{name}}
                        </ng-container>
                    </span> 
                    <span class="ml-2 name-participant" *ngIf="company">
                        {{company}}
                    </span>
                </span>
               <span class="time"><i class="mr-2 far fa-clock"></i>{{ (time * 1000) | date:'h:mm a' }} </span> 
            </div>
            <div class="content-html-message" [innerHtml]="messageHtml"></div>
            <ng-container *ngIf="files.length > 0">
                <div class="">
                    <div *ngFor="let file of files;let i = index;" class="col-12 flex justify-content-between border-files">
                        <div class="w-2rem flex align-items-center justify-content-center mr-2">
                            <i class="far {{getIcon(file.name)}} text-2xl" [style.color]="getColor(file.name)"></i>
                        </div>
                        <div class="flex align-items-center overflow-hidden" style="width: 100%;">
                            <span style="width: calc(100% - 30px)!important;" class=" overflow-hidden text-left text-overflow-ellipsis white-space-nowrap block">
                                <a target="_blank" class="text-900 no-underline">{{file.name}}</a>
                            </span>
                        </div>

                        <div class="downloadBtn">
                            <button pButton (click)="download(file.url)" pRipple style="padding: 8px;padding: 8px;height: 25px;width: 25px;" type="button" icon="pi pi-cloud-download" class="p-button-rounded p-button-success p-button-outlined"></button>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="images.length > 0">
                <div class="cursor-pointer my-2" style="width: 280px;">
                    <div class="border-images">
                        <ng-container *ngIf="images.length === 1">
                            <div class="image-grid-1 w-full h-full">
                                <img (click)="openPreviewIndex(0)" src="{{images[0].url}}" class="image-grid-col-2 image-grid-row-2">
                            </div>
                        </ng-container>
                        <ng-container *ngIf="images.length === 2">
                            <div class="image-grid-2">
                                <img (click)="openPreviewIndex(0)" src="{{images[0].url}}" >
                                <img (click)="openPreviewIndex(1)" src="{{images[1].url}}" >
                            </div>
                        </ng-container>
                        <ng-container *ngIf="images.length >= 3">
                            <div class="image-grid-3">
                                <img (click)="openPreviewIndex(0)" class="image-grid-col-2 image-grid-row-2" src="{{images[0].url}}" >
                                <img (click)="openPreviewIndex(1)" src="{{images[1].url}}" alt="architecture">
                                <span class="relative" (click)="openPreviewIndex(2)">
                                    <ng-container *ngIf="images.length > 3">
                                        <span class="absolute w-full h-full flex align-items-center justify-content-center" style="background: rgba(0, 0, 0, 0.5);">
                                            <span class="text-2xl" style="color: white;">+ {{images.length - 3}}</span>
                                        </span>
                                    </ng-container>
                                    <img src="{{images[2].url}}" alt="architecture">
                                </span>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="videos.length > 0">
                <div class="cursor-pointer my-2" style="width: 280px;">
                    <div class="border-video">
                        <ng-container *ngIf="videos.length === 1">
                            <div class="image-grid-1 w-full h-full">
                                <video (click)="openVideoPreviewIndex(0)" id="background-video" muted playsinline>
                                    <source [src]="videos[0].url">
                                </video>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="videos.length === 2">
                            <div class="image-grid-2">
                                <video (click)="openVideoPreviewIndex(0)" id="background-video" muted playsinline>
                                    <source [src]="videos[0].url">
                                </video>
                                <video (click)="openVideoPreviewIndex(1)" id="background-video" muted playsinline>
                                    <source [src]="videos[1].url">
                                </video>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="videos.length >= 3">
                            <div class="image-grid-3">
                                <video  (click)="openVideoPreviewIndex(0)" class="image-grid-col-2 image-grid-row-2" id="background-video" muted playsinline>
                                    <source [src]="videos[0].url">
                                </video>
                                <video (click)="openVideoPreviewIndex(1)" id="background-video" muted playsinline>
                                    <source [src]="videos[1].url">
                                </video>
                                <span class="relative" (click)="openVideoPreviewIndex(2)">
                                    <ng-container *ngIf="videos.length > 3">
                                        <span class="absolute w-full h-full flex align-items-center justify-content-center" style="background: rgba(0, 0, 0, 0.5);">
                                            <span class="text-2xl" style="color: white;">+ {{videos.length - 3}}</span>
                                        </span>
                                    </ng-container>
                                    <video (click)="openVideoPreviewIndex(2)" id="background-video" muted playsinline>
                                            <source [src]="videos[2].url">
                                    </video>
                                </span>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
            <div *ngIf="message.quantityAnswer > 0 && message.typeMessage === 'PRINCIPAL' && thread === 'NO'" class="container-response" (click)="openResponse()">
                <span class="ml-2 font-bold" style="color: var(--primary-color);">{{message.quantityAnswer}} Respuestas</span> <i class="mx-2 fas fa-chevron-right"></i>
            </div>
            <div class="options-message">
                <ng-container *ngIf="thread === 'NO'">
                    <i class="fas fa-chevron-down" (click)="menuChat.toggle($event)"></i>
                </ng-container>
            </div>
        </div>
    </div>

    <ng-container *ngIf="images.length > 0">
        <p-sidebar [(visible)]="previewSidebarImage" [fullScreen]="true" [blockScroll]="true" appendTo="body" styleClass="sidebarPreview">
            <div class="grid m-0 list-preview-photo-video relative overflow-hidden">
                <div class="col-12 p-0 relative" style="z-index: 2;">
                    <div class="w-full container-option-preview">
                        <div class="name-avatar">
                            <span class="border-avatar" [style.border-color]="colorAvatar">
                                <p-avatar [image]="avatar" styleClass="avatarChat" shape="circle"></p-avatar>
                            </span>
                            <div class="container-message ml-2">
                                <span [style.color]="colorName" class="name-participant">{{name}}</span> <span class="ml-2 name-participant" *ngIf="company">{{company}}</span><span class="time"><i class="mr-2 far fa-clock"></i>{{ (time * 1000) | date:'h:mm a' }} </span> 
                             </div>
                        </div>
                        <div>
                            <button pButton pRipple type="button" icon="fas fa-cloud-download-alt" class="p-button-rounded p-button-secondary mr-2" (click)="download(tempSlider)"></button>
                            <button pButton pRipple type="button" icon="fas fa-search-plus" class="p-button-rounded p-button-secondary mr-2"  (click)="zoomIn()"></button>
                            <button pButton pRipple type="button" icon="fas fa-search-minus" class="p-button-rounded p-button-secondary mr-2" (click)="zoomOut()"></button>
                            <button pButton pRipple type="button" icon="pi pi-times" class="close-preview p-button-rounded p-button-secondary" (click)="closePreview()"></button>
                        </div>
                    </div>
                </div>
                <div class="col-12 p-0 relative" [ngClass]="{'hidden': images.length === 0 }">
                    <swiper [spaceBetween]="10" [navigation]="true" class="mySwiper-preview" [zoom]="true" [pagination]="{clickable: true}" (swiper)="onSwiper($event)"  (slideChange)="onSlideChange($event)">
                        <ng-template swiperSlide *ngFor="let item of images">
                            <div class="w-full h-full relative">
                                <div class="swiper-zoom-container relative">
                                    <img src="{{item.url}}" />
                                </div>
                            </div>
                        </ng-template>
                    </swiper>
                </div>
            </div>
        </p-sidebar>
    </ng-container>

    <ng-container *ngIf="videos.length > 0">
        <p-sidebar [(visible)]="previewSidebarVideo" [fullScreen]="true" [blockScroll]="true" appendTo="body" styleClass="sidebarPreview">
            <div class="grid m-0 list-preview-photo-video relative overflow-hidden">
                <div class="col-12 p-0 relative" style="z-index: 2;">
                    <div class="w-full container-option-preview">
                        <div class="name-avatar">
                            <span class="border-avatar" [style.border-color]="colorAvatar">
                                <p-avatar [image]="avatar" styleClass="avatarChat" shape="circle"></p-avatar>
                            </span>
                            <div class="container-message ml-2">
                                <span [style.color]="colorName" class="name-participant">{{name}}</span> <span class="ml-2 name-participant" *ngIf="company">{{company}}</span><span class="time"><i class="mr-2 far fa-clock"></i>{{ (time * 1000) | date:'h:mm a' }} </span> 
                             </div>
                        </div>
                        <div>
                            <button pButton pRipple type="button" icon="fas fa-cloud-download-alt" class="p-button-rounded p-button-secondary mr-2" (click)="download(tempSlider)"></button>
                            <button pButton pRipple type="button" icon="pi pi-times" class="close-preview p-button-rounded p-button-secondary" (click)="closeVideoPreview()"></button>
                        </div>
                    </div>
                </div>
                <div class="col-12 p-0 relative" [ngClass]="{'hidden': videos.length === 0 }">
                    <swiper [spaceBetween]="10" [navigation]="true" class="mySwiper-preview" [zoom]="true" [pagination]="{clickable: true}" (swiper)="onSwiperVideo($event)" (slideChange)="onSlideChangeVideo($event)">
                        <ng-template swiperSlide *ngFor="let item of videos">
                            <div class="w-full h-full relative">
                                <div class="swiper-zoom-container relative">
                                    <video id="background-video" loop controls controlsList="nodownload" style="width:calc(100vw - 300px);padding-top: 4.5rem;
                                    padding-bottom: 2.5rem; height: 100%;">
                                        <source [src]="item.url">
                                    </video>
                                </div>
                            </div>
                        </ng-template>
                    </swiper>
                </div>
            </div>
        </p-sidebar>
    </ng-container>
</ng-container>

<!-- options chat -->
<p-menu #menuChat [popup]="true" [model]="itemsChat" appendTo="body"></p-menu>

<!-- PREVIEW PHOTO VIDEO -->

