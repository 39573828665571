<div [class]="'br br-' + theme" [class.br-readonly]="readOnly" [class.br-disabled]="disabled">

    <div class="br-units" (mouseleave)="reset()">
  
      <div class="br-unit" *ngFor="let unit of contexts" [class.br-fraction]="unit.fraction"
          [class.br-selected]="unit.selected" [class.br-active]="unit.active"
          (click)="unit.click($event)" (mouseenter)="unit.enter()"></div>
  
    </div>
  
    <div *ngIf="showText" class="br-text">{{ nextRate | rateTitle: titles}}</div>
  </div>