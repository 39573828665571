import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class hubService {
  constructor(
    private http: HttpClient,
    private sessionService: SessionService
  ) {}

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'client-id': 'apliclientesksks',
      'client-hash': 'apliclientesksks',
      Auth: this.sessionService.token,
    }),
  };

  // PANEL HUB 
  getCompanyHub(){
    return this.http.post(`${environment.baseUrlAPI}/admin/getCompanyHub`, {}, this.httpOptions);
  }

  updateCompanyHub(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/updateCompanyHub`, body, this.httpOptions);
  }

  deleteHubBId(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/deleteHubBId`, body, this.httpOptions);
  }

  // CREAR HUB
  createCompanyHub(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/createCompanyHub`, body, this.httpOptions);
  }

  getCompanyByIdHub(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/getCompanyByIdHub`, body, this.httpOptions);
  }

  // REQUERIMIENTOS
  updateRequierementHub(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/updateRequierementHub`, body, this.httpOptions);
  }
  
  getRequirementHubById(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/getRequirementHubById`, body, this.httpOptions);
  }

  //PREFERENCIAS

  updatePreferencesHub(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/updatePreferencesHub`, body, this.httpOptions);
  }

  getPreferencesHubById(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/getPreferencesHubById`, body, this.httpOptions);
  }

  //PERMISOS

  updatePermissionPostHub(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/updatePermissionPostHub`, body, this.httpOptions);
  }

  getPermissionPostHubById(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/getPermissionPostHubById`, body, this.httpOptions);
  }

  //FIRMA
  secondUpdateCompanyHub(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/secondUpdateCompanyHub`, body, this.httpOptions);
  }

  // SOLICITUDES
  getCompanyRequestHub(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/getCompanyRequestHub`, body, this.httpOptions);
  }

  acceptedRequestHubCompany(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/acceptedRequestHubCompany`, body, this.httpOptions);
  }

  acceptedRequestMemberHubCompany(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/acceptedRequestMemberHubCompany`, body, this.httpOptions);
  }

  rejectedRequestHubCompany(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/rejectedRequestHubCompany`, body, this.httpOptions);
  }

  // MIEMBROS
  getHubsCompanyPending(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/getHubsCompanyPending`, body, this.httpOptions);
  }

  accepedtHubCompany(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/accepedtHubCompany`, body, this.httpOptions);
  }

  rejectedtHubCompany(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/rejectedtHubCompany`, body, this.httpOptions);
  }

  /**
   * 
   * @param body { idHub, idCompany, status }
   * @returns 
   */
  updatePermissionPostCompany(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/updatePermissionPostCompany`, body, this.httpOptions);
  }
  
  searchCompanyAll(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/searchCompanyAll`, body, this.httpOptions);
  }

  getDependencyInfraestructure(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/getCompanyInfrastructure`, body,  this.httpOptions);
  }

  //ASOCIADOS
  getHubsCompanyAcceptedAssocieated(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/getHubsCompanyAcceptedAssocieated`, body, this.httpOptions);
  }
  
  serachCompany(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/serachCompany`, body, this.httpOptions);
  }

  associatedCompany(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/addAssociatedHubCompany`, body, this.httpOptions);
  }

  //PUBLICIDAD
  createPublicationsHub(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/createPublicationsHub`, body, this.httpOptions);
  }

  getPublicationsHubByidHub(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/getPublicationsHubByidHub`, body, this.httpOptions);
  }

  deletePublicationHubBId(body){
    const httpOptionsTemp = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'client-id':'apliclientesksks',
        'client-hash':'apliclientesksks',
        'Auth': this.sessionService.token
      }),
      body: body
    };
    return this.http.delete(`${environment.baseUrlAPI}/admin/deletePublicationHubBId`, httpOptionsTemp);
  }
}

