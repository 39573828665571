import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { SessionGuard } from './services/guards/session.guard';
const routes: Routes = [
  { 
    path: '', 
    loadChildren: () => import('./modules/signin/signin.module').then(m => m.SigninModule)
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [ SessionGuard ],
    children : [
      {
        path: '',
        loadChildren: () => import('./modules/home/home-panel/home-panel.module').then(m => m.HomePanelModule)
      },
      {
        path: 'hubs',
        loadChildren: () => import('./modules/home/red/hubs/hubs.module').then(m => m.HubsModule)
      },
      {
        path: 'how-does-it-work',
        loadChildren: () => import('./modules/home/how-does-it-work/how-does-it-work.module').then(m => m.HowDoesItWorkModule)
      },
      {
        path: 'business-panel',
        loadChildren: () => import('./modules/home/business/business-panel/business-panel.module').then(m => m.BusinessPanelModule)
      },
      {
        path: 'verify-business',
        loadChildren: () => import('./modules/home/business/verify-business/verify-business.module').then(m => m.VerifyBusinessModule)
      },
      {
        path: 'mass-invite',
        loadChildren: () => import('./modules/home/business/mass-invite/mass-invite.module').then(m => m.MassInviteModule)
      },
      {
        path: 'my-account',
        loadChildren: () => import('./modules/home/settings/my-account/my-account.module').then(m => m.MyAccountModule)
      },
      {
        path: 'users',
        loadChildren: () => import('./modules/home/settings/user-panel/user-panel.module').then(m => m.UserPanelModule)
      },
      {
        path: 'messages',
        loadChildren: () => import('./modules/home/chat-company/chat-company.module').then(m => m.ChatCompanyModule)
      },
      {
        path: 'ses-templates',
        loadChildren: () => import('./modules/home/settings/ses-template/ses-template.module').then(m => m.SesTemplateModule)
      },
      {
        path: 'feedback',
        loadChildren: () => import('./modules/home/feedback/feedback.module').then(m => m.FeedbackModule)
      },
      {
        path: 'updater',
        loadChildren: () => import('./modules/home/updater-cbn/updater-cbn.module').then(m => m.UpdaterCbnModule)
      },
      {
        path: 'dashboard-template',
        loadChildren: () => import('./modules/home/dashboard-template/dashboard-template.module').then(m => m.DashboardTemplateModule)
      },
      {
        path: 'services-subservices',
        loadChildren: () => import('./modules/home/settings/services-subservices/services-subservices.module').then(m => m.ServicesSubservicesModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
