import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from '../session.service';

@Injectable({
  providedIn: 'root'
})
export class SessionGuard  {

  constructor(private sessionService : SessionService){ }

  canActivate() {
    if(this.sessionService.statusSession){
      return true;
    }else{
      this.sessionService.deletedSession();
      window.location.href = "/"
      return false;
    }
  }
  
}
