<div id="sidebar" class="layout-sidebar mobile hidden" [ngStyle]="{'overflow':app.sidebarActive ? 'hidden' : 'visible'}"
    (click)="app.onSidebarClick($event)">
    <div class="layout-tabmenu"
        style="box-shadow: 0px 1px 7px rgb(0 0 0 / 10%), 0px 4px 5px -2px rgb(0 0 0 / 12%), 0px 10px 15px -5px rgb(0 0 0 / 20%) !important;">
        <a class="menu-button">
            <button pButton pRipple type="button" icon="menu-button fas fa-times"
                class="p-button-rounded p-button-secondary p-button-text" (click)="removeMenu()"></button>
        </a>
        <!-- inicio de los iconos de sidebar -->
        <ul class="layout-tabmenu-nav">
            <li *ngFor="let tab of tabs" [ngClass]="{'active-item':app.activeTabIndex === tab.index}">
                <a href="#" class="ripplelink tabmenuitem-link" (click)="app.onTabClick($event, tab.index)">
                    <!-- Icono variable fa-(id-card) -->
                    <i class="fa {{tab.icon}}"></i></a> <!-- Icono variable -->
                <div class="layout-tabmenu-tooltip">
                    <div class="layout-tabmenu-tooltip-arrow"></div>
                    <!-- Texto variable -->
                    <div class="layout-tabmenu-tooltip-text">{{tab.text}}</div>
                </div>
            </li>
        </ul>

        <!-- inicio del contenido de sidebar -->
        <div class="layout-tabmenu-contents">
            <!-- Contenedor -->
            <div *ngFor="let body of bodies" class="layout-tabmenu-content"
                [ngClass]="{'layout-tabmenu-content-active': app.activeTabIndex === body.index}">
                <div class="layout-submenu-title clearfix">
                    <!-- Titulo de zona de sidebar -->
                    <span>{{body.title}}</span>
                </div>
                <!-- Subtitulo y contenido de elemento sidebar -->

                <ng-container *ngFor="let content of body['content']">
                    <div class="projects-tab">
                        <!-- Contenedor -->
                        <ul>
                            <!-- Lista de subtitulos -->
                            <li class="clearfix">
                                <ng-container *ngIf="content.type === 'message'">
                                    <ng-container *ngIf="content.enabled">
                                        <div class="content-top">
                                            <a class="nav-side-a" routerLink="/home/{{content.href}}" routerLinkActive="sec-active" [routerLinkActiveOptions]="{exact: true}" *ngIf="content.href != 'home'">
                                                <!-- El icono es variable (fa-(adress-card-o)) -->
                                                <i class="fa {{content.icon}}" id="icon{{content.order}}"></i>
                                                <!-- Subtitulo -->
                                                <span style="float: unset" class="project-title text-enabled">{{content.text}}</span>
                                            </a>
                                            <a class="nav-side-a" routerLink="/home" routerLinkActive="sec-active" [routerLinkActiveOptions]="{exact: true}" *ngIf="content.href === 'home'">
                                                <i class="{{content.icon}}" id="icon{{content.order}}"></i>
                                                <span style="float: unset" class="project-title text-enabled">{{content.text}}</span>
                                            </a>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="!content.enabled">
                                        <div class="content-top">
                                            <!-- El icono es variable (fa-(adress-card-o)) -->
                                            <i class="fa {{content.icon}}" id="icon{{content.order}}"></i>
                                            <!-- Subtitulo -->
                                            <span style="float: unset"
                                                class="project-title text-disabled">{{content.text}}</span>
                                        </div>
                                    </ng-container>
                                    <div class="container-nav" *ngFor="let branch of content['content']">
                                        <span *ngIf="!branch.enabled" class="message">{{branch.title}}
                                        </span>
                                        <a *ngIf="branch.enabled" class="nav-side-a" routerLink="/home/{{branch.href}}"
                                            routerLinkActive="sec-active" [routerLinkActiveOptions]="{exact: true}">
                                            <span class="message nav-side-text">{{branch.title}}</span>
                                        </a>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="content.type === 'progressBranch'">
                                    <i class="fa {{content.icon}}" id="icon{{content.order}}"></i>
                                    <span style="float: unset"
                                        class="project-title text-enabled">{{content.text}}</span>
                                    <div class="project-progressbar" style="width: 70px; margin-top: 6px;">
                                        <div class="project-progressbar-value" id="bar-order-{{content.order}}"
                                            style.width="{{getWitdh(content.score, content.order)}}%">
                                        </div>
                                    </div>
                                    <span>&nbsp;&nbsp;{{content.score}}%</span>
                                    <div class="container-nav" *ngFor="let branch of content['content']">
                                        <span *ngIf="!branch.enabled" class="message">{{branch.title}}
                                        </span>
                                        <a *ngIf="branch.enabled" class="nav-side-a" routerLink="/home/{{branch.href}}"
                                            routerLinkActive="sec-active" [routerLinkActiveOptions]="{exact: true}">
                                            <span class="message nav-side-text">{{branch.title}}</span>
                                        </a>
                                    </div>
                                </ng-container>
                            </li>
                        </ul>
                    </div>
                </ng-container>

            </div>


        </div>
    </div>
</div>

<div id="sidebar" class="layout-sidebar desktop" [ngStyle]="{'overflow':app.sidebarActive ? 'hidden' : 'visible'}"
    (click)="app.onSidebarClick($event)">
    <div class="layout-tabmenu">
        <a class="menu-button" routerLink="/home">
            <img src="assets/images/CBN.png" class="morpheus-logo" />
        </a>
        <!-- inicio de los iconos de sidebar -->
        <ul class="layout-tabmenu-nav">
            <li *ngFor="let tab of tabs" [ngClass]="{'active-item':app.activeTabIndex === tab.index}">
                <a href="#" class="ripplelink tabmenuitem-link" (click)="app.onTabClick($event, tab.index)">
                    <!-- Icono variable fa-(id-card) -->
                    <i class="fa {{tab.icon}}"></i></a> <!-- Icono variable -->
                <div class="layout-tabmenu-tooltip">
                    <div class="layout-tabmenu-tooltip-arrow"></div>
                    <!-- Texto variable -->
                    <div class="layout-tabmenu-tooltip-text">{{tab.text}}</div>
                </div>
            </li>
        </ul>

        <!-- inicio del contenido de sidebar -->
        <div class="layout-tabmenu-contents">
            <!-- Contenedor -->
            <div *ngFor="let body of bodies" class="layout-tabmenu-content"
                [ngClass]="{'layout-tabmenu-content-active': app.activeTabIndex === body.index}">
                <div class="layout-submenu-title clearfix">
                    <!-- Titulo de zona de sidebar -->
                    <span>{{body.title}}</span>
                </div>
                <!-- Subtitulo y contenido de elemento sidebar -->

                <ng-container *ngFor="let content of body['content']">
                    <div class="projects-tab">
                        <!-- Contenedor -->
                        <ul>
                            <!-- Lista de subtitulos -->
                            <li class="clearfix">
                                <ng-container *ngIf="content.type === 'message'">
                                    <ng-container *ngIf="content.enabled">
                                        <div class="content-top">
                                            <a class="nav-side-a" routerLink="/home/{{content.href}}" routerLinkActive="sec-active" [routerLinkActiveOptions]="{exact: true}" *ngIf="content.href != 'home'">
                                                <!-- El icono es variable (fa-(adress-card-o)) -->
                                                <i class="fa {{content.icon}}" id="icon{{content.order}}"></i>
                                                <!-- Subtitulo -->
                                                <span style="float: unset" class="project-title text-enabled">{{content.text}}</span>
                                            </a>
                                            <a class="nav-side-a" routerLink="/home" routerLinkActive="sec-active" [routerLinkActiveOptions]="{exact: true}" *ngIf="content.href === 'home'">
                                                <i class="{{content.icon}}" id="icon{{content.order}}"></i>
                                                <span style="float: unset" class="project-title text-enabled">{{content.text}}</span>
                                            </a>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="!content.enabled">
                                        <div class="content-top">
                                            <!-- El icono es variable (fa-(adress-card-o)) -->
                                            <i class="fa {{content.icon}}" id="icon{{content.order}}"></i>
                                            <!-- Subtitulo -->
                                            <span style="float: unset"
                                                class="project-title text-disabled">{{content.text}}</span>
                                        </div>
                                    </ng-container>
                                    <div class="container-nav" *ngFor="let branch of content['content']">
                                        <span *ngIf="!branch.enabled" class="message">{{branch.title}}
                                        </span>
                                        <a *ngIf="branch.enabled" class="nav-side-a" routerLink="/home/{{branch.href}}"
                                            routerLinkActive="sec-active" [routerLinkActiveOptions]="{exact: true}">
                                            <span class="message nav-side-text">{{branch.title}}</span>
                                        </a>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="content.type === 'progressBranch'">
                                    <i class="fa {{content.icon}}" id="icon{{content.order}}"></i>
                                    <span style="float: unset"
                                        class="project-title text-enabled">{{content.text}}</span>
                                    <div class="project-progressbar" style="width: 70px; margin-top: 6px;">
                                        <div class="project-progressbar-value" id="bar-order-{{content.order}}"
                                            style.width="{{getWitdh(content.score, content.order)}}%">
                                        </div>
                                    </div>
                                    <span>&nbsp;&nbsp;{{content.score}}%</span>
                                    <div class="container-nav" *ngFor="let branch of content['content']">
                                        <span *ngIf="!branch.enabled" class="message">{{branch.title}}
                                        </span>
                                        <a *ngIf="branch.enabled" class="nav-side-a" routerLink="/home/{{branch.href}}"
                                            routerLinkActive="sec-active" [routerLinkActiveOptions]="{exact: true}">
                                            <span class="message nav-side-text">{{branch.title}}</span>
                                        </a>
                                    </div>
                                </ng-container>
                            </li>
                        </ul>
                    </div>
                </ng-container>

            </div>


        </div>
    </div>
</div>