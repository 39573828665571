import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-block-ui-card',
  templateUrl: './block-ui-card.component.html',
  styleUrls: ['./block-ui-card.component.scss']
})
export class BlockUiCardComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }

}
