import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import Cropper from 'cropperjs';

@Component({
  selector: 'app-crop-image-global',
  templateUrl: './crop-image-global.component.html',
  styleUrls: ['./crop-image-global.component.scss'],
})
export class CropImageGlobalComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  imageCrop: Cropper;
  @Input() type_crop = NaN;
  @Output() close = new EventEmitter<Boolean>();
  @Output() sendCrop = new EventEmitter<Object>();

  noImage = true;
  errorImage = {
    status_size: true,
    status_file: true,
    status_general: true,
  };
  urlImg = '';

  constructor() {}

  ngOnInit(): void {}

  uploadListener(info) {
    let files = info.srcElement.files;
    if (this.isValidImage(files[0]).status_general) {
      this.errorImage = this.isValidImage(files[0]);
      this.getBase64(files[0]).then((res: string) => {
        this.urlImg = res;
        this.noImage = false;
        setTimeout(() => {
          this.createCrop();
        }, 200);
      });
    } else {
      this.errorImage = this.isValidImage(files[0]);
    }
  }

  createCrop() {
    const image: HTMLImageElement = document.getElementById(
      'image-crop'
    ) as HTMLImageElement;
    this.imageCrop = new Cropper(
      image,
      !this.type_crop
        ? {
            aspectRatio: NaN,
          }
        : {
            aspectRatio: this.type_crop,
            minCropBoxHeight: 256,
            minCropBoxWidth: 256,
            minContainerWidth: 256,
            minContainerHeight: 256,
          }
    );
  }

  zoom(ratio) {
    this.imageCrop.zoom(ratio);
  }

  move(x, y) {
    this.imageCrop.move(x, y);
  }

  rotate(degree) {
    this.imageCrop.rotate(degree);
  }

  scaleX() {
    let val = this.imageCrop.getData().scaleX === 1 ? -1 : 1;
    this.imageCrop.scaleX(val);
  }

  scaleY() {
    let val = this.imageCrop.getData().scaleY === 1 ? -1 : 1;
    this.imageCrop.scaleY(val);
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  isValidImage(file: any) {
    if (file) {
      let weight = file['size'];
      let ext = file['name'].split('.').pop();
      let size = weight <= 5242880 ? true : false;
      let file_s = ['jpg', 'png', 'jpeg'].includes(ext.toLowerCase())
        ? true
        : false;
      return {
        status_size: size,
        status_file: file_s,
        status_general: size && file_s ? true : false,
      };
    } else {
      return {
        status_size: false,
        status_file: false,
        status_general: false,
      };
    }
  }

  ngAfterViewInit() {
    /* const image: HTMLImageElement = document.getElementById('image') as HTMLImageElement;
    this.dataCrop = new Cropper(image, {
      aspectRatio: NaN,
      crop(event) {
        console.log("======")
        console.log(event.detail.width);
        console.log(event.detail.height);
        console.log("======")
      },
    }); */
  }

  getImage() {
    /* var imagenes = this.dataCrop.getCroppedCanvas().toDataURL("image/png");
    this.imageUrl = imagenes; */
  }

  ngOnDestroy() {}

  clear() {
    if (this.imageCrop) {
      this.imageCrop.destroy();
    }
    this.noImage = true;
    this.urlImg = '';
    this.errorImage = {
      status_size: true,
      status_file: true,
      status_general: true,
    };
  }

  cancel() {
    this.clear();
    this.close.emit(false);
  }

  saveCrop() {
    this.sendCrop.emit({
      file_base64: this.imageCrop
        .getCroppedCanvas({
          maxWidth: 4096,
          maxHeight: 4096,
        })
        .toDataURL('image/png'),
    });

    this.cancel();
  }
}
