<div class="layout-wrapper" [ngClass]="{'layout-wrapper-menu-active':sidebarActive,
                                        'layout-overlay-menu':overlay}">
    <!--topbar-->
    <app-home-top></app-home-top>
    <!--sidebar-->
    <app-home-sidebar></app-home-sidebar>
    <div class="layout-content">
        <div class="layout-content-inner h-full">
             <router-outlet></router-outlet> 
        </div>
    </div>
</div>