import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SessionService } from './session.service';
@Injectable({
  providedIn: 'root',
})
export class AwsUserService {
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'client-id':'apliclientesksks',
      'client-hash':'apliclientesksks'
    })
  }; 

  constructor(
    private http: HttpClient, 
    private sessionService: SessionService
  ) {}

  //registrar usuario
  signUp(body) {
    return this.http.post(`${environment.baseUrlAPI}/admin/signUp`, body, this.httpOptions);
  }
  //verificar cuenta de usuario
  verified(body) {
    return this.http.post(`${environment.baseUrlAPI}/admin/verifyAccount`, body, this.httpOptions);
  }
  //reenviar codigo de verificacion
  resendCodeVerified(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/resendConfirmationCode`, body, this.httpOptions);
  }
  //iniciar sesion
  signIn(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/signIn`, body, this.httpOptions);
  }
  //solicitar cambio de contraseña
  forgotPassword(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/forgotPassword`, body, this.httpOptions);
  }
  //actualizar contraseña
  confirmNewPass(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/confirmNewPassword`, body, this.httpOptions);
  }
  //cerrar sesion
  logout(){
    const httpOptionsLogout = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'client-id':'apliclientesksks',
        'client-hash':'apliclientesksks',
        'Auth': this.sessionService.revokeToken
      })
    }; 
    return this.http.post(`${environment.baseUrlAPI}/admin/logout`, {}, httpOptionsLogout);
  }

  updateUserAttributes(body){
    const httpOptionsPreference = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'client-id':'apliclientesksks',
        'client-hash':'apliclientesksks',
        'Auth': this.sessionService.token
      })
    };
    return this.http.post(`${environment.baseUrlAPI}/admin/updateUserAttributes`, body, httpOptionsPreference);
  }

  uploadFileS3(body, params){
    const httpFormaData = {
      headers: new HttpHeaders({
        //'Content-Type': 'multipart/form-data',
        'client-id':'apliclientesksks',
        'client-hash':'apliclientesksks',
        'Auth': this.sessionService.token
      })
    };
    return this.http.post(`${environment.baseUrlAPI}/admin/uploadFileS3?ext=${params}`, body, httpFormaData);
  }

  getInfoUser(){
    const httpOptionsPreference = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'client-id':'apliclientesksks',
        'client-hash':'apliclientesksks',
        'Auth': this.sessionService.token
      })
    };
    return this.http.post(`${environment.baseUrlAPI}/admin/listUser`, {},  httpOptionsPreference);
  }

  getUsersAll(body){
    const httpOptionsPreference = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'client-id':'apliclientesksks',
        'client-hash':'apliclientesksks',
        'Auth': this.sessionService.token
      })
    };
    return this.http.post(`${environment.baseUrlAPI}/admin/listUsersAll`, body, httpOptionsPreference);
  }

  listUsersCognitoPagination(body){
    const httpOptionsPreference = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'client-id':'apliclientesksks',
        'client-hash':'apliclientesksks',
        'Auth': this.sessionService.token
      })
    };
    return this.http.post(`${environment.baseUrlAPI}/admin/listUsersCognitoPagination`, body, httpOptionsPreference);
  }

  detailCognitoGroupUser(body){
    const httpOptionsPreference = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'client-id':'apliclientesksks',
        'client-hash':'apliclientesksks',
        'Auth': this.sessionService.token
      })
    };
    return this.http.post(`${environment.baseUrlAPI}/admin/detailCognitoGroupUser`, body, httpOptionsPreference);
  }

  updateUserErrorDynamoCognitoGroup(body){
    const httpOptionsPreference = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'client-id':'apliclientesksks',
        'client-hash':'apliclientesksks',
        'Auth': this.sessionService.token
      })
    };
    return this.http.post(`${environment.baseUrlAPI}/admin/updateUserErrorDynamoCognitoGroup`, body, httpOptionsPreference);
  }
}
