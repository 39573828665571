import { Component, OnInit ,Input} from '@angular/core';

@Component({
  selector: 'app-animated-warning',
  templateUrl: './animated-warning.component.html',
  styleUrls: ['./animated-warning.component.scss']
})
export class AnimatedWarningComponent implements OnInit {
  @Input() height = 'height: 220px;'
  constructor() { 
    console.log(this.height)
  }

  ngOnInit(): void {
    setTimeout(() => {
      document.getElementById("animation").classList.add("active")
    }, 100);
    
  }

}
