import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AnimatedCheckComponent } from 'src/app/shared/animated-check/animated-check.component';
import { SwiperModule } from 'swiper/angular';
import { CropImageGlobalComponent } from 'src/app/shared/crop-image-global/crop-image-global.component';
import { PrimengModule } from '../primeng/primeng.module';
import { ListFilesComponent } from 'src/app/shared/list-files/list-files.component';
import { ClipboardModule } from 'ngx-clipboard';
import { BlockUiCardComponent } from 'src/app/shared/block-ui-card/block-ui-card.component';
import { AnimatedWarningComponent } from 'src/app/shared/animated-warning/animated-warning.component';
import { InfoSupplierComponent } from 'src/app/shared/info-supplier/info-supplier.component';
import { ListPropertiesComponent } from 'src/app/shared/list-properties/list-properties.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { ViewProductComponent } from 'src/app/shared/view-product/view-product.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { ChatItemComponent } from 'src/app/shared/chat/chat-item/chat-item.component';
import { ChatNotSelectedComponent } from 'src/app/shared/chat/chat-not-selected/chat-not-selected.component';
import { ChatMessageUserComponent } from 'src/app/shared/chat/chat-message-user/chat-message-user.component';
import { ChatInputFooterComponent } from 'src/app/shared/chat/chat-input-footer/chat-input-footer.component';
import { SearchGifComponent } from 'src/app/shared/search-gif/search-gif.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UploadFilesComponent } from 'src/app/shared/upload-files/upload-files.component';
import { UploadVideoComponent } from 'src/app/shared/upload-video/upload-video.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { DndDirectiveVideo } from 'src/app/shared/upload-video/dnd-video.directive';
import { DndDirective } from 'src/app/shared/upload-files/dnd.directive';
import { BarRatingComponent } from 'src/app/shared/bar-rating/bar-rating.component';
import { BarRatingPipe } from 'src/app/shared/bar-rating/pipes/bar-rating.pipe'
import { UploadPhotoComponent } from 'src/app/shared/upload-photo/upload-photo.component';

@NgModule({
  declarations: [
    AnimatedCheckComponent,
    CropImageGlobalComponent,
    ListFilesComponent,
    BlockUiCardComponent,
    AnimatedWarningComponent,
    InfoSupplierComponent,
    ListPropertiesComponent,
    ViewProductComponent,
    UploadFilesComponent,
    UploadVideoComponent,
    //chat
    ChatItemComponent,
    ChatNotSelectedComponent,
    ChatMessageUserComponent,
    ChatInputFooterComponent,
    SearchGifComponent,
    DndDirectiveVideo,
    DndDirective,
    BarRatingComponent,
    BarRatingPipe,
    UploadPhotoComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    SwiperModule,
    PrimengModule,
    ClipboardModule,
    GoogleMapsModule,
    ColorPickerModule,
    FormsModule,
    ReactiveFormsModule,
    PickerModule
  ],
  exports:[
    TranslateModule,
    AnimatedCheckComponent,
    SwiperModule,
    CropImageGlobalComponent,
    ListFilesComponent,
    ClipboardModule,
    BlockUiCardComponent,
    AnimatedWarningComponent,
    InfoSupplierComponent,
    ListPropertiesComponent,
    ViewProductComponent,
    ColorPickerModule,
    UploadFilesComponent,
    UploadVideoComponent,
    //chat
    ChatItemComponent,
    ChatNotSelectedComponent,
    ChatMessageUserComponent,
    ChatInputFooterComponent,
    SearchGifComponent,
    BarRatingComponent,
    BarRatingPipe,
    UploadPhotoComponent
  ]
})
export class GlobalModule { }
