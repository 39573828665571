import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-chat-message-user',
  templateUrl: './chat-message-user.component.html',
  styleUrls: ['./chat-message-user.component.scss']
})
export class ChatMessageUserComponent implements OnInit {

  @Input() message = null;
  @Input() idSend = "";
  @Input() thread = "NO";
  @Output() openResponseChat = new EventEmitter<Object>();

  itemsChat = [];
  swiper = null;
  previewSidebarImage = false;
  indexSwiper = 0;

  swiperVideo = null;
  previewSidebarVideo = false;
  indexSwiperVideo = 0;

  //fields
  avatar = "";
  name = "";
  time = null;
  messageHtml = "";

  images = [];
  files = [];
  videos = [];

  colores = [
    { color: "#2C2C2C", correlative:	[20,170,320,470] },
    { color: "#000000", correlative:	[41,191,341,491] },
    { color: "#212121", correlative:	[62,212,362,512] },
    { color: "#424242", correlative:	[81,231,381,531] },
    { color: "#757575", correlative:	[99,249,399,549] },
    { color: "#9E9E9E", correlative:	[114,264,414,564] },
    { color: "#37474F", correlative:	[21,171,321,471] },
    { color: "#455A64", correlative:	[42,192,342,492] },
    { color: "#546E7A", correlative:	[63,213,363,513] },
    { color: "#607D8B", correlative:	[82,232,382,532] },
    { color: "#3E2723", correlative:	[19,169,319,469] },
    { color: "#4E342E", correlative:	[40,190,340,490] },
    { color: "#5D4037", correlative:	[61,211,361,511] },
    { color: "#795548", correlative:	[80,230,380,530] },
    { color: "#8D6E63", correlative:	[98,248,398,548] },
    { color: "#BCAAA4", correlative:	[113,263,413,563] },
    { color: "#D7CCC8", correlative:	[125,275,425,575] },
    { color: "#DD2C00", correlative:	[3,153,303,453] },
    { color: "#FF3D00", correlative:	[24,174,324,474] },
    { color: "#FF6E40", correlative:	[45,195,345,495] },
    { color: "#FF9E80", correlative:	[66,216,366,516] },
    { color: "#BF360C", correlative:	[85,235,385,535] },
    { color: "#E64A19", correlative:	[102,252,402,552] },
    { color: "#FFCCBC", correlative:	[117,267,417,567] },
    { color: "#FF6D00", correlative:	[4,154,304,454] },
    { color: "#FF9100", correlative:	[25,175,325,475] },
    { color: "#FFAB40", correlative:	[46,196,346,496] },
    { color: "#FFD180", correlative:	[67,217,367,517] },
    { color: "#E65100", correlative:	[86,236,386,536] },
    { color: "#FFE0B2", correlative:	[103,253,403,553] },
    { color: "#FFC400", correlative:	[5,155,305,455] },
    { color: "#FFD740", correlative:	[26,176,326,476] },
    { color: "#FFE57F", correlative:	[47,197,347,497] },
    { color: "#FFEA00", correlative:	[6,156,306,456] },
    { color: "#FFFF00", correlative:	[27,177,327,477] },
    { color: "#FFFF8D", correlative:	[48,198,348,498] },
    { color: "#FFEE58", correlative:	[68,218,368,518] },
    { color: "#FFF176", correlative:	[87,237,387,537] },
    { color: "#FFF9C4", correlative:	[104,254,404,554] },
    { color: "#AEEA00", correlative:	[7,157,307,457] },
    { color: "#C6FF00", correlative:	[28,178,328,478] },
    { color: "#EEFF41", correlative:	[49,199,349,499] },
    { color: "#F4FF81", correlative:	[69,219,369,519] },
    { color: "#827717", correlative:	[88,238,388,538] },
    { color: "#9E9D24", correlative:	[105,255,405,555] },
    { color: "#AFB42B", correlative:	[118,268,418,568] },
    { color: "#CDDC39", correlative:	[126,276,426,576] },
    { color: "#E6EE9C", correlative:	[134,284,434,584] },
    { color: "#64DD17", correlative:	[8,158,308,458] },
    { color: "#76FF03", correlative:	[29,179,329,479] },
    { color: "#B2FF59", correlative:	[50,200,350,500] },
    { color: "#CCFF90", correlative:	[70,220,370,520] },
    { color: "#33691E", correlative:	[89,239,389,539] },
    { color: "#558B2F", correlative:	[106,256,406,556] },
    { color: "#689F38", correlative:	[119,269,419,569] },
    { color: "#8BC34A", correlative:	[127,277,427,577] },
    { color: "#9CCC65", correlative:	[135,285,435,585] },
    { color: "#AED581", correlative:	[141,291,441,591] },
    { color: "#00C853", correlative:	[9,159,309,459] },
    { color: "#00E676", correlative:	[30,180,330,480] },
    { color: "#69F0AE", correlative:	[51,201,351,501] },
    { color: "#B9F6CA", correlative:	[71,221,371,521] },
    { color: "#388E3C", correlative:	[90,240,390,540] },
    { color: "#43A047", correlative:	[107,257,407,557] },
    { color: "#4CAF50", correlative:	[120,270,420,570] },
    { color: "#66BB6A", correlative:	[128,278,428,578] },
    { color: "#81C784", correlative:	[136,286,436,586] },
    { color: "#A5D6A7", correlative:	[142,292,442,592] },
    { color: "#00BFA5", correlative:	[10,160,310,460] },
    { color: "#1DE9B6", correlative:	[31,181,331,481] },
    { color: "#64FFDA", correlative:	[52,202,352,502] },
    { color: "#A7FFEB", correlative:	[72,222,372,522] },
    { color: "#004D40", correlative:	[91,241,391,541] },
    { color: "#00695C", correlative:	[108,258,408,558] },
    { color: "#00897B", correlative:	[121,271,421,571] },
    { color: "#26A69A", correlative:	[129,279,429,579] },
    { color: "#80CBC4", correlative:	[137,287,437,587] },
    { color: "#B2DFDB", correlative:	[143,293,443,593] },
    { color: "#00B8D4", correlative:	[1,151,301,451] },
    { color: "#00E5FF", correlative:	[22,172,322,472] },
    { color: "#18FFFF", correlative:	[43,193,343,493] },
    { color: "#84FFFF", correlative:	[53,203,353,503] },
    { color: "#006064", correlative:	[64,214,364,514] },
    { color: "#00838F", correlative:	[73,223,373,523] },
    { color: "#00BCD4", correlative:	[83,233,383,533] },
    { color: "#26C6DA", correlative:	[100,250,400,550] },
    { color: "#B2EBF2", correlative:	[115,265,415,565] },
    { color: "#0091EA", correlative:	[11,161,311,461] },
    { color: "#00B0FF", correlative:	[32,182,332,482] },
    { color: "#40C4FF", correlative:	[54,204,354,504] },
    { color: "#80D8FF", correlative:	[74,224,374,524] },
    { color: "#01579B", correlative:	[92,242,392,542] },
    { color: "#0288D1", correlative:	[109,259,409,559] },
    { color: "#039BE5", correlative:	[122,272,422,572] },
    { color: "#4FC3F7", correlative:	[130,280,430,580] },
    { color: "#81D4FA", correlative:	[138,288,438,588] },
    { color: "#B3E5FC", correlative:	[144,294,444,594] },
    { color: "#2962FF", correlative:	[12,162,312,462] },
    { color: "#448AFF", correlative:	[33,183,333,483] },
    { color: "#82B1FF", correlative:	[55,205,355,505] },
    { color: "#0D47A1", correlative:	[75,225,375,525] },
    { color: "#1565C0", correlative:	[93,243,393,543] },
    { color: "#1E88E5", correlative:	[110,260,410,560] },
    { color: "#2196F3", correlative:	[123,273,423,573] },
    { color: "#64B5F6", correlative:	[131,281,431,581] },
    { color: "#90CAF9", correlative:	[139,289,439,589] },
    { color: "#BBDEFB", correlative:	[145,295,445,595] },
    { color: "#8C9EFF", correlative:	[147,297,447,597] },
    { color: "#1A237E", correlative:	[13,163,313,463] },
    { color: "#303F9F", correlative:	[34,184,334,484] },
    { color: "#3F51B5", correlative:	[56,206,356,506] },
    { color: "#5C6BC0", correlative:	[76,226,376,526] },
    { color: "#7986CB", correlative:	[94,244,394,544] },
    { color: "#6200EA", correlative:	[14,164,314,464] },
    { color: "#651FFF", correlative:	[35,185,335,485] },
    { color: "#7C4DFF", correlative:	[57,207,357,507] },
    { color: "#B388FF", correlative:	[15,165,315,465] },
    { color: "#512DA8", correlative:	[36,186,336,486] },
    { color: "#5E35B1", correlative:	[58,208,358,508] },
    { color: "#7E57C2", correlative:	[77,227,377,527] },
    { color: "#B39DDB", correlative:	[95,245,395,545] },
    { color: "#AA00FF", correlative:	[111,261,411,561] },
    { color: "#D500F9", correlative:	[16,166,316,466] },
    { color: "#EA80FC", correlative:	[37,187,337,487] },
    { color: "#4A148C", correlative:	[17,167,317,467] },
    { color: "#6A1B9A", correlative:	[38,188,338,488] },
    { color: "#8E24AA", correlative:	[59,209,359,509] },
    { color: "#AB47BC", correlative:	[78,228,378,528] },
    { color: "#CE93D8", correlative:	[96,246,396,546] },
    { color: "#F50057", correlative:	[18,168,318,468] },
    { color: "#FF4081", correlative:	[39,189,339,489] },
    { color: "#FF80AB", correlative:	[60,210,360,510] },
    { color: "#880E4F", correlative:	[79,229,379,529] },
    { color: "#C2185B", correlative:	[97,247,397,547] },
    { color: "#E91E63", correlative:	[112,262,412,562] },
    { color: "#F06292", correlative:	[124,274,424,574] },
    { color: "#F48FB1", correlative:	[132,282,432,582] },
    { color: "#F8BBD0", correlative:	[140,290,440,590] },
    { color: "#D50000", correlative:	[2,152,302,452] },
    { color: "#FF1744", correlative:	[23,173,323,473] },
    { color: "#FF5252", correlative:	[44,194,344,494] },
    { color: "#FF8A80", correlative:	[65,215,365,515] },
    { color: "#B71C1C", correlative:	[84,234,384,534] },
    { color: "#D32F2F", correlative:	[101,251,401,551] },
    { color: "#F44336", correlative:	[116,266,416,566] },
    { color: "#EF5350", correlative:	[125,275,425,575] },
    { color: "#EF9A9A", correlative:	[133,283,433,583] },
    { color: "#FFCDD2", correlative:	[146,296,446,596] }
  ];

  colorName = "#000000";
  colorAvatar = "#000000";
  extensionsAttr = [
    { ext:['xls', 'xlsx', 'csv'], color:'#1BBB53', icon:'fa-file-excel' },
    { ext:['txt'], color:'#03A9F4', icon:'fa-file-alt' },
    { ext:['doc', 'docx', 'docm' ,'aspx'], color:'#3C33FF', icon:'fa-file-word' },
    { ext:['jpg', 'jpeg', 'gif', 'png', 'bmp', 'psd'], color:'#BF00FC', icon:'fa-file-image' },
    { ext:['avi', 'mp4', 'mpeg', 'mwv'], color:'#00E9FC', icon:'fa-file-video' },
    { ext:['ppt', 'pptx', 'pptm'], color:'#FF5722', icon:'fa-file-powerpoint' },
    { ext:['pdf'], color:'#E91E63', icon:'fa-file-pdf' },
    { ext:['html', 'css', 'js', 'json'], color:'#E2D63D', icon:'fa-file-code' },
    { ext:['mp3', 'wav', 'wma'], color:'#D1AE2E', icon:'fa-file-audio' },
    { ext:['zip', 'rar', 'gzip'], color:'#A67711', icon:'fa-file-archive' },
    { ext:['etc'], color:'#9E9E9E', icon:'fa-file' }
  ];

  nameNoSplit:any = [];
  company = "";
  tempSlider = null;
  correlativeParticipant = null;
  constructor( public trans: TranslateService , private globalService: GlobalService) { 
    this.globalService.request.subscribe((res) => {
      if (res) {
        switch (res['type']) {
          case 'UPDATELANG':
            this.translateService();
            break;
        }
      }
    });
  }

  ngOnInit(): void {
    console.log(this.message)
    this.correlativeParticipant = this.message['correlativeParticipant']
    this.avatar = this.message['avatar'] && this.message['avatar'].length > 0 ?  this.message['avatar'] : "assets/images/user.svg";
    this.nameNoSplit = this.message['name'] && this.message['name'].length > 0 ?  this.message['name'] : "";

    let splitName = this.nameNoSplit.split(" - ");
    this.name = splitName[0];
    this.company = splitName[1];

    this.time = this.message['createdDate'];
    let comment = this.replaceURLWithHTMLLinks(this.decrypt(this.message['message']));
    this.messageHtml = comment.split("\n").join("<br>");
    this.images = JSON.parse(this.decrypt(this.message['images']));
    this.videos = JSON.parse(this.decrypt(this.message['videos']));
    this.files = JSON.parse(this.decrypt(this.message['files']));
    this.colorName = this.message['typeChat'] != 'USER_DIRECT_COMPANY' ? (this.colores.find(c => c['correlative'].includes(this.message.correlativeParticipant)) ? this.colores.find(c => c['correlative'].includes(this.message.correlativeParticipant))['color'] : "#000000") : "var(--text-color-secondary)";

    this.colorAvatar = this.message['typeChat'] != 'USER_DIRECT_COMPANY' ? (this.colores.find(c => c['correlative'].includes(this.message.correlativeParticipant)) ? this.colores.find(c => c['correlative'].includes(this.message.correlativeParticipant))['color'] : "#000000") : "#ffffff00";
    this.translateService();
  }

  async translateService()  {
    let textLang: any = await new Promise((resolve, reject) => {
      this.trans.get('option-chat').subscribe((res) => {
        resolve(res);
      });
    });
    if(this.message.me){
      this.itemsChat = [
        {
          label: textLang['respond'],
          icon: 'fas fa-reply',
          command: () => {
            this.openResponse();
          },
        },
        {
          label: textLang['delete'],
          icon: 'far fa-trash-alt',
          command: () => {},
        }
      ]
    }else{
      this.itemsChat = [
        {
          label: textLang['respond'],
          icon: 'fas fa-reply',
          command: () => {
            this.openResponse();
          },
        },
        {
          label: textLang['delete'],
          icon: 'far fa-flag',
          command: () => {},
        },
      ]
    }
  }

  replaceURLWithHTMLLinks(e) {
    return e.replace(
      /(\(.*?)?\b((?:https?|ftp|file):\/\/[-a-z0-9+&@#\/%?=~_()|!:,.;]*[-a-z0-9+&@#\/%=~_()|])/gi,
      function (e, r, n) {
        var t = '';
        r = r || '';
        for (var a = /\(/g; a.exec(r); ) {
          var l;
          (l = /(.*)(\.\).*)/.exec(n) || /(.*)(\).*)/.exec(n)) &&
            ((n = l[1]), (t = l[2] + t));
        }
        return (
          r +
          "<a href='" +
          n +
          "' target='_blank' rel='nofollow noopener'>" +
          n +
          '</a>' +
          t
        );
      }
    );
  }

  openResponse(){
    this.openResponseChat.emit(this.message);
  }

  decrypt(value: string){
    var bytes  = CryptoJS.AES.decrypt(value, this.idSend);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  //sidebar imagenes
  openPreviewIndex(index){
    this.previewSidebarImage = true;
    this.swiper.slideTo(index);
  }

  closePreview(){
    this.previewSidebarImage = false;
  }

  zoomIn(){
    this.swiper.zoom.in()
  }

  zoomOut(){
    this.swiper.zoom.out()
  }

  onSwiper(swiper){
    this.tempSlider = this.images[0].url;
    this.swiper = swiper;
    if(this.images.length > 0){
      this.swiper.slideTo(this.indexSwiper);
    }
  }

  onSlideChange(swiper){
    this.tempSlider = this.images[swiper.activeIndex].url;
  }

  //sidebar videos
  openVideoPreviewIndex(index){
    this.previewSidebarVideo= true;
    this.swiperVideo.slideTo(index);
  }

  closeVideoPreview(){
    this.previewSidebarVideo = false;
  }

  onSwiperVideo(swiper){
    this.tempSlider = this.videos[0].url;
    this.swiperVideo = swiper;
    if(this.videos.length > 0){
      this.swiperVideo.slideTo(this.indexSwiperVideo);
    }
  }

  onSlideChangeVideo(swiper){
    this.tempSlider = this.videos[swiper.activeIndex].url;
  }

  download(url){
    window.open(url, "_blank");
  }

  getColor(name){
    let ext = name.split(".")[name.split(".").length - 1].toLowerCase();
    return this.extensionsAttr.find(c => c['ext'].includes(ext)) ? this.extensionsAttr.find(c => c['ext'].includes(ext))['color'] : '#9e9e9e';
  }
  
  getIcon(name){
    let ext = name.split(".")[name.split(".").length - 1].toLowerCase();
    return this.extensionsAttr.find(c => c['ext'].includes(ext)) ? this.extensionsAttr.find(c => c['ext'].includes(ext))['icon'] : 'fa-file';
  }

}
