<p-dialog [draggable]="false" [closeOnEscape]="false" header="{{ 'CARGAR FOTO' | translate }}" [(visible)]="showFiles" [modal]="true" [closable]="false" [style]="{'width': '60%'}" [breakpoints]="{'640px': '100vw'}" appendTo="body">
    <div class="grid">
        <div class="col-12 md:col-6 lg:col-6">
            <div class="col-12 dropZone p-2 relative m-0 flex align-content-center justify-content-center flex-wrap" appDnd (fileDropped)="onFileDropped($event)">
                <input class="opacity-0 absolute top-0 left-0 w-full h-full z-2 cursor-pointer" type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)" />
                <img src="https://cbn-proveedores.s3.sa-east-1.amazonaws.com/cbn-images/others/uploader.png" alt="" class="w-full">
                <p class="text-center m-0">{{ 'drop_files' | translate }} <br> {{ 'or' | translate }} <label for="fileDropRef"><b>{{ 'SEARCH' | translate }}</b></label></p>                
            </div>
        </div>
        <div class="col-12 md:col-6 lg:col-6">
            <p-scrollPanel [style]="{width: '100%', height: '250px'}">
                <ng-container *ngFor="let file of files">
                    <div class="grid card-file">
                        <div class="col-2 text-center">
                            <i class="far {{file.icon}} icon-ext" [style.color]="file.color"></i>
                        </div>
                        <div class="col-9">
                            <div class="text-overflow-ellipsis w-full overflow-hidden white-space-nowrap">{{file.name}}</div>
                            <span>{{file.size}}</span>
                            <p-progressBar [value]="file.progress" styleClass="progress-file" [showValue]="false"></p-progressBar>
                        </div>
                        <div class="col-1">
                            <div *ngIf="file.status === 'FINALIZED'" style="height: 20px; margin-top: 29px;"> <app-animated-check></app-animated-check></div>
                            <i *ngIf="file.status === 'PENDING'" class="far fa-times-circle text-pink-300 text-sm cursor-pointer" (click)="deleteFile(file)"></i>
                        </div>
                    </div>
                </ng-container>
            </p-scrollPanel>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button pButton pRipple type="button" label="{{ 'cancelU' | translate }}"  class="p-button-rounded p-button-danger w-button" (click)="closeUpload()"></button>
        <button pButton pRipple type="button" label="{{ 'CONTINUE' | translate }}"  class="p-button-rounded p-button-info w-button" [disabled]="statusUploading || files.length === 0" (click)="generateFiles()"></button>
    </ng-template>
</p-dialog>
