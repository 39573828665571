import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  private env: string = environment.env;
  public lang: string;
  public theme:string;
  public desktop:boolean;
  request = new BehaviorSubject<Object>(undefined);
  request$ = this.request.asObservable();
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private translateService: TranslateService,
    private config: PrimeNGConfig
  ) {}

  validateDevice(){
    if(screen.width < 768){
     this.desktop = false
    }else{
      this.desktop = true
    }
  }

  switchTheme(theme: string) {
    this.theme = theme;
    let themeLink = this.document.getElementById(
      'app-theme'
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = theme + '.css';
    }
  }

  changeLanguage(type: string) {
    this.lang = type;
    console.log(type)
    this.translateService.use(type);
    this.translateService
      .get('primeng')
      .subscribe((res) => this.config.setTranslation(res));
  }

  statusEnvironments() {
    console.log(this.env);
  }

  sendRequest(data){
    this.request.next(data);
  }
}
