import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SessionService } from './session.service';


@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  constructor(
    private http: HttpClient,
    private sessionService: SessionService
  ) {}

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'client-id': 'apliclientesksks',
      'client-hash': 'apliclientesksks',
      Auth: this.sessionService.token,
    }),
  };

  
  listCompanies(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/getCompaniesAll`, body, this.httpOptions);
  }

  listResumProvidersCompany(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/listResumProvidersCompany`, body, this.httpOptions);
  }

  listCompanySecoundCategoriesCBN(){
    return this.http.post(`${environment.baseUrlAPI}/admin/listCompanySecoundCategoriesCBN`, {},  this.httpOptions);
  }
  
  peruvianCompaniesExportXlsx(){
    return this.http.post(`${environment.baseUrlAPI}/companies/peruvian/exportXlsx`, {},  this.httpOptions);
  }
  
  peruvianCompaniesUpdate(body){
    return this.http.post(`${environment.baseUrlAPI}/companies/peruvian/update`, body,  this.httpOptions);
  }

  /**
   * metodo para traer información del perfil empresa
   * @param body { idCompany }
   * @returns 
   */
  getCompanyProfileGeneralData(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/getCompanyProfileGeneralData`, body,  this.httpOptions)
  }
  /**
   *  metodo para traer información del formulario completo de empresa
   * @param body  { idCompany }
   * @returns 
   */
  getCompanyTabSummaryData(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/getCompanyTabSummaryData`, body,  this.httpOptions)
  }
  /**
   *  metodo para traer información de los ejecutivos de empresa
   * @param body  { idCompany }
   * @returns 
   */
   getCompanyTabContactData(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/getCompanyTabContactData`, body,  this.httpOptions)
  }
  /**
   *  metodo para traer información del formulario completo de empresa
   * @param body  { idCompany }
   * @returns 
   */
  getCompanyTabPlanData(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/getCompanyTabPlanData`, body,  this.httpOptions)
  }
  /**
   *  metodo para crear una nueva suscripcion a una empresa
   * @param body  { idCompany, idPlan, additionalUsers, startDate, dueDate, paymentDate, dateOfNotification, renovation, planSelected }
   * @returns 
   */
  createCompanyPlan(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/createCompanyPlan`, body,  this.httpOptions)
  }
  /**
   *  metodo para eliminar una suscripcion a una empresa
   * @param body  { idCompany, startDate }
   * @returns 
   */
   deleteCompanyPlan(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/deleteCompanyPlan`, body,  this.httpOptions)
  }
  /**
   *  metodo para obtener usuarios de una empresa
   * @param body  { idCompany }
   * @returns 
   */
  getUsersCompany(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/getUsersCompany`, body,  this.httpOptions)
  }
  /**
   *  metodo para asociar usuarios a una empresa
   * @param body  { idCompany, idUser }
   * @returns 
   */
  addUserCompany(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/addUserCompany`, body,  this.httpOptions)
  }
  /**
   *  metodo para eliminar usuarios de una empresa
   * @param body  { idCompany, idUser }
   * @returns 
   */
  deleteUserCompany(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/deleteUserCompany`, body,  this.httpOptions)
  }

  /**
   *  metodo para obtener usuarios de una empresa
   * @param body  { idCompany, query }
   * @returns 
   */
  getUsersQueryNotCompany(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/getUsersQueryNotCompany`, body,  this.httpOptions)
  }
/**
   *  metodo para obtener estado de una empresa
   * @param body  { idCompany }
   * @returns 
   */
  getStatusCompany(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/getStatusCompany`, body,  this.httpOptions)
  }

  addCompanyOpensearch(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/addCompanyOpensearch`, body,  this.httpOptions)
  }

  addCompanyAllOpensearch(){
    return this.http.post(`${environment.baseUrlAPI}/admin/addCompanyAllOpensearch`, {},  this.httpOptions)
  }

  listCompanyNotOpensearch(){
    return this.http.post(`${environment.baseUrlAPI}/admin/listCompanyNotOpensearch`, {},  this.httpOptions)
  }

  //invitacion masiva a los proveedores
  listClients(){
    return this.http.post(`${environment.baseUrlAPI}/admin/listClients`, {},  this.httpOptions)
  }

  listCompaniesNotSupplier(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/listCompaniesNotSupplier`, body,  this.httpOptions)
  }

  inviteSupplier(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/inviteSupplier`, body,  this.httpOptions)
  }

  companiesPremium(){
    return this.http.post(`${environment.baseUrlAPI}/admin/companiesPremium`, {},  this.httpOptions)
  }

  evaluationsCompanyPremium(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/evaluationsCompanyPremium`, body,  this.httpOptions)
  }

  requestEvaluationsCompanyPremium(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/requestEvaluationsCompanyPremium`, body,  this.httpOptions)
  }

  shippingAwardedCompanyPremium(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/shippingAwardedCompanyPremium`, body,  this.httpOptions)
  }

  shippingAwardedTechnicalCompanyPremium(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/shippingAwardedTechnicalCompanyPremium`, body,  this.httpOptions)
  }

  deleteCompany(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/deleteCompany`, body,  this.httpOptions)
  }

  exportXlsxExecutivesCompanyAll(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/exportXlsxExecutivesCompanyAll`, body,  this.httpOptions)
  }

  //Campos para plantillas docx
  createTag(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/createTag`, body,  this.httpOptions)
  }
  
  updateTag(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/updateTag`, body,  this.httpOptions)
  }

  panelTag(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/panelTag`, body,  this.httpOptions)
  }

  deleteTag(body){
    const httpOptionsTemp = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'client-id':'apliclientesksks',
        'client-hash':'apliclientesksks',
        'Auth': this.sessionService.token
      }),
      body: body
    };
    return this.http.delete(`${environment.baseUrlAPI}/admin/deleteTag`, httpOptionsTemp);
  }
  
  //Tipos de plantillas docx
  createTemplateDocxFieldsModule(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/createTemplateDocxFieldsModule`, body,  this.httpOptions)
  }
  
  updateTemplateDocxFieldsModule(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/updateTemplateDocxFieldsModule`, body,  this.httpOptions)
  }

  panelTemplateDocxFieldsModule(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/panelTemplateDocxFieldsModule`, body,  this.httpOptions)
  }

  deleteTemplateDocxFieldsModule(body){
    const httpOptionsTemp = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'client-id':'apliclientesksks',
        'client-hash':'apliclientesksks',
        'Auth': this.sessionService.token
      }),
      body: body
    };
    return this.http.delete(`${environment.baseUrlAPI}/admin/deleteTemplateDocxFieldsModule`, httpOptionsTemp);
  }


//servicio
  listService(){
    return this.http.get(`${environment.baseUrlAPI}/admin/service`, this.httpOptions)
  }

  deleteService(body){
    const httpOptionsTemp = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'client-id':'apliclientesksks',
        'client-hash':'apliclientesksks',
        'Auth': this.sessionService.token
      }),
      body: body
    };
    return this.http.delete(`${environment.baseUrlAPI}/admin/service`, httpOptionsTemp);
  }

  createService(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/service`, body, this.httpOptions);
  }

  editService(body){
    return this.http.put(`${environment.baseUrlAPI}/admin/service`,body, this.httpOptions);
  }

  sortService(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/sortService`, body, this.httpOptions);
  }

  //subservicio

  listSubService(){
    return this.http.get(`${environment.baseUrlAPI}/admin/subservice`, this.httpOptions)
  }

  deleteSubService(body){
    const httpOptionsTemp = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'client-id':'apliclientesksks',
        'client-hash':'apliclientesksks',
        'Auth': this.sessionService.token
      }),
      body: body
    };
    return this.http.delete(`${environment.baseUrlAPI}/admin/subservice`, httpOptionsTemp);
  }

  createSubService(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/subservice`, body, this.httpOptions);
  }

  editSubService(body){
    return this.http.put(`${environment.baseUrlAPI}/admin/subservice`,body, this.httpOptions);
  }

  sortSubService(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/sortSubService`, body, this.httpOptions);
  }

  //Como funciona

  panelVideo(){
    return this.http.get(`${environment.baseUrlAPI}/admin/panelVideo`, this.httpOptions);
  }

  sortVideos(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/sortVideos`,body, this.httpOptions);
  }

  createVideo(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/createVideo`,body, this.httpOptions);
  }

  updateVideo(body){
    return this.http.put(`${environment.baseUrlAPI}/admin/updateVideo`,body, this.httpOptions);
  }
  
  deleteVideo(body){
    const httpOptionsTemp = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'client-id':'apliclientesksks',
        'client-hash':'apliclientesksks',
        'Auth': this.sessionService.token
      }),
      body: body
    };
    return this.http.delete(`${environment.baseUrlAPI}/admin/deleteVideo`,httpOptionsTemp);
  }

  detailVideo(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/detailVideo`,body, this.httpOptions);
  }

  //Actualizador

  getListUpdates(){
    return this.http.get(`${environment.baseUrlAPI}/admin/update`, this.httpOptions);
  }

  createUpdate(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/update`,body, this.httpOptions);
  }

  deleteUpdate(body){
    const httpOptionsTemp = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'client-id':'apliclientesksks',
        'client-hash':'apliclientesksks',
        'Auth': this.sessionService.token
      }),
      body: body
    };
    return this.http.delete(`${environment.baseUrlAPI}/admin/update`,httpOptionsTemp);
  }

  getPublication(){
    return this.http.get(`${environment.baseUrlAPI}/admin/publication`, this.httpOptions);
  }

  createPublication(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/publication`,body , this.httpOptions);
  }

  detailPublication(body){
    return this.http.post(`${environment.baseUrlAPI}/admin/detailpublication`,body ,this.httpOptions);
  }

  updatePublication(body){
    return this.http.put(`${environment.baseUrlAPI}/admin/update`,body ,this.httpOptions);
  }
}
